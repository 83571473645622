import { createSlice, createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { getPayments , downloadPayments , searchPayments } from "../services/paymentService";

export const getAllPayments = createAsyncThunk(
    "manage_payments/getAllPayments",
    async (params, { rejectWithValue }) => {
      try {
        const res = await getPayments(params);
        if (res.status === 200) {
            console.log(res.data, "data")
          return res.data;
        } else throw res.error;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );


  export const downloadPayment = createAsyncThunk(
    "manage_payments/downloadPayment",
    async(params, {rejectWithValue}) =>{
      try{
        const res = await downloadPayments(params);
        if(res.status === 200){
          return res.data;
        }else throw res.error;
      }catch(error){
          return rejectWithValue(error);
      }
    }
  )


  export const searchPayment = createAsyncThunk(
    "manage_payments/searchPayments",
    async(params , {rejectWithValue}) =>{
      try{
        const res = await searchPayments(params);
        if(res.status === 200){
          return res.data;
        }else throw res.error;
      }catch(error){
        return rejectWithValue(error)
      }
    }
  )

const payments = createSlice({
    name: "manage_payments",
    initialState: {
      allPayments: [],
      downloadPaymentList : [],
      searchData  : []
     
    },
    extraReducers: (builder) => {
      builder.addCase(getAllPayments.fulfilled , (state ,action) =>{
        state.allPayments  = action.payload;
      })

      builder.addCase(downloadPayment.fulfilled , (state , action) =>{
        state.downloadPaymentList = action.payload;
      })
      
      builder.addCase(searchPayment.fulfilled ,(state , action) =>{
        state.searchData = action.payload;
      })
     
    },
  });
  
  export default payments.reducer;