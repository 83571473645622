import React, { useEffect, useDeferredValue } from "react";
import { Row, Col } from "react-bootstrap";
import { Anchor, Item, Box, Button } from "../../components/elements";
import { CardLayout, CardHeader, FloatCard } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import LabelField from "../../components/fields/LabelField";
import RidesTable from "../../components/tables/RidesTable";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/rideManagement.json";
import { useDispatch, useSelector } from "react-redux";
import { getAllRides } from "../../slices/rideManagementSlice";
import { changeLabel } from "../../slices/sidebarSlice";
import { SearchIcon , DownloadIcon} from "../../components/icons/icons";
import { Search } from "../../components/fields/search";
import { getDateInYYYYMMDD } from "../../components/elements/DatePicker";
import DatePicker from "../../components/elements/DatePicker";
// import { downloadRide } from "../../services/rideManagementService";
import { downloadRides } from "../../slices/rideManagementSlice";
import { downloadCsv } from "../../components/elements/DownloadCsv";
import { ToastContainer, toast } from "react-toastify";
import { searchRide } from "../../slices/rideManagementSlice";
import "react-toastify/dist/ReactToastify.css";

export default function RideManagement() {
  const [date, setDate] = React.useState({});
  const [filter, setFilter] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue , setSearchValue] = React.useState("");
  const [itemsPerPage] = React.useState(10);
  const [searchActive, setSearchActive] = React.useState(false);

  const [selectedRange , setSelectedRange] = React.useState({
    startDate : new Date(),
    endDate : new Date()
  })

  const dispatch = useDispatch();
  const allRides = useSelector((state) => state.rideManagement.allRides);

  const searchData = useSelector((state) => state.rideManagement.searchData)

  
  // const fetchRides = (params) =>{
  //   dispatch(getAllRides(params))
  //   .unwrap()
  //   .catch((error) => console.log(error));

  // }

  const fetchRides = (params) => {
    dispatch(
      getAllRides(params))
      .unwrap()
      .catch((error) => console.log(error));
  };
  


  React.useEffect(() => {
   fetchRides({status : "scheduled" ,page: currentPage,
    limit: itemsPerPage})
    dispatch(changeLabel("ridemanagement"));
  }, []);


  const downloadRideDetail = async (params) => {
    try {
  
      const csvContent =  await  dispatch(downloadRides(params)).unwrap();
      downloadCsv(csvContent, "ScheduledRidesDetails.csv")

    } catch (error) {
        toast.error("No Data found");
    }
  };
  
 

 const handleDateRange = (range) =>{
  let {startDate , endDate } = range;
  setSelectedRange(range);
  let formattedStart =  getDateInYYYYMMDD(startDate);
  let formattedEnd = getDateInYYYYMMDD(endDate);
  fetchRides({status : "scheduled", fromDate : formattedStart  , toDate  : formattedEnd , page : currentPage ,limit : itemsPerPage })
 }

 
 const DownloadData = () =>{
  let startDate = getDateInYYYYMMDD(selectedRange?.startDate)
  let endDate = getDateInYYYYMMDD(selectedRange?.endDate)
  downloadRideDetail({status : "scheduled" , fromDate : startDate , toDate : endDate})
  
}

  // const handlePageChange = (newPage) => {
  //   console.log(newPage , "new Page")
  //   setCurrentPage(newPage);
  // };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage); // Update the current page
   const params = {
      status: "scheduled",
      page: newPage,
      limit: itemsPerPage, // Set the items per page if necessary
    }
    // fetchRides(params)
    applyFilters()
  };
  

  const searchRides = (data) =>{
    setSearchActive(true)
     dispatch(searchRide(data))
     .unwrap()
     .catch((error) => console.log(error));
  }


  const handleChange = (e)=>{
    if(e.target.value === ""){
       setSearchActive(false)
    }
    setSearchValue(e.target.value)
    
  }


  const getStartIndex = () => {
    return (currentPage - 1) * itemsPerPage;
  };


  const applyFilters = () => {
    const params = { status: "scheduled"};
  
    if (selectedRange) {
      params.fromDate = getDateInYYYYMMDD(selectedRange.startDate);
      params.toDate = getDateInYYYYMMDD(selectedRange.endDate);
      // filterRides(params);
      fetchRides(params)
    }
  
    if (searchValue) {
      params.searchTerms = searchValue;
      searchRides(params);
    } 
    else {
      fetchRides({ status: "scheduled", page: currentPage, limit: itemsPerPage });
    }
  };
  
  
  useEffect(() => {
    applyFilters();
  }, [currentPage , searchValue, selectedRange]);

  const tbody = searchActive && searchData?.length === 0 ? [] : (searchActive ? searchData : allRides)
 

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={data?.pageTitle}>
              {data?.breadcrumb.map((item, index) => (
                <Item key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </Item>
              ))}
            </Breadcrumb>
          </CardLayout>
        </Col>

      

        <Col xl={12}>
          <CardLayout>
            <CardHeader title="Detailed Information of all the Scheduled Rides" />

            <div className="d-flex justify-content-between flex-nowrap pb-4">
            <div className="d-flex gap-2">
            <DatePicker onDateRangeSubmit  = {handleDateRange} />
             <div className="d-flex cursor-pointer wrap-content px-2 gap-1 " onClick = {DownloadData}>
              
         <div className="px-1"><DownloadIcon /> </div>
         <div className="text-uppercase">Export</div>
      
            </div>
            </div>

           <Col xl = {5}>
           <div className="d-flex searchClass"   >
            <span className="px-1"><SearchIcon /> </span>
           <span className="search-input"><Search placeholder="Search by rideID/name/email"  value= {searchValue || ""} onChange = {handleChange}/> </span></div>

           </Col>
           </div>

          
            {/* <RidesTable
              thead={data?.table.thead}
              // tbody={data?.table.ttbody}
              tbody = {tbody}
              // tbody={searchData.length > 0  ? searchData : allRides}
            />
            <Pagination /> */}
            <RidesTable
              thead={data?.table.thead}
              startIndex = {getStartIndex}
              tbody={searchData.length > 0 ? tbody : tbody?.docs}
            /> 
        {tbody.totalDocs > 0 &&
             <Pagination
              totalItems={tbody?.totalDocs}
              itemsPerPage={tbody?.pagingCounter}
              currentPage={currentPage}
              limit={tbody?.limit}
              onPageChange={handlePageChange}
            /> 
        }
          </CardLayout>
        </Col>
      </Row>
      <ToastContainer />
    </PageLayout>
  );
}
