import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import {
  Button,
  Image,
  Input,
  Text,
  Box,
  Icon,
  Anchor,
  Option,
  Heading,
} from "../elements";
import userInfo from "../../data/master/riderManagement.json";
import { useNavigate } from "react-router-dom";
import { ViewProfileIcon , ActiveToggleIcon , ToggleIcon } from "../icons/icons";


// { thead, tbody, filterData }
export default function RiderTable({ tbody, startIndex , toggleChange }) {
  console.log(startIndex() , "start")
  const [data, setData] = useState([]);
  const [userData, setUserData] = React.useState("");
  const [editModal, setEditModal] = React.useState(false);
  const [blockModal, setBlockModal] = React.useState(false);

  const navigate = useNavigate();

  const thead = [
    "rider name",
    // "gender",
    "email",
    "phone",
    "status",
    "created at",
    "profile",
    "action",
  ];

  useEffect(() => {
    setData(tbody);
  }, [tbody]);

  // useEffect(() => {
  //   if (filterData.length !== 0) {
  //     setData(filterData);
  //   } else {
  //     setData(tbody);
  //   }
  // }, [tbody, filterData]);

  const handleCheckbox = (event) => {
    const { name, checked } = event.target;

    if (name === "allCheck") {
      const checkData = data?.map((item) => {
        return { ...item, isChecked: checked };
      });
      setData(checkData);
    } else {
      const checkData = data?.map((item) =>
        item.name === name ? { ...item, isChecked: checked } : item
      );
      setData(checkData);
    }
  };

  const handleClick = (item) => {
    navigate("/rider-profile", { state: item });
  };

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                {/* <Input
                  type="checkbox"
                  name="allCheck"
                  checked={
                    data?.filter((item) => item.isChecked !== true).length < 1
                  }
                  onChange={handleCheckbox}
                /> */}
                <Text>uid</Text>
              </Box>
            </Th>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {data?.length > 0 ? data?.map((item, index) => (
            <Tr key={index} onClick={() => handleClick(item._id)}>
              <Td title="id">
                <Box className="mc-table-check">
                  {/* <Input
                    type="checkbox"
                    name={item.name}
                    checked={item?.isChecked || false}
                    onChange={handleCheckbox}
                  /> */}
                  <Text>#{startIndex() + index + 1}</Text>
                </Box>
              </Td>
              <Td title={item?.name}>
                <Box className="mc-table-profile">
                  {/* <Image src={item.src} alt={item.alt} /> */}
                  <Text className = "text-capitalize">{item?.name}</Text>
                </Box>
              </Td>
              {/* <Td title={item?.gender}>{item?.gender}</Td> */}
              <Td title={item?.email}><Text className = "text-capitalize">{item?.email ? item?.email : ""}</Text></Td>
              <Td title={item?.phone}>{item?.phone}</Td>
              <Td title={item?.isActive}>
                {item?.isActive && (
                  <Text className="activeTag">active</Text>
                )}

                {!item?.isActive && (
                  <Text className="inActiveTag">inactive</Text>
                )}
              </Td>
            
              <Td title={item?.createdAt}>
                {new Date(item?.createdAt).toLocaleDateString()}
              </Td>
              <Td>
                <Box className="mc-table-action pe-auto align-items-center cursor-pointer">
                <div  onClick={() => handleClick(item._id)}>
                    <ViewProfileIcon />
                  </div>
                </Box>
                  {/* <Anchor
                    href="/rider-profile"
                    title="View"
                    className="material-icons view"
                  >
                    {item.action.view}
                  </Anchor> */}
                  {/* <Button
                    title="View"
                    className="material-icons view"
                    onClick={() => handleClick(item)}
                  >
                    visibility
                  </Button> */}
                  {/* <Form.Check
                    type="switch"
                    id="switch"
                    defaultChecked={item?.isActive}
                    onChange={() => toggleChange(item?.authId)}
                  /> */}
                  {/* <Button
                    title="Edit"
                    className="material-icons edit"
                    onClick={() => setEditModal(true, setUserData(item))}
                  >
                    {item.action.edit}
                  </Button> */}
                  {/* <Button
                    title="Block"
                    className="material-icons block"
                    onClick={() => setBlockModal(true)}
                  >
                    {item.action.block}
                  </Button> */}
                {/* </Box> */}
              </Td>
              <Td title = {item.action}>
              <Box className="mc-table-action pe-auto align-items-center  cursor-pointer">
                <div onClick = {async(event) =>{
                   event.stopPropagation(); 
                 await toggleChange(item?.authId)
                }}>
                  {item?.isActive ? <ActiveToggleIcon /> : <ToggleIcon />}
                 </div>
                </Box>
              </Td>
            </Tr>
          ))  :
          <tr  className = "">
       <td colspan = {thead.length + 1} valign = "top" className = "text-center">
         No Data available in table
         </td>
        </tr>
          }
        </Tbody>
      </Table>

      <Modal
        show={editModal}
        onHide={() => setEditModal(false, setUserData(""))}
      >
        <Box className="mc-user-modal">
          <Image src={userData?.src} alt={userData?.alt} />
          <Heading as="h4">{userData?.name}</Heading>
          <Text as="p">{userData?.email}</Text>
          <Form.Group className="form-group inline mb-4">
            <Form.Label>role</Form.Label>
            <Form.Select>
              <Option>{userData?.role ? userData?.role.text : ""}</Option>
              {userInfo?.role?.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="form-group inline">
            <Form.Label>status</Form.Label>
            <Form.Select>
              <Option>{userData?.status}</Option>
              {userInfo?.status?.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Form.Select>
          </Form.Group>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setEditModal(false)}
            >
              close popup
            </Button>
            <Button
              type="button"
              className="btn btn-success"
              onClick={() => setEditModal(false)}
            >
              save Changes
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>

      <Modal show={blockModal} onHide={() => setBlockModal(false)}>
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">Want to block this user's account?</Text>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setBlockModal(false)}
            >
              nop, close
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => setBlockModal(false)}
            >
              yes, block
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>
    </Box>
  );
}
