import React from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Text, Item, Anchor, Button } from "../../components/elements";
import { LabelField } from "../../components/fields";
import { Breadcrumb, DivideTitle } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/addAdmin.json";
import Multiselect from "multiselect-react-dropdown";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAdmin,
  getAllActivePermissions,
} from "../../slices/teamManagementSlice";
import { getAllCountry } from "../../slices/countryManagementSlice";
import { changeLabel } from "../../slices/sidebarSlice";

let permissionId = [];
export default function AddAdmin() {
  const email_pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const [adminData, setAdminData] = React.useState({
    role: "admin",
    permissions: [],
    phone :  ""
  });

  const dispatch = useDispatch();

  const activePermissions = useSelector(
    (state) => state.teamManagement.activePermissions
  );

  const countryList = useSelector(
    (state) => state.countryManagement.countryList
  );

 
  const [code, setCode] = React.useState(countryList?.[0]?.phoneCode || "");
  const navigate = useNavigate();

  const handleChange = (e) => {
   
    setAdminData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };



  const handlePhoneChange = (e) => {
    let inputValue = e.target.value;
    
    if (inputValue.startsWith(code)) {
      inputValue = inputValue.slice(code.length); 
    }
    
    setAdminData((prev) => ({
      ...prev,
      phone: code + inputValue, 
    }));
  };
  
  
  const handleCodeChange = (e) => {
    const newCode = e.target.value;
  
    setAdminData((prev) => ({
      ...prev,
      phone: prev.phone.startsWith(code) 
        ? newCode + "" 
        : newCode + "", 
    }));
    

    setCode(newCode);
  };
  
  
  const onSelect = (selectedList) => {
    selectedList.map((item) => {
      if (!permissionId.includes(item._id)) {
        permissionId.push(item._id);
      }
    });

    setAdminData((prev) => ({
      ...prev,
      permissions: permissionId,
    }));
  };

  const onRemove = (_, removedItem) => {
    permissionId = permissionId.filter((id) => id !== removedItem._id);
    setAdminData((prev) => ({
      ...prev,
      permissions: permissionId,
    }));
  };

  const handleGender = (_, selectedItem) => {
      setAdminData((prev) => ({
      ...prev,
      
      gender: selectedItem.gender.toLowerCase(),
    }));
  };

  const getActivePermissions = () => {
    dispatch(getAllActivePermissions({ isActive: true }))
      .unwrap()
      .catch((error) => console.log("Something went wrong!"));
  };

  React.useEffect(() => {
    getActivePermissions();

    dispatch(getAllCountry())
      .unwrap()
      .then((countries) =>{
        setCode(countries?.[0]?.phoneCode  || "" )
      })
      .catch(() => toast.error("Something unwanted occurred!"));

    dispatch(changeLabel("teammanagement"));
  }, []);

  React.useEffect(() => {
    if (adminData.phone) {
      setAdminData((prev) => ({
        ...prev,
        phone: "",
      }));
    }
  }, [code]);

  const onsubmit = () => {
    if (!adminData.name) {
      toast.warn("Name is required!");
    } else if (!adminData.email) {
      toast.warn("Email is required!");
    }
    else if (!email_pattern.test(adminData.email)) {
      toast.warn("Invalid email");
    }else if(!adminData.phone){
       toast.warn("phone is required")
    } else if (adminData.permissions?.length === 0) {
      console.log("No permission selected");
      toast.warn("Permission is required!");
    } else {
      dispatch(addNewAdmin(adminData))
        .unwrap()
        .then(() => {
          toast.success("Admin added successfully");
          setTimeout(() => {
            navigate("/team-list");
          }, 1000);
        })
        .catch((error) => toast.error("Something went wrong"));
    }
  };

 
  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>

      <CardLayout className="p-sm-5">
          <div className="container "> 
            <div className="row">
              <div className = "col-md-3">
                <Text >Name</Text>
                <LabelField
                  type="text"
                  name="name"
                  placeholder = "Type admin's name"
                  fieldSize={`w-xl h-md`}
                  onChange={handleChange}
                />
                 </div>

                 <div className = "col-md-3">
                <Text >Email</Text>
                <LabelField
                  type="text"
                  name="email"
                  placeholder = "Type admin's email"
                  fieldSize={`w-xl h-md`}
                  onChange={handleChange}
                />
                 </div>
          
                 <div className = "col-md-3">
              <Text>Phone</Text>
     
                  <div className ="d-flex">
                    <select
                    className = "phoneCode"
                      value={code}
                      onChange={handleCodeChange}
                    >
                      {countryList?.map((country, index) => (
                        <option key={index} value={country?.phoneCode}>
                          {country?.phoneCode}
                        </option>
                      ))}
                    </select>
                  

                    <input
                      type="tel"
                      name="phone"
                      maxLength="10"
                      pattern="[1-9]{1}[0-9]{9}"
                      className = "phoneInput"
                      placeholder="Type admin's mobile no."
                      value={adminData?.phone.slice(code.length)}
                      onChange={handlePhoneChange}
                      onBlur={(e) => {
                        if (e.target.validationMessage)
                          toast.warn("Please enter a valid number!");
                        else return;
                      }}
                    />
                    </div>
                  </div>
                  </div>

                  
                  <div className="row mt-3" >              
                <div className = "col-md-3 ">
                  Gender
                  <Multiselect
                    options={[
                      { gender: "Male", id: 1 },
                      { gender: "Female", id: 2 },
                      { gender: "Others", id: 3 },
                    ]}
                    displayValue="gender"
                    placeholder="Choose Gender"
                    // hidePlaceholder="true"
                    selectionLimit="1"
                    avoidHighlightFirstOption
                    closeOnSelect
                    onSelect={handleGender}
                    customCloseIcon={
                      <button
                        type="button"
                        className="btn-close ms-2"
                        aria-label="Close"
                      ></button>
                    }
                    className="arrow-dropdown"
                    style={{
                      multiselectContainer: {
                        width: "auto",
                        backgroundColor: "#FAFAFA",
                        borderRadius: "8px",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                        flexWrap: 'wrap',
                      },
                      // searchBox: {
                      //   borderRadius: "8px",
                      // },
                      inputField: {
                        padding: "3px",
                        marginLeft: "5px",
                        
                      },
                      chips: {
                        background: "#f9c935",
                        fontSize: "15px",
                        color: "#5e5d72",
                        padding: "4px 6px",
                        margin : "2px",
                      },
                    }}
                  />
                </div>

               <div className = "col-md-3">
                  <Text>Permissions</Text>
              
                  <Multiselect
                    options={activePermissions}
                    displayValue="label"
                    placeholder="Choose Permissions"
                    // hidePlaceholder="true"
                    onSelect={onSelect}
                    onRemove={onRemove}
                    avoidHighlightFirstOption
                    customCloseIcon={
                      <button
                        type="button"
                        className="btn-close ms-2"
                        aria-label="Close"
                      ></button>
                    }
                    className="arrow-dropdown"
                    style={{
                      multiselectContainer: {
                        width: "auto",
                        backgroundColor: "#FAFAFA",
                        borderRadius: "8px",
                        cursor: "pointer",
                        flexWrap: 'wrap',
                       
                      },
                      // searchBox: {
                      //   borderRadius: "8px",
                      // },
                      inputField: {
                        padding: "3px",
                        marginLeft: "5px",
                       
                      },
                      chips: {
                        background: "#f9c935",
                        fontSize: "13px",
                        color: "#5e5d72",
                        margin: "2px",
                        padding: "4px 6px",
                      },
                    }}
                  />
                </div>
                
            
            </div>
                </div>

              
       <div className="d-flex justify-content-end mt-4">
         <div 
        className="blackButton"
                    onClick = {() => onsubmit() }
          >
            Submit
            </div>
            </div>
      </CardLayout>
      <ToastContainer />
     
    </PageLayout>
  );
}



