import { Endpoints, HttpMethods } from "../constants";
import ApiCall from "../utils/ApiCall";

export const addRidePrice = (data) =>
  ApiCall(Endpoints.ADD_RIDE_PRICE, HttpMethods.POST, null, data);

export const getRidePrice = () =>
  ApiCall(Endpoints.GET_RIDE_PRICE, HttpMethods.GET);

export const getRidePriceById = (id) =>
  ApiCall(
    Endpoints.GET_RIDE_PRICE_BY_ID.replace(":id", id),
    HttpMethods.GET
  );

export const updateRidePrice = (data) =>
  ApiCall(Endpoints.UPDATE_RIDE_PRICE, HttpMethods.PUT, null, data);

export const addPolicy = (data) =>
  ApiCall(Endpoints.ADD_POLICY, HttpMethods.POST, null, data);

export const getPolicy = () =>
  ApiCall(Endpoints.GET_POLICY, HttpMethods.GET);

export const getPolicyById = (id) =>
  ApiCall(Endpoints.GET_POLICY_BY_ID.replace(":id", id), HttpMethods.GET);

export const updatePolicy = (data) =>
  ApiCall(Endpoints.UPDATE_POLICY, HttpMethods.PUT, null, data);

export const addLanguage = (data) =>
  ApiCall(Endpoints.ADD_LANGUAGE, HttpMethods.POST, null, data);

export const toggleLanguage = (id) =>
  ApiCall(Endpoints.TOGGLE_LANGUAGE.replace(":id", id), HttpMethods.GET);

export const editLanguage = (data) =>
  ApiCall(Endpoints.EDIT_LANGUAGE, HttpMethods.PUT, null, data);

export const addVehicleType = (data) =>
  ApiCall(Endpoints.ADD_VEHICLE_TYPE, HttpMethods.POST, null, data);

export const toggleVehicleType = (id) =>
  ApiCall(
    Endpoints.TOGGLE_VEHICLE_TYPE.replace(":id", id),
    HttpMethods.GET
  );

export const editVehicleType = (data) =>
  ApiCall(Endpoints.EDIT_VEHICLE_TYPE, HttpMethods.PUT, null, data);

export const addCompany = (data) =>
  ApiCall(Endpoints.ADD_COMPANY, HttpMethods.POST, null, data);

export const getAllCompany = () =>
  ApiCall(Endpoints.GET_ALL_COMPANY, HttpMethods.GET);

export const toggleCompany = (id) =>
  ApiCall(Endpoints.TOGGLE_COMPANY.replace(":id", id), HttpMethods.GET);

export const editCompany = (data) =>
  ApiCall(Endpoints.EDIT_COMPANY, HttpMethods.PUT, null, data);

export const addState = (data) =>
  ApiCall(Endpoints.ADD_STATE, HttpMethods.POST, null, data);

export const toggleState = (id) =>
  ApiCall(Endpoints.TOGGLE_STATE.replace(":id", id), HttpMethods.GET);

export const editState = (data) =>
  ApiCall(Endpoints.EDIT_STATE, HttpMethods.PUT, null, data);

export const addCounty = (data) =>
  ApiCall(Endpoints.ADD_COUNTY, HttpMethods.POST, null, data);

export const getAllCounty = () =>
  ApiCall(Endpoints.GET_ALL_COUNTY, HttpMethods.GET);

export const toggleCounty = (id) =>
  ApiCall(Endpoints.TOGGLE_COUNTY.replace(":id", id), HttpMethods.GET);

export const editCounty = (data) =>
  ApiCall(Endpoints.EDIT_COUNTY, HttpMethods.PUT, null, data);

export const addVehicleColor = (data) =>
  ApiCall(Endpoints.ADD_COLOR, HttpMethods.POST, null, data);

export const getAllVehicleColor = (params) =>
  ApiCall(Endpoints.GET_ALL_COLOR, HttpMethods.GET, params);

export const toggleVehicleColor = (id) =>
  ApiCall(Endpoints.TOGGLE_COLOR.replace(":id", id), HttpMethods.GET);

export const editVehicleColor = (data) =>
  ApiCall(Endpoints.EDIT_COLOR, HttpMethods.PUT, null, data);

export const addReason = (data) =>
  ApiCall(Endpoints.ADD_REASON, HttpMethods.POST, null, data);

export const getAllReasons = () =>
  ApiCall(Endpoints.GET_ALL_REASON, HttpMethods.GET);

export const toggleReason = (id) =>
  ApiCall(Endpoints.TOGGLE_REASON.replace(":id", id), HttpMethods.GET);

export const editReason = (data) =>
  ApiCall(Endpoints.EDIT_REASON, HttpMethods.PUT, null, data);
