import React from "react";
import Section from "../components/elements/Section";

export default function Footer() {
  const year = new Date();
  return (
    // <Section as="footer" className="mc-footer">© All Rights Reserved by ♥ Hail-A-Taxi</Section>
    <Section as="footer" className="mc-footer">
      © {year.getFullYear()} Hail-A-Taxi
    </Section>
  );
}
