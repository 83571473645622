import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addAdmin,
  addPermission,
  changePermission,
  downloadAdmins,
  editPermission,
  getActivePermissions,
  getAdmins,
  toggleChangeAdmin,
  toggleChangePermission,
} from "../services/teamManagementService";

export const getAllAdmins = createAsyncThunk(
  "manage_team/getAllAdmins",
  async (params, { rejectWithValue }) => {
    try {
      const res = await getAdmins(params);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const toggleChange = createAsyncThunk(
  "manage_team/toggleChange",
  async ({ id, params }, { dispatch, rejectWithValue }) => {
    try {
      const res = await toggleChangeAdmin(id);
      if (res.status === 200) {
        await dispatch(getAllAdmins(params));
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeAdminPermission = createAsyncThunk(
  "manage_team/changeAdminPermission",
  async (data, { rejectWithValue }) => {
    try {
      const res = await changePermission(data);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllActivePermissions = createAsyncThunk(
  "manage_team/getAllActivePermissions",
  async (params, { rejectWithValue }) => {
    try {
      const res = await getActivePermissions(params);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewAdmin = createAsyncThunk(
  "manage_team/addNewAdmin",
  async (adminData, { rejectWithValue }) => {
    try {
      const res = await addAdmin(adminData);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewPermission = createAsyncThunk(
  "manage_team/addNewPermission",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await addPermission(data);
      if (res.status === 200) {
        await dispatch(getAllActivePermissions());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const toggleChangePermissionById = createAsyncThunk(
  "manage_team/toggleChangePermissionById",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const res = await toggleChangePermission(id);
      if (res.status === 200) {
        await dispatch(getAllActivePermissions());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editExistingPermission = createAsyncThunk(
  "manage_team/editExistingPermission",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await editPermission(data);
      if (res.status === 200) {
        await dispatch(getAllActivePermissions());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadTeam = createAsyncThunk(
  "manage_team/downloadTeam",
  async(params , {dispatch , rejectWithValue }) =>{
    try{
      const res = await downloadAdmins(params);
      if(res.status === 200){
        return res.data;
      }else throw res.error;
    }catch(err){
      return rejectWithValue(err);
    }
  }
)

const teamManagement = createSlice({
  name: "manage_team",
  initialState: {
    // adminList: {},
    adminList: [],
    activePermissions: [],
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAdmins.fulfilled, (state, action) => {
      state.adminList = action.payload;
    });

    builder.addCase(toggleChange.fulfilled);

    builder.addCase(changeAdminPermission.fulfilled);

    builder.addCase(getAllActivePermissions.fulfilled, (state, action) => {
      state.activePermissions = action.payload;
    });

    builder.addCase(addNewAdmin.fulfilled);

    builder.addCase(addNewPermission.fulfilled);

    builder.addCase(toggleChangePermissionById.fulfilled);

    builder.addCase(downloadTeam.fulfilled);
  },
});

export default teamManagement.reducer;
