import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Text, Box, Button } from "../elements";
import { useDispatch } from "react-redux";
import { editExistingLanguage } from "../../slices/manageSettingsSlice";
import { EditIcon , ActiveToggleIcon , ToggleIcon } from "../icons/icons";
import { CardLayout } from "../cards";
import DivideTitle from "../DivideTitle";

export default function LanguageTable({ languages, toggleChange, toast }) {
  const [data, setData] = useState([]);
  const [editModal, setEditModal] = React.useState(false);
  const [userData, setUserData] = React.useState("");
  const [editData, setEditData] = React.useState({
    Id: "",
    label: "",
  });

  const dispatch = useDispatch();

  const thead = ["language", "status", "action"];

  useEffect(() => {
    setData(languages);
  }, [languages]);

  const handleEditData = (e, id) => {
    setEditData({
      Id: id,
      label: e.target.value,
    });

    setUserData((prev) => ({
      ...prev,
      label: e.target.value,
    }));
  };

  const onEditLanguage = () => {
    if (editData?.label) {
      dispatch(editExistingLanguage(editData))
        .unwrap()
        .then((res) => {
          toast.success(res.result);
          setEditModal(false);
          setEditData({});
        })
        .catch((error) => toast.error("Something went wrong!"));
    } else {
      toast.warn("No changes made!");
      setEditModal(false);
    }
  };

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                <Text>uid</Text>
              </Box>
            </Th>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        {data?.length > 0 ? (
          <Tbody className="mc-table-body even">
            {data?.map((item, index) => (
              <Tr key={index}>
                <Td title="id">
                  <Box className="mc-table-check">
                    <Text>#{index + 1}</Text>
                  </Box>
                </Td>
                <Td title={item?.label}>{item?.label}</Td>
                <Td>
                {item?.isActive && (
                    <Text className="activeTag ">active</Text>
                  )}

                  {!item?.isActive && (
                    <Text className="inActiveTag">inactive</Text>
                  )}
                </Td>
                <Td>
                  <Box className="mc-table-action align-items-center">
                    <div
                      // title="Edit"
                      // className="material-icons edit"
                      onClick={() => setEditModal(true, setUserData(item))}
                    >
                      <EditIcon />
                    </div>
                    <div onClick = {async() => await toggleChange(item?._id)}>
                  {item?.isActive ? <ActiveToggleIcon /> : <ToggleIcon />}
                 </div>

                    {/* <Form.Check
                      type="switch"
                      id="switch"
                      defaultChecked={item.isActive}
                      onChange={() => toggleChange(item._id)}
                    /> */}
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        ) : null}
      </Table>

      <Modal
        show={editModal}
        onHide={() => setEditModal(false, setUserData(""))}
      >
        <CardLayout >
         <DivideTitle title= "Edit Language" className="mb-4" />
          <Form.Group className="form-group">
            {/* <Form.Label>edit language</Form.Label> */}
            <Form.Control
              type="text"
              value={userData?.label}
              onChange={(e) => handleEditData(e, userData?._id)}
            />
          </Form.Group>

          <Modal.Footer>
            <Box className="d-flex gap-2">
            <div
              className="whiteButton"
              onClick={() => setEditModal(false)}
            >
              close
            </div>
            <div
              type="button"
              className="blackButton"
              onClick={onEditLanguage}
            >
              save
            </div>
            </Box>
          </Modal.Footer>
        </CardLayout>
      </Modal>
    </Box>
  );
}
