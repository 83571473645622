import { Endpoints, HttpMethods } from "../constants";
import ApiCall from "../utils/ApiCall";

export const getAdmins = (params) =>
  ApiCall(Endpoints.GET_ALL_PROFILE, HttpMethods.GET, params);

export const toggleChangeAdmin = (id) =>
  ApiCall(Endpoints.TOGGLE_USER.replace(":id", id), HttpMethods.GET);

export const changePermission = (data) =>
  ApiCall(Endpoints.CHANGE_PERMISSION, HttpMethods.POST, null, data);

export const getActivePermissions = (params) =>
  ApiCall(Endpoints.GET_ALL_PERMISSION, HttpMethods.GET, params);

export const addAdmin = (data) =>
  ApiCall(Endpoints.ADD_USER, HttpMethods.POST, null, data);

export const addPermission = (data) =>
  ApiCall(Endpoints.ADD_PERMISSION, HttpMethods.POST, null, data);

export const toggleChangePermission = (id) =>
  ApiCall(Endpoints.TOGGLE_PERMISSION.replace(":id", id), HttpMethods.GET);

export const editPermission = (data) =>
  ApiCall(Endpoints.EDIT_PERMISSION, HttpMethods.PUT, null, data);

export const downloadAdmins = (params) =>
  ApiCall(Endpoints.DOWNLOAD_PROFILES , HttpMethods.GET , params);