import React from "react";
import { Table , Thead, Tbody , Th, Tr , Td} from "../elements/Table";
import { Text, Box, Heading, Image } from "../elements";
import { useNavigate } from "react-router-dom";

export default function DetailedPayout({ startIndex , data}) {
  const navigate = useNavigate();
  const handleClick = (item) => {
    navigate("/riding", { state: item });
  };

  const thead = ["ride id ", "ride date ", "ride fare", "mode of collect"];
  return (
   
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                <Text>uid</Text>
              </Box>
            </Th>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
        {data?.length > 0 ? data?.map((item, index) =>(
              <Tr key={index}  onClick = {() => handleClick(item._id)} >
                <Td title="id">
                  <Box className="mc-table-check">
                    <Text>#{startIndex() +index + 1}</Text>
                  </Box>
                </Td>
                <Td title={item?.locations?.pickupLocation?.name}>
                  <span className='text-truncate'>{item?.locations?.pickupLocation?.name}</span>
                </Td>
                <Td title={item?.locations?.dropoffLocation?.name}>
                <span className='text-truncate'>{item?.locations?.dropoffLocation?.name}</span>
                </Td>
                <Td title={item?.totalPrice}>$ {item?.totalPrice.toFixed(2)}</Td>
            
                <Td title={item?.createdAt}>
                 {new Date(item?.createdAt).toLocaleDateString()}
                </Td>
                <Td title={item?.distance}>$ {item?.distance}</Td>
                <Td title={item?.status}>{item?.status === "completed" && (
                    <span className="completedTag">{item?.status}
                      </span>
                  )}
                  {item?.status === "scheduled" && (
                    // <Text className="mc-table-badge purple">{item?.status}</Text>
                    <span className="scheduledTag">{item?.status}
                      </span>
                  )}
                  {item?.status === "cancelled" && (
                    // <Text className="mc-table-badge red">{item?.status}</Text>
                    <span className="cancelledTag">{item?.status}
                      </span>
                  )}
                   {item?.status === "ongoing" && (
                    // <Text className="mc-table-badge purple">{item?.status}</Text>
                    <span className="ongoingTag">{item?.status}
                      </span>
                  )}
                </Td>
              </Tr>
            )
          )
        :
        <tr >
          <td colspan = {thead.length + 1} className="text-center" valign = "top">No Data available in table</td>
        </tr>
        }
        </Tbody>
      </Table>
    </Box>
  );
}
