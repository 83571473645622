import { Endpoints, HttpMethods } from "../constants";
import ApiCall from "../utils/ApiCall";

export const getPayments = (params) =>
    ApiCall(Endpoints.FETCH_ALL_CASH_PAYMENTS , HttpMethods.GET , params)
  
export const downloadPayments =  (params) =>
    ApiCall(Endpoints.DOWNLOAD_PAYMENTS , HttpMethods.GET , params)

export const searchPayments = (params) =>
    ApiCall(Endpoints.SEARCH_PAYMENTS , HttpMethods.GET , params);