import React from "react";
import { Row, Col } from "react-bootstrap";
import { Anchor, Item, Box, Button } from "../../components/elements";
import { CardLayout, CardHeader, FloatCard } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import LabelField from "../../components/fields/LabelField";
import RidesTable from "../../components/tables/RidesTable";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/rideManagement.json";
import { useDispatch, useSelector } from "react-redux";
import { getAllRides } from "../../slices/rideManagementSlice";
import { changeLabel } from "../../slices/sidebarSlice";
import { SearchIcon , DownloadIcon} from "../../components/icons/icons";
import { Search } from "../../components/fields/search";
import { getDateInYYYYMMDD } from "../../components/elements/DatePicker";
import DatePicker from "../../components/elements/DatePicker";
import { downloadRides } from "../../slices/rideManagementSlice";
import { downloadCsv } from "../../components/elements/DownloadCsv";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { searchRide } from "../../slices/rideManagementSlice";
import { current } from "@reduxjs/toolkit";

export default function OngoingRide() {
  const [date, setDate] = React.useState({});
  const [filter, setFilter] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage ] = React.useState(10);
  const [searchValue , setSearchValue ] = React.useState("");
  const [searchActive, setSearchActive] = React.useState(false);


  const searchData = useSelector((state) => state.rideManagement.searchData)


  const [selectedRange , setSelectedRange] = React.useState({
    startDate : new Date(),
    endDate : new Date()
  })

  const dispatch = useDispatch();
  const allRides = useSelector((state) => state.rideManagement.allRides);

  // console.log(allRides, "rtk...");
  const getRides = (params) =>{
    dispatch(getAllRides( params ))
    .unwrap()
    .catch((error) => console.log("Something unwanted occurred!"));
  }

  React.useEffect(() => {
    getRides({status : "ongoing" , page : currentPage ,
      limit : itemsPerPage})

    dispatch(changeLabel("ridemanagement"));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value === "all") {
      setFilter({});
    } else {
      setFilter((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    setDate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const downloadRideDetail = async (params) => {
    try {
  
      const csvContent =  await  dispatch(downloadRides(params)).unwrap();
      downloadCsv(csvContent, "'OngoingRideDetails.csv")

    } catch (error) {
     toast.error("No rides found");
    }
  };
 

 const handleDateRange = (range) =>{
  let {startDate , endDate } = range;
  setSelectedRange(range);
  let formattedStart =  getDateInYYYYMMDD(startDate);
  let formattedEnd = getDateInYYYYMMDD(endDate);
  getRides({status : "ongoing" , fromDate : formattedStart  , toDate  : formattedEnd , page : currentPage , limit : itemsPerPage })
 }


 const DownloadData = () =>{
  let startDate = getDateInYYYYMMDD(selectedRange?.startDate)
  let endDate = getDateInYYYYMMDD(selectedRange?.endDate)

 downloadRideDetail({status : "ongoing" , fromDate : startDate , toDate : endDate })
}

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    applyFilters()
  };

  const searchRides = async(data) =>{
    setSearchActive(true)
    const res = await dispatch(searchRide(data))
     .unwrap()
     .catch((error) => console.log(error));
  }
 
 const getStartIndex = () => {
  return (currentPage - 1) * itemsPerPage;
 }

  const handleSearchChange = (e)=>{
    if(e.target.value === ""){
      setSearchActive(false)
    }
    setSearchValue(e.target.value)
  
  }

  const applyFilters = () => {
    const params = {status : "ongoing" , page : currentPage , limit : itemsPerPage};
  
    if (selectedRange) {
        params.fromDate = getDateInYYYYMMDD(selectedRange.startDate);
        params.toDate = getDateInYYYYMMDD(selectedRange.endDate);
        getRides(params)
    }
  
     if(searchValue) {
        params.searchTerms = searchValue;
        searchRides(params);
    } else {
        getRides({status : "ongoing" , page : currentPage , limit : itemsPerPage});
    }
  };
  
  React.useEffect(() => {
    applyFilters();
  }, [currentPage , searchValue, selectedRange]);

  const tbody = searchActive && searchData.length === 0 ? [] : (searchActive ? searchData : allRides)


  const fun = () => {
    console.log("Fun...called!");
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={data?.pageTitle}>
              {data?.breadcrumb.map((item, index) => (
                <Item key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </Item>
              ))}
            </Breadcrumb>
          </CardLayout>
        </Col>


        <Col xl={12}>
          <CardLayout>
            <CardHeader title="Detailed Information of all the Ongoing Rides" />


            <div className="d-flex justify-content-between flex-nowrap pb-4">
              <div className="d-flex gap-2">
            {/* <span className="border rounded-xl  p-2 m-auto ">date and time</span>  */}
            <DatePicker onDateRangeSubmit  = {handleDateRange} />
             <div className="d-flex cursor-pointer wrap-content" onClick = {DownloadData}>
              
         <span className="px-1"><DownloadIcon /> </span>
         <span className="text-uppercase">Export</span>
      
            </div>
            </div>

           <Col xl = {5}>
           <div className="d-flex searchClass"   >
            <span className="px-1"><SearchIcon /> </span>
           <span className="search-input"><Search placeholder="Search by rideID/name/email"  value= {searchValue || ""} onChange = {handleSearchChange}/> </span></div>
           

           </Col>
           </div>

           

            <RidesTable
              thead={data?.table.thead}
              startIndex={getStartIndex}
              tbody={searchData?.length > 0 ? tbody : tbody?.docs}
            />
            {tbody.totalDocs > 0 &&
            <Pagination
              totalItems={tbody?.totalDocs}
              itemsPerPage={tbody?.pagingCounter}
              currentPage={currentPage}
              limit={tbody?.limit}
              onPageChange={handlePageChange}
            />
             }
          </CardLayout>
        </Col>
      </Row>
      <ToastContainer />
    </PageLayout>
  );
}
