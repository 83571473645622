import React from "react";
import {  Col } from "react-bootstrap";
import { Box, Item, Anchor, Button } from "../../components/elements";
import { Breadcrumb, DivideTitle } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/settings.json";
import { Country } from "country-state-city";
import { CountriesTable } from "../../components/tables";
import { CardHeader } from "../../components/cards";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewCountry,
  getAllCountry,
} from "../../slices/countryManagementSlice";
import { changeLabel } from "../../slices/sidebarSlice";
import Multipleselect from "../../components/Multipleselect";
import { AddIcon } from "../../components/icons/icons";

export default function CountryManagement() {
  const [countries, setCountries] = React.useState([]);
  const [country, setCountry] = React.useState({});
  const dispatch = useDispatch();

  const countryList = useSelector(
    (state) => state.countryManagement.countryList
  );

 

  const onSelect = (selectedList, selectedItem) => {
    setCountries(selectedList);
    setCountry(selectedItem);
  };

  const onRemove = (selectedList) => {
    setCountries(selectedList);
  };

  const handleClick = async () => {
    if (!country) {
      toast.warn("Add a country!");
    } else {
     
      dispatch(addNewCountry(country))
        .unwrap()
        .then(() => toast.success("Country added successfully"))
        .catch((error) => toast.error("Country not added or already exists"));
      setCountries([]);
    }
  };

  React.useEffect(() => {
    dispatch(getAllCountry())
      .unwrap()
      .catch(() => toast.error("Something unwanted occurred!"));

    dispatch(changeLabel("managesettings"));
  }, []);

  console.log(countries, "klkl")

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>

      <CardLayout className="p-sm-5">
        <Box className="mb-5">
          <DivideTitle title="manage countries" className="mb-5" />

         
                <div className="d-flex gap-2">
                 <Col xl = {4}>
                  <Multipleselect
                    options={Country.getAllCountries()}
                    displayValue="name"
                    placeholder="Choose Country"
                    onSelect={onSelect}
                    onRemove={onRemove}
                    selectionLimit={1}
                    selectedValues={countries}
                  />
                  </Col>

     
         <div className="blackButton" onClick= {() => handleClick() } >
            <span className="px-1"> <AddIcon /></span>
           Add Countries
           </div>
          

                </div>
        </Box>
      </CardLayout>

      <CardLayout className={`mt-4`}>
        <CardHeader title="Selected Countries" />
        <CountriesTable
          thead={["name", "isoCode", "flag", "phonecode", "action"]}
          list={countryList}
          toast={toast}
        />
      </CardLayout>
      <ToastContainer />
    </PageLayout>
  );
}
