import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PageLayout from "../../layouts/PageLayout";
import { Breadcrumb } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import { Item, Anchor } from "../../components/elements";
// import data from "../../data/master/driverManagement.json";
import data from "../../data/master/addDriverMultiStep.json";
import { NextIcon } from "../../components/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addDriverDocuments,
  appendPhoneCode,
  addNewDriver,
  removePhoneCode,
} from "../../slices/driverManagementSlice";
import {
  DriverDetail,
  BankDetail,
  DrivingLicenceDetail,
  ChauffeurDetail,
  VehicleInsurance,
  RegistrationCertificate,
  VehicleDetail,
  PreviewDetail,
} from "./MultiStepForm";
import { toast, ToastContainer } from "react-toastify";
import { changeLabel } from "../../slices/sidebarSlice";
import { useNavigate } from "react-router-dom";

const steps = [
  "Driver Details",
  "Bank Details",
  "Vehicle Details",
  "Licence Details",
  "Vehicle Insurance",
  "Registration Certificate",
  "Chauffeur Details",
  "Preview",
];

export default function MultiStepAddDriver() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const img = React.useRef([]);

  const [docs, setDocs] = React.useState({
    bank: {
      docImg: [],
    },
    dl: {
      docImg: [],
    },
    chauffeur: {
      docImg: [],
    },
    insurance: {
      docImg: [],
    },
    registration_certificate: {
      docImg: [],
    },
  });

  const navigate = useNavigate();

  const email_pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const dispatch = useDispatch();

  const { driverData } = useSelector((state) => state.driverManagement);
  console.log(driverData, "driver data for submit");

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (activeStep) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setSkipped(newSkipped);

    if (activeStep === 0) {
      if (!driverData?.name) {
        toast.warn("Enter driver's name!");
      } else if (!driverData?.phone) {
        toast.warn("Enter driver's phone number!");
      } else if (driverData?.phone === driverData.secondaryPhone) {
        toast.warn("Phone and secondary phone can't be same!");
      } else if (!driverData?.email) {
        toast.warn("Enter driver's email!");
      } else if (!email_pattern.test(driverData?.email)) {
        toast.warn("Enter a valid email!");
      } else if (!driverData?.gender) {
        toast.warn("Select driver's gender!");
      } else if (driverData?.language.length === 0) {
        toast.warn("Select languages known by driver!");
      } else if (!driverData?.driverState) {
        toast.warn("Select driver's Address!");
      } else if (!driverData?.driverCounty) {
        toast.warn("Select driver's Address Properly!");
      } else if (!driverData?.profileUrl) {
        toast.warn("Upload driver's profile image!");
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    } else if (activeStep === 1) {
      if (!docs?.bank?.firstName) {
        toast.warn("Enter first name of driver!");
      } else if (!docs?.bank?.lastName) {
        toast.warn("Enter last name of driver!");
      } else if (!docs?.bank?.documentNumber) {
        toast.warn("Enter bank account number!");
      } else if (!docs?.bank?.routingNumber) {
        toast.warn("Enter routing Number!");
      } else if (docs?.bank?.docImg?.length === 0) {
        toast.warn("Upload a cancelled cheque!");
      } else if (docs?.bank?.docImg?.length > 1) {
        toast.warn("You can upload only one file!");
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    } else if (activeStep === 2) {
      if (!driverData?.vehicleData?.vehicleName) {
        toast.warn("Enter vehicle name!");
      } else if (!driverData?.vehicleData?.vehicleNumber) {
        toast.warn("Enter vehicle number!");
      } else if (!driverData?.vehicleData?.type) {
        toast.warn("Please select vehicle type!");
      } else if (!driverData?.vehicleData?.color) {
        toast.warn("Please select vehicle color!");
      } 
      // else if (!driverData?.vehicleData?.numberOfSeats) {
      //   toast.warn("Enter number of seats!");
      // } 
      else if (driverData?.vehicleData?.vehicleGallery?.length === 0) {
        toast.warn("Upload vehicle images!");
      } else if (driverData?.vehicleData?.vehicleGallery?.length !== 4) {
        toast.warn("Upload four vehicle images!");
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    } else if (activeStep === 3) {
      if (!docs?.dl?.documentNumber) {
        toast.warn("Enter licence number!");
      } else if (!docs?.dl?.expirationDate) {
        toast.warn("Enter the expiry date!");
      } else if (docs?.dl?.docImg?.length === 0) {
        toast.warn("Upload licence images!");
      } else if (docs?.dl?.docImg?.length !== 2) {
        toast.warn("Upload licence both side! ");
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    } else if (activeStep === 4) {
      if (!docs?.insurance?.documentNumber) {
        toast.warn("Enter insurance number!");
      } else if (!docs?.insurance?.documentName) {
        toast.warn("Enter insurance company name!");
      } else if (!docs?.insurance?.expirationDate) {
        toast.warn("Enter the expiry date!");
      } else if (docs?.insurance?.docImg?.length === 0) {
        toast.warn("Upload vehicle insurance!");
      } else if (docs?.insurance?.docImg?.length > 1) {
        toast.warn("You can upload only one file! ");
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    } else if (activeStep === 5) {
      if (!docs?.registration_certificate?.documentNumber) {
        toast.warn("Enter vehicle registration number!");
      } else if (!docs?.registration_certificate?.expirationDate) {
        toast.warn("Enter the expiry date!");
      } else if (!docs?.registration_certificate?.make) {
        toast.warn("Enter vehicle make!");
      } else if (!docs?.registration_certificate?.model) {
        toast.warn("Enter vehicle model!");
      } else if (!docs?.registration_certificate?.year) {
        toast.warn("Enter the year!");
      } else if (docs?.registration_certificate?.docImg?.length === 0) {
        toast.warn("Upload registration certificate!");
      } else if (docs?.registration_certificate?.docImg?.length > 1) {
        toast.warn("You can upload only one file! ");
      } else {
        // dispatch(addDriverDocuments(docs));
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    } else if (activeStep === 6) {
      if (!docs?.chauffeur?.documentNumber) {
        toast.warn("Enter chauffeur number!");
      } else if (!docs?.chauffeur?.expirationDate) {
        toast.warn("Enter the expiry date!");
      } else if (docs?.chauffeur?.docImg?.length === 0) {
        toast.warn("Upload chauffeur card images!");
      } else if (docs?.chauffeur?.docImg?.length !== 2) {
        toast.warn("Upload chauffeur card both side! ");
      } else {
        console.log("api is called ")
        dispatch(addDriverDocuments(docs));
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    }
  // };
  }


  
  const handleSubmit = () => {
    // dispatch(appendPhoneCode());
    dispatch(addNewDriver(driverData))
      .unwrap()
      .then(() => {
        toast.success("Driver registered successfully!");
        setTimeout(() => {
          navigate("/verified-drivers");
        }, 1000);
      })
    
      .catch((error) => {
        if (error.message === "Rejected") toast.error("Driver not registered");
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  // Custom StepIcon component to remove the circle
const CustomStepIcon = (props) => {
  const { active, completed, icon ,label} = props;

  return (
    <div
      style={{
        display: 'flex',
        // alignItems: 'center',
        justifyContent: 'start',
        // width: 22,
        // height: 22,
        padding : "15px 0px", 
        paddingRight : "30px",
        backgroundColor: 'transparent', // Remove the background color
        color: active ? '#000' : completed ? '#000' : '#ccc', // Set text color based on state
        fontWeight: active || completed ? 'bold' : 'normal',
        borderRadius: '0', // Remove the circle shape
        border: 'none', // Remove border
        fontSize : "14px",
        borderBottom : "2px solid", 
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {`${icon}. ${label}`}
    </div>
  );
};


  const handleReset = () => {
    setActiveStep(0);
  };

  React.useEffect(() => {
    if (activeStep === steps.length - 1) {
      dispatch(appendPhoneCode());
    }

    if (activeStep === 0) {
      dispatch(removePhoneCode());
    }
  }, [activeStep]);

  React.useEffect(() => {
    dispatch(changeLabel(""));
  }, []);

  console.log(docs , "docss")

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>

      <CardLayout className="p-sm-5">
        <Box>
         

<Stepper activeStep={activeStep} alternativeLabel>
  {steps.map((label, index) => {
    const stepProps = {};
    const labelProps = {};
    if (isStepSkipped(index)) {
      stepProps.completed = false;
    }
    return (
      <Step
        key={label}
        {...stepProps}
        sx={{
          "& .MuiStepLabel-root .Mui-completed": {
            color: "#000", // Color for completed step
          },
          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
            color: "#000",
             // Color for completed step label
            // textDecoration: "underline", // Underline completed label
          },
          "& .MuiStepLabel-root .Mui-active": {
            color: "#000",
            // background : "red",
           
           // Color for active step
          },
          "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
            color: "#000", // Color for active step label
            // textDecoration: "underline", // Underline active label
          },
          "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
            fill: "#fff", // Color for the step icon text
          },
          "& .MuiStepIcon-root": {
            border: 'none', // Remove default border
            borderRadius: '0', // Remove circle shape
            backgroundColor: 'transparent', // Remove background color
         
        
          },
          "& .MuiStepIcon-text": {
           
            // textDecoration: 'underline', // Underline step number
          },
          '& .MuiStepConnector-line': {
            display: 'none',
          },
        }}
      >
        <StepLabel
          {...labelProps}
          StepIconComponent={(props) =>  CustomStepIcon({...props, label})}
         // Use custom StepIcon
          sx={{
           '& .MuiStep-root': {
      padding: 0, // Remove padding from step
    },
    '& .MuiStepLabel-root': {
      margin: 0, // Remove margin from step label
      padding: 0, // Remove padding from step label
    },
    '& .MuiStepLabel-label': {
      margin: 0, // Remove margin from label
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
          }}
        >
          {/* {label} */}
        </StepLabel>
      </Step>
    );
  })}
</Stepper>




          <div className={activeStep > 6 ? "" : "px-[10px]"}>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography
                  sx={{ mt: 2, mb: 1 }}
                  component={"div"}
                  variant={"body2"}
                >
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "d-flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography
                  sx={{ mt: 5, mb: 1 }}
                  component={"div"}
                  variant={"body2"}
                >
                  {activeStep === 0 ? (
                    <DriverDetail toast={toast} />
                  ) : activeStep === 1 ? (
                    <BankDetail
                      bankDocs={docs}
                      setDocData={setDocs}
                      img={img}
                    />
                  ) : activeStep === 2 ? (
                    <VehicleDetail />
                  ) : activeStep === 3 ? (
                    <DrivingLicenceDetail
                      licenceDocs={docs}
                      setDocData={setDocs}
                      img={img}
                    />
                  ) : activeStep === 4 ? (
                    <VehicleInsurance
                      insuranceDocs={docs}
                      setDocData={setDocs}
                      img={img}
                    />
                  ) : activeStep === 5 ? (
                    <RegistrationCertificate
                      rcDocs={docs}
                      setDocData={setDocs}
                      img={img}
                    />
                  ) : activeStep === 6 ? (
                    <ChauffeurDetail
                      chauffeurDocs={docs}
                      setDocData={setDocs}
                      img={img}
                    />
                  ) : (
                    // activeStep + 1
                    <PreviewDetail docs={docs} />
                  )}
                </Typography>
                {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 , gap : "8px"}}> */}
                {/* <Box> */}
                <div className=" d-flex flex-wrap justify-content-end mt-3 gap-2">
               { activeStep > 0  && <div className="whiteButton"
                    onClick={activeStep === 0 ? " " : handleBack}
                   >
                    Back
                  </div>
                }
 

                  <div className="blackButton"
                    onClick={() =>
                      activeStep !== steps.length - 1
                        ? handleNext(activeStep)
                        : handleSubmit()
                    }
                  >
                    {activeStep === steps.length - 1 ? "Submit" : <span className="flex gap-2 items-center">Next <NextIcon /></span>}
                  </div>

                  </div>
                {/* </Box> */}
              </React.Fragment>
            )}
          </div>
        </Box>
      </CardLayout>
      <ToastContainer
        closeOnClick={true}
        pauseOnHover={false}
        autoClose={2000}
      />
    </PageLayout>
  );
}
