import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRideDetail, getRides, rideDetailsByProfileId, searchRideById } from "../services/rideManagementService";
import { downloadRide } from "../services/rideManagementService";
import { dashboardData } from "../services/rideManagementService";
import { create } from "@mui/material/styles/createTransitions";

export const getAllRides = createAsyncThunk(
  "manage_rides/getAllRides",
  async (filter, { rejectWithValue }) => {
    try {
      const res = await getRides(filter);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRideDetailById = createAsyncThunk(
  "manage_rides/getRideDetailById",
  async (id, { rejectWithValue }) => {
    try {
      const res = await getRideDetail(id);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



export const downloadRides = createAsyncThunk(
  "manage_rides/downloadRide",
  async(filter , {rejectWithValue }) =>{
    try{
      const res = await  downloadRide(filter);
     if(res.status === 200){
      console.log(res.data)
      return res.data;
     }else{
      throw new Error(`Unexpected status code: ${res.status}`)
     }
    }catch(error){
      return rejectWithValue(error);
    }
  }
)

export const searchRide = createAsyncThunk(
  "manage_rides/searchRide",
  async(filter , {rejectWithValue}) =>{
    try{
      const res = await searchRideById(filter);
      if(res.status == 200){
        return res.data;
      }else{
        throw res.error
      }
    }catch(error){
      return rejectWithValue(error)
    }
  }
)


export const dashboard = createAsyncThunk(
  "manage_rides/dashboard",
  async(_ , {rejectWithValue}) =>{
    try{
      const res = await dashboardData();
      if(res.status === 200){
        return res.data
      }else{
        throw res.error
      }
    }catch(error){
      return rejectWithValue(error)
    }
  }
)

export const ridesByProfileId = createAsyncThunk(
  "manage_rides/ridesByProfileId",
  async(id , {rejectWithValue}) =>{
    try{
      const res = await rideDetailsByProfileId(id)
      if(res.status === 200){
        return res.data
      }else{
        throw res.error
      }
    }catch(error){
      return rejectWithValue(error)
    }
  }
)




const rideManagement = createSlice({
  name: "manage_rides",
  initialState: {
    allRides: [],
    rideDetail: {},
    downloadRide : {},
    searchData : [],
    homeData : {},
    ridesByProfileId : {}
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRides.fulfilled, (state, action) => {
      state.allRides = action.payload;
    });

    builder.addCase(getRideDetailById.fulfilled, (state, action) => {
      state.rideDetail = action.payload;
    });

    builder.addCase(downloadRides.fulfilled , (state , action) =>{
      state.downloadRide = action.payload;
    })

    builder.addCase(searchRide.fulfilled , (state , action) =>{
      state.searchData = action.payload;
    }
    )
    builder.addCase(dashboard.fulfilled ,(state , action) =>{
      state.homeData = action.payload;
    })

    builder.addCase(ridesByProfileId.fulfilled , (state , action) =>{
      state.ridesByProfileId = action.payload;
    })
  },
});

export default rideManagement.reducer;
