export const downloadCsv = (csvContent, fileName) => {
    if (!csvContent) {
      console.error("No CSV content to download");
      return;
    }
  
    // Create a Blob with the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);
  
    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName; // Filename
  
    // Append link to the body and trigger download
    document.body.appendChild(link);
    link.click();
  
    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  