import React from "react";
import {
  Box,
  Form,
  Heading,
  Button,
  Anchor,
  Image,
  Text,
} from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/resetPassword.json";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { resetAdminPassword } from "../../slices/authSlice";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const [passwordObj, setPasswordObj] = React.useState({
    email: location.state,
  });

  const handleChange = (e) => {
    setPasswordObj((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleResetPassword = () => {
    console.log(passwordObj, "obj");
    if (!passwordObj.otp) {
      toast.warn("Please enter the otp!");
    } else if (!passwordObj.password) {
      toast.warn("Enter your new password!");
    } else if (!passwordObj.confirmPassword) {
      toast.warn("Enter the confirm password!");
    } else if (passwordObj.password !== passwordObj.confirmPassword) {
      toast.warn("Password doesn't match!");
    } else {
      dispatch(resetAdminPassword(passwordObj))
        .unwrap()
        .then((res) => {
          toast.success(res.result);
          setTimeout(() => {
            console.log(res, "res in reset");
            navigate("/login");
          }, 1000);
        })
        .catch((error) => toast.error("Something went wrong!"));
    }
  };

  return (
    <Box className="mc-auth">
      <Image
        className="mc-auth-pattern"
        src={data?.pattern.src}
        alt={data?.pattern.alt}
      />
      <Box className="mc-auth-group">
        <Logo
          src={data?.logo.src}
          alt={data?.logo.alt}
          href={data?.logo.path}
          className="mc-auth-logo"
        />

        <Form className="mc-auth-form">
          <Heading as="h4" className="mc-auth-title">
            {data?.title}
          </Heading>
          {data?.input.map((item, index) => (
            <IconField
              key={index}
              icon={item.icon}
              type={item.type}
              classes={item.fieldSize}
              placeholder={item.placeholder}
              passwordVisible={item.passwordVisible}
              name={item.name}
              onChange={handleChange}
            />
          ))}
          <Button
            className={`mc-auth-btn ${data?.button.fieldSize}`}
            type={data?.button.type}
            onClick={handleResetPassword}
          >
            {data?.button.text}
          </Button>
        </Form>
        <Box className="mc-auth-navigate">
          <Text as="span">{data?.navigate.title}</Text>
          <Anchor href={data?.navigate.path}>{data?.navigate.text}</Anchor>
        </Box>
      </Box>
      <ToastContainer />
    </Box>
  );
}
