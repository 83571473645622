import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays,
  } from "date-fns";
  
  // defined all the formate
  const defined = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  };
  
  //   staticranges----------
  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };
  
  export function createStaticRanges(ranges) {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
  }
  
  export const defaultStaticRanges = createStaticRanges([
    {
      label: "Today",
      range: () => ({
        startDate: defined.startOfToday,
        endDate: defined.endOfToday,
      }),
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: defined.startOfYesterday,
        endDate: defined.endOfYesterday,
      }),
    },
    {
      label: "This Week",
      range: () => ({
        startDate: defined.startOfWeek,
        endDate: defined.endOfWeek,
      }),
    },
    {
      label: "Last week",
      range: () => ({
        startDate: defined.startOfLastWeek,
        endDate: defined.endOfLastWeek,
      }),
    },
    {
      label: "This Month",
      range: () => ({
        startDate: defined.startOfMonth,
        endDate: defined.endOfLastMonth,
      }),
    },
    {
      label: "Last Month",
      range: () => ({
        startDate: defined.startOfLastMonth,
        endDate: defined.endOfLastMonth,
      }),
    },
  ]);
  
  //   Default INput Ranges
  
  export const defaultInputRanges = [
    {
      label: "Days Up to Today",
      range(value) {
        return {
          startDate: addDays(
            defined.startOfToday,
            (Math.max(Number(value), 1) - 1) * -1
          ),
          endDate: defined.endOfToday,
        };
      },
      getCurrentValue(range) {
        if (!isSameDay(range.endDate, defined.endOfToday)) return "-";
        if (!range.startDate) return "∞";
        return differenceInCalendarDays(defined.endOfToday, range.startDate) + 1;
      },
    },
    {
      label: "Days Starting Today",
      range(value) {
        const today = new Date();
        return {
          startDate: today,
          endDate: addDays(today, Math.max(Number(value), 1)),
        };
      },
      getCurrentValue(range) {
        if (!isSameDay(range.startDate, defined.startOfToday)) return "-";
        if (!range.endDate) return "∞";
        return differenceInCalendarDays(range.endDate, defined.startOfToday) + 1;
      },
    },
  ];
  