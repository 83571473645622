import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addCountry,
  deleteCountry,
  getCountry,
  addContact
} from "../services/countryManagementService";

export const getAllCountry = createAsyncThunk(
  "manage_country/getAllCountry",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getCountry();
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewCountry = createAsyncThunk(
  "manage_country/addNewCountry",
  async (countries, { dispatch, rejectWithValue }) => {
    try {
      const res = await addCountry(countries);
      if (res.status === 200) {
        await dispatch(getAllCountry());
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCountryByName = createAsyncThunk(
  "manage_country/deleteCountryByName",
  async (name, { dispatch, rejectWithValue }) => {
    try {
      const res = await deleteCountry(name);
      if (res.status === 200) {
        await dispatch(getAllCountry());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addContactForm = createAsyncThunk(
  "manage_country/addContactForm",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await addContact(data);
      if (res.status === 200) {
        // await dispatch(getAllCountry());
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
;


const countryManagement = createSlice({
  name: "manage_country",
  initialState: {
    countryList: [],
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCountry.fulfilled, (state, action) => {
      state.countryList = action.payload;
    });

    builder.addCase(addNewCountry.fulfilled);
    builder.addCase(addContactForm.fulfilled)
  },
});

export default countryManagement.reducer;
