import { Navigate } from "react-router-dom";

function ProtectedRoutes({ children }) {
  // const isSignedIn = document.cookie;

  const isSignedIn = localStorage.getItem("token");
  const userType = JSON.parse(localStorage.getItem("permissions"));

  if (!isSignedIn) {
    // If the user is not signed in, redirect to the login page
    return <Navigate to="/login" />;
  }

  if (userType?.length === 0) {
    return <Navigate to="/login" />;
  }

  // Define allowed routes for each user type
  const allowedRoutes = {
    super_admin: ["/"], // Allow all routes for superadmin

    ridemanagement: [
      "/dashboard",
      "/home",
      "/ride-management",
      "/ride-detail",
      "/riding",
      "/pending-rides",
      "/scheduled-rides",
      "/completed-rides",
      "/cancelled-rides"
    ],

    drivermanagement: [
      "/dashboard",
      "/home",
      "/driver-management",
      "/driver-profile",
      "/add-driver",
      "/mui",
      "/pending-drivers",
      "/verified-drivers",
      "/documents"
    ],

    ridermanagement: [
      "/dashboard",
      "/home",
      "/rider-management",
      "/rider-profile",
    ],

    faremanagement: [
      "/dashboard",
      "/home",
      "/fare-management",
      "/user-profile",
    ],

    rideratings: ["/dashboard", "/home", "/rating-list", "/user-profile"],

    teammanagement: [
      "/dashboard",
      "/home",
      "/team-list",
      "/add-admin",
      "/permissions",
      "/user-profile",
    ],

    announcements :[
      "/dashboard",
      "/home",
      "/announcement",
      "/add-announcement"
    ],

    vehiclesettings : [
       "/dashboard",
       "/home",
       "/fee-management",
       "/add-vehicle-price",
       "/add-color"
    ],

    payments :[
      "/dashboard",
      "/home",
      "/driver-payouts",
      "/transactions",
      "/requested-payout"
    ],
    managesettings: [
      "/dashboard",
      "/home",
      "/company",
      "/fee-management",
      "/api-credential",
      "/country-management",
      "/add-language",
      "/manage-reason",
      "/policy-management",
      "/add-policy",
      "/privacy-policy"
    ],
  };

  // Check if the current route is allowed for the user's permission
  const currentRoute = window.location.pathname;
  const isRouteAllowed = userType?.map((item) => {
    return (
      allowedRoutes[item] &&
      allowedRoutes[item].some((route) => currentRoute.startsWith(route))
    );
  });

  // console.log(isRouteAllowed, "IsRouteAllowed...");

  if (!isRouteAllowed?.includes(true)) {
    // If the route is not allowed for the user's permission, redirect to a default page or show an error message
    return <Navigate to="/*" />;
  }

  return children;
}

export default ProtectedRoutes;
