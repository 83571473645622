import { createSlice, createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { logout } from "../pages/master/Login";
import {
  getDrivers,
  toggleChangeDrivers,
  getDriverProfile,
  // verifyDriverProfile,
  // rejectDriverProfile,
  uploadImage,
  addDriver,
  getStates,
  getCounty,
  getLanguage,
  getVehicleType,
  getVehicleTypeLists,
  getStateById,
  getCountyById,
  getVehicleTypeById,
  getLanguageById,
  verifyDocument,
  rejectDocument,
  verifyProfile,
  getCompany,
  getCompanyById,
  downloadDrivers,
  downloadVehicleType,
  searchProfile,driverCount,
  searchVehicleType
} from "../services/driverManagementService";
import useHistory from 'react-router-dom'


export const fetchDriver = createAsyncThunk(
  "manage_drivers/fetchDriver",
  async (params, { rejectWithValue }) => {
    try {
      const res = await getDrivers(params);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const toggleChange = createAsyncThunk(
  "manage_drivers/toggleChange",
  async ({ id, params }, { dispatch, rejectWithValue }) => {
    try {
      const res = await toggleChangeDrivers(id);
      if (res.status === 200) {
        await dispatch(fetchDriver(params));
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDriverProfileById = createAsyncThunk(
  "manage_drivers/getDriverProfileById",
  async (id, { rejectWithValue }) => {
    try {
      const res = await getDriverProfile(id);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// export const verifyDriverProfileById = createAsyncThunk(
//   "manage_drivers/verifyDriverProfileById",
//   async (id, { dispatch, rejectWithValue }) => {
//     try {
//       const res = await verifyDriverProfile(id);
//       if (res.status === 200) {
//         await dispatch(getDriverProfileById(id));
//         return res.data;
//       } else throw res.error;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

// export const rejectDriverProfileWithReason = createAsyncThunk(
//   "manage_drivers/rejectDriverProfileWithReason",
//   async ({ reason, id }, { dispatch, rejectWithValue }) => {
//     try {
//       const res = await rejectDriverProfile(reason);
//       if (res === 200) {
//         await dispatch(getDriverProfileById(id));
//         return res.data;
//       } else throw res.error;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const uploadImages = createAsyncThunk(
  "manage_drivers/uploadImages",
  async (formData, { rejectWithValue }) => {
    try {
      const res = await uploadImage(formData);
      if (res.status === 200) {
        return res.data;
      } else {
        // throw res.response.data.msg;
        throw res.error;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewDriver = createAsyncThunk(
  "manage_drivers/addNewDriver",
  async (formData, { rejectWithValue }) => {
    try {
      const res = await addDriver(formData);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
    
      return rejectWithValue(error);
    }
  }
);

export const getAllState = createAsyncThunk(
  "manage_drivers/getAllState",
  async (params, { rejectWithValue }) => {
    try {
      const res = await getStates(params);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getStateFromId = createAsyncThunk(
  "manage_settings/getStateFromId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await getStateById(id);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCountyByState = createAsyncThunk(
  "manage_drivers/getCountyByState",
  async (id, { rejectWithValue }) => {
    try {
      const res = await getCounty(id);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCountyFromId = createAsyncThunk(
  "manage_settings/getCountyFromId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await getCountyById(id);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllLanguage = createAsyncThunk(
  "manage_drivers/getAllLanguage",
  async (params, { rejectWithValue }) => {
    try {
      const res = await getLanguage(params);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLanguageFromId = createAsyncThunk(
  "manage_drivers/getLanguageFromId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await getLanguageById(id);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllVehicleType = createAsyncThunk(
  "manage_drivers/getAllVehicleType",
  async (params, { rejectWithValue }) => {
    try {
      const res = await getVehicleType(params);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVehicleTypeList = createAsyncThunk(
  "manage_drivers/getVehicleTypeList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getVehicleTypeLists();
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const getVehicleTypeFromId = createAsyncThunk(
  "manage_drivers/getVehicleTypeFromId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await getVehicleTypeById(id);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyDocumentByProfile = createAsyncThunk(
  "manage_drivers/verifyDocument",
  async ({profileId , docType}, {dispatch , rejectWithValue }) => {
    try {
      const res = await verifyDocument(profileId , docType);
      if (res.status === 200) {
        await dispatch(getDriverProfileById(profileId));
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const rejectDocumentByProfile = createAsyncThunk(
  "manage_drivers/rejectDocument",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await rejectDocument(data);
      if (res.status === 200) {
        await dispatch(getDriverProfileById(data.id));
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleVerifyProfile = createAsyncThunk(
  "manage_drivers/verifyProfile" , 
  async(id , {dispatch , rejectWithValue}) =>{
    try{
      const res = await verifyProfile(id)
      if(res.status === 200){
      return res.data
    }
   else throw res.error;
   }catch (error) {
  return rejectWithValue(error);
   }
  }
)

export const getAllCompany = createAsyncThunk(
  "manage_drivers/getAllCompany",
  async (params, { rejectWithValue }) => {
    try {
      const res = await getCompany(params);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCompanyFromId = createAsyncThunk(
  "manage_drivers/getCompanyFromId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await getCompanyById(id);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadProfiles  = createAsyncThunk(
  "manage_drivers/downloadProfiles",
  async(params , {rejectWithValue }) =>{
    try{
      const res = await downloadDrivers(params);
      if(res.status === 200){
        return res.data;
      }else throw res.error;
    }catch(error) {
     return rejectWithValue(error);
    }
  }
)

export const downloadVehicleTypeWithPrice = createAsyncThunk(
  "manage_drivers/downloadVehicleType",
  async(params , {rejectWithValue}) =>{
    try{
      const res = await downloadVehicleType(params);
      if(res.status === 200){
        return res.data;
      }else throw res.error;
    }catch(error){
      return rejectWithValue(error);
    }
  }
)

export const searchProfiles = createAsyncThunk(
  "manage_drivers/searchProfiles",
  async(params , {rejectWithValue}) =>{
    try{
      const res = await searchProfile(params);
      if(res.status === 200){
        return res.data;
      }else throw res.error;
    }catch(error){
      return rejectWithValue(error);
    }
  })


  export const driverCard = createAsyncThunk(
    "manage_drivers/driverCard",
    async(_ , {rejectWithValue}) =>{
      let res
      try{
         res = await driverCount();
        if(res.status === 200){
          return res.data;
        }else throw res.error
      }catch(error){
        return rejectWithValue(error)
      }
    }
  )

  export const searchVehicleTypes = createAsyncThunk(
    "manage_drivers/searchVehicleTypes",
    async(params , {rejectWithValue}) =>{
      try{
        const res = await searchVehicleType(params);
        if(res.status === 200){
          return res.data;
        }else throw res.error;
      }catch(error){
        return rejectWithValue(error);
      }
    })



const driverManagement = createSlice({
  name: "manage_drivers",
  initialState: {
    // loading: 'idle',
    key: false,
    // drivers: {},
    drivers: [],
    driverProfile: {},
    states: [],
    county: [],
    language: [],
    vehicleTypeData: [],
    vehicleType :   [],
    phoneCode: "",
    stateName: "",
    countyName: "",
    vehicleName: "",
    companyId : [],
    searchData : [],
    vehicleSearch : [],
    CardsCount: {},
    driverData: {
      name: "",
      email: "",
      phone: "",
      secondaryPhone: "",
      role: "driver",
      gender: "",
      profileUrl: "",
      language: [],
      driverState: "",
      driverCounty: "",
      address : "",
      companyId : "",
      // documentList: [],
      // documentList: {
      //   bank: {},
      //   dl: {},
      //   chauffeur: {},
      //   insurance: {},
      //   registration_certificate: {},
      // },
      documentList: {},
      vehicleData: {},
    },
    multiselect: {
      gender: [],
      language: [],
      state: [],
      county: [],
      vehicleType: [],
      vehicleTypeData :[],
      vehicleColor: [],
      companyId: []
    },
  },
  reducers: {
    addKey(state, action) {
      state.key = action.payload;
    },

    emptyCounty(state, action) {
      state.county = action.payload;
    },

    addDriverDetail(state, action) {
      if (action.payload.name === "language") {
        state.driverData[action.payload.name] = [...action.payload.value];
      } else {
        state.driverData[action.payload.name] = action.payload.value;
      }
    },

    // addDriverDetail(state, action) {
    //   if (action.payload.name === "company") {
    //     state.driverData[action.payload.name] = [...action.payload.value];
    //   } else {
    //     state.driverData[action.payload.name] = action.payload.value;
    //   }
    // },

    addDriverDocuments(state, action) {
      // state.driverData.documentList = [
      //   ...state.driverData.documentList,
      //   action.payload,
      // ];

      // state.driverData.documentList[action.payload.type] = action.payload.value;
      state.driverData.documentList = action.payload;
    },

    addVehicleDetail(state, action) {
      state.driverData.vehicleData[action.payload.name] = action.payload.value;
    },

    manageMultiselect(state, action) {
      state.multiselect[action.payload.key] = action.payload.value;
    },

    managePhoneCode(state, action) {
      state.previousPhoneCode = state.phoneCode;
      state.phoneCode = action.payload;
    },

    appendPhoneCode(state) {
      if (!state.driverData.phone.includes(state.phoneCode)) {
        state.driverData.phone = state.phoneCode + state.driverData.phone;
      }

      if (!state.driverData.secondaryPhone.includes(state.phoneCode)) {
        state.driverData.secondaryPhone =
          state.phoneCode + state.driverData.secondaryPhone;
      }
    },

    removePhoneCode(state) {
      if (state.driverData.phone.includes(state.phoneCode)) {
        state.driverData.phone = state.driverData.phone.replace(
          state.phoneCode,
          ""
        );
      }

      if (state.driverData.secondaryPhone.includes(state.phoneCode)) {
        state.driverData.secondaryPhone =
          state.driverData.secondaryPhone.replace(state.phoneCode, "");
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDriver.fulfilled, (state, action) => {
      state.drivers = action.payload;
    });

    builder.addCase(toggleChange.fulfilled);

    builder.addCase(getDriverProfileById.fulfilled, (state, action) => {
      state.driverProfile = action.payload.result;
    });

    builder.addCase(verifyDocumentByProfile.fulfilled);

    builder.addCase(rejectDocumentByProfile.fulfilled);

    builder.addCase(uploadImages.fulfilled);
    // builder.addCase(uploadImages.pending, (state, action) => {
    //   state.loading = "pending"
    // });

    builder.addCase(addNewDriver.fulfilled);

    builder.addCase(getAllState.fulfilled, (state, action) => {
      state.states = action.payload;
    });

    builder.addCase(getStateFromId.fulfilled, (state, action) => {
      state.stateName = action.payload.label;
    });

    builder.addCase(getCountyByState.fulfilled, (state, action) => {
      state.county = action.payload;
    });

    builder.addCase(getCountyFromId.fulfilled, (state, action) => {
      state.countyName = action.payload.label;
    });

    builder.addCase(getAllLanguage.fulfilled, (state, action) => {
      state.language = action.payload;
    });

    builder.addCase(getAllVehicleType.fulfilled, (state, action) => {
      state.vehicleTypeData = action.payload;
    });

    builder.addCase(getVehicleTypeList.fulfilled , (state , action) =>{
      state.vehicleType = action.payload;
    })

    builder.addCase(getVehicleTypeFromId.fulfilled, (state, action) => {
      state.vehicleName = action.payload.label;
    });

    builder.addCase(getAllCompany.fulfilled , (state , action) => {
      state.company = action.payload;
    })

    builder.addCase(getCompanyFromId.fulfilled, (state, action) => {
      state.companyName = action.payload;
    });

    builder.addCase(downloadProfiles.fulfilled)
    builder.addCase(downloadVehicleTypeWithPrice.fulfilled)

    builder.addCase(searchProfiles.fulfilled ,(state , action) =>{
      state.searchData = action.payload;
    })

    builder.addCase(driverCard.fulfilled ,(state , action) =>{
      state.CardsCount = action.payload;
    })

    builder.addCase(searchVehicleTypes.fulfilled ,(state , action) =>{
      state.vehicleSearch = action.payload;
    })
  
  },
});

export default driverManagement.reducer;
export const {
  addKey,
  emptyCounty,
  addDriverDetail,
  addDriverDocuments,
  addVehicleDetail,
  manageMultiselect,
  managePhoneCode,
  appendPhoneCode,
  removePhoneCode,
} = driverManagement.actions;
