import React from "react";
import { CardHeader } from ".";
import { OrdersChart } from "../charts";
import { Box, List, Item, Heading, Text, Icon } from "../elements";

export default function OrdersCard({ title, dotsMenu, items, total }) {
  return (
    // <Box className="mc-card-pie"></Box>
    <Box className="mc-card">
      <CardHeader title={title} dotsMenu={dotsMenu} />
      <Box style={{ marginBottom: "10px" }}>
        <OrdersChart chart={items} total={total} />
      </Box>
      <List className="mc-order-card-list">
        {items.map((item, index) => (
          <Item key={index} className="mc-order-card-item">
            <Icon className={`material-icons ${item.color}`}>{item.icon}</Icon>
            <Text as="p">{item.name}</Text>
            <Heading as="h5">${item.value}</Heading>
          </Item>
        ))}
      </List>
    </Box>
  );
}
