import React from "react";
import Box from "../elements/Box";
import CardHeader from "./CardHeader";
import Pagination from "../Pagination";
import { LabelField } from "../fields";
import { Row, Col } from "react-bootstrap";
import RidesTable from "../tables/RidesTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllRides } from "../../slices/rideManagementSlice";
import { useNavigate } from "react-router-dom";
import { Table } from "../elements/Table";
import { Tr, Th, Thead ,Tbody ,Td } from "../elements/Table";
import { Text } from "../elements";
import { ViewIcon } from "../icons/icons";

export default function RidesCard({ title, table  , ride}) {
  const dispatch = useDispatch();
 
  console.log(ride , "kl")
  


  return (
    <Box className="mc-card">
      <CardHeader title={title}  />
      {/* <Row xs={1} sm={2} xl={4} className="mb-4">
                {table.filter.map((item, index)=> (
                    <Col key={ index }>
                        <LabelField
                            type = { item.type }
                            label = { item.label }
                            option = { item.option }
                            placeholder = { item.placeholder }
                            labelDir = "label-col"
                            fieldSize = "w-100 h-md"
                        />
                    </Col>
                ))}
            </Row> */}
      <RideTable
      status = "completed"
        // thead={table.thead}
        // startIndex = {getStartIndex}
        //   tbody={table.ttbody}
        tbody={ride}
      />
       {/* <Pagination
              totalItems={allRides?.totalDocs}
              itemsPerPage={allRides?.pagingCounter}
              currentPage={allRides?.page}
              limit={allRides?.limit}
              onPageChange={handlePageChange}
            />  */}
      {/* <Pagination /> */}
    </Box>
  );
}




 function RideTable({status , tbody }) {
  const [data, setData] = React.useState([]);
  const [userData, setUserData] = React.useState("");
  const [editModal, setEditModal] = React.useState(false);
  const [blockModal, setBlockModal] = React.useState(false);
  const navigate = useNavigate();

  const theadPending = [
    // "driver name",
    "rider name",
    "fare",
    "pickup location",
    "drop location",
    "pickup date",
    "status",
    "action",
  ];

  const thead = [
    "driver name",
    "rider name",
    "fare",
    "pickup location",
    "drop location",
    "pickup date",
    "status",
    "action",
  ];

  React.useEffect(() => {
    setData(tbody);
  }, [tbody]);

  const handleCheckbox = (event) => {
    const { name, checked } = event.target;

    if (name === "allCheck") {
      const checkData = data?.map((item) => {
        return { ...item, isChecked: checked };
      });
      setData(checkData);
    } else {
      const checkData = data?.map((item) =>
        item.name === name ? { ...item, isChecked: checked } : item
      );
      setData(checkData);
    }
  };

  const handleClick = (item) => {
    // navigate("/ride-detail", { state: item });
    navigate("/riding", { state: item });
  };

  console.log(tbody , "l;l")

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                <Text>uid</Text>
              </Box>
            </Th>
            {status === "pending" ? theadPending.map((item ,index) =>(<Th key = {index}>{item}</Th>))
            :
            thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))
          }
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {data?.length > 0 ? data?.map((item, index) =>(
              <Tr key={index} onClick={() => handleClick(item?._id)}>
                <Td title="id">
                  <Box className="mc-table-check">
                    <Text>#{ index + 1}</Text>
                  </Box>
                </Td>
                {item.status == "pending" ? null :
                <Td title={item?.driver?.name}>
                  <Box className="mc-table-profile">
                   <Text>{item?.driver?.name}</Text>
                  </Box>
                </Td>
               }
                <Td title={item?.passenger?.name}>
                  <Box className="mc-table-icon role">
                    <Text as="span">{item?.passenger?.name}</Text>
                  </Box>
                </Td>
                <Td title={item?.totalPrice}>$ {item?.totalPrice.toFixed(2)}</Td>
              <Td title={item?.locations?.pickupLocation?.name}>
                  <span className='text-truncate'>{item?.locations?.pickupLocation?.name}</span>
                </Td>
                <Td title={item?.locations?.dropoffLocation?.name}>
                <span className='text-truncate'>{item?.locations?.dropoffLocation?.name}</span>
                </Td>
                <Td title={item?.pickupDate}>
                 {new Date(item.pickupDate).toLocaleDateString()}
                </Td>
                <Td title={item?.status}>
                  {item?.status === "completed" && (
                    <span className="completedTag">{item?.status}
                      </span>
                  )}
                  {item?.status === "scheduled" && (
                    // <Text className="mc-table-badge purple">{item?.status}</Text>
                    <span className="scheduledTag">{item?.status}
                      </span>
                  )}
                  {item?.status === "cancelled" && (
                    // <Text className="mc-table-badge red">{item?.status}</Text>
                    <span className="cancelledTag">{item?.status}
                      </span>
                  )}
                   {item?.status === "pending" && (
                    // <Text className="mc-table-badge red">{item?.status}</Text>
                    <span className="pendingTag">{item?.status}
                      </span>
                  )}
                </Td>
                <Td>
                  <Box className="mc-table-ation pe-auto align-items-center" >
                  <div className="cursor-pointer" onClick={() => handleClick(item?._id)} >
                    <ViewIcon />
                    {/* <Button
                      title="View"
                      className="material-icons view"
                      onClick={() => handleClick(item?._id)}
                    >
                      visibility
                    </Button> */}
                  </div>
                  </Box>
                </Td>
              </Tr>
            )
          )
          :
          <tr  className = "">
          <td colspan = {thead.length + 1} valign = "top" className = "text-center">
            No Data available in table
            </td>
           </tr>
          }
        </Tbody>
      </Table>
    </Box>
  );
}
