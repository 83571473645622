import React from "react";
import { Row, Col } from "react-bootstrap";
import { Text, Box, Image, Heading } from "../../../components/elements";
import { useSelector } from "react-redux";
import { CardLayout } from "../../../components/cards";
import { DivideTitle } from "../../../components";
import { ImageIcon } from "../../../components/icons/icons";
import { useNavigate } from "react-router-dom";

export default function PreviewDetail({ docs }) {
  const {
    driverData,
    phoneCode,
    stateName,
    countyName,
    vehicleName,
    multiselect,
  } = useSelector((state) => state.driverManagement);
  

  return (
    <Box>
    <Row>
    <Col xl = {6}>
      <CardLayout className="">
        <Box>
          <DivideTitle as="h4" title="driver details" className="mb-4" />

             <div className="previewText">
              Driver Name:
              <span>{driverData?.name}</span>
              </div>


             <div className="previewText">
             Phone:
              <span>{driverData?.phone}</span>
              </div>

              {driverData?.secondaryPhone && (
               <div className="previewText">
                  Secondary Phone:
              <span> {driverData?.secondaryPhone}</span>
              </div>
               )}

              <div className="previewText">
              Email:
              <span>{driverData?.email}</span>
              </div>
             
              <div className="previewText">
              Gender:
              <span>{driverData?.gender}</span>
              </div>

              <div className="previewText">
              Languages:
             {multiselect?.language?.map((lang, i) => (
                  <span className = "d-flex justify-content-end">  {lang.label} </span>
                ))}
              </div>

              <div className="previewText">
              State:
              <span>{driverData?.driverState}</span>
              </div>

              <div className="previewText">
              County:
              <span>{driverData?.driverCounty}</span>
              </div>


               <div className="previewText"> 
                  Profile Picture:
                  <span 
                    className="d-flex cursor-pointer" 
                    onClick={() => {
                      const newWindow = window.open();
                      if (newWindow) {
                        
                        newWindow.document.write(`<img src="${driverData?.profileUrl}" alt="profile_picture" style="max-width: 100%; height: auto;" />`);
                        newWindow.document.title = "Profile Picture";
                      }
                    }}
                  >
                    <ImageIcon /> 
                  </span>
                </div> 
             
     </Box>
      </CardLayout>
      </Col>

      <Col xl = {6}>
      <CardLayout className="">
        <Box>
          <DivideTitle title="bank details" className="mb-4" />

          <div className="previewText">
          First Name:
              <span>{docs?.bank?.firstName}</span>
              </div>


          <div className="previewText">
          Last Name:
              <span>{docs?.bank?.lastName}</span>
              </div>


          <div className="previewText">
          Account Number:
              <span>{docs?.bank?.documentNumber}</span>
              </div>

              <div className="previewText">
              Routing Number:
              <span>{docs?.bank?.routingNumber}</span>
              </div>

            <div className="previewText">
              Cancelled Cheque:
              <span className="d-flex cursor-pointer gap-2">
                {docs?.bank?.docImg?.map((imgSrc, index) => (
                  <span 
                    key={index}
                    className="d-flex cursor-pointer mr-2"
                    onClick={() => {
                      const newWindow = window.open();
                      if (newWindow) {
                        newWindow.document.write(`<img src="${imgSrc}" alt="Cancelled Cheque" style="max-width: 100%; height: auto;" />`);
                        newWindow.document.title = "Cancelled Cheque";
                      }
                    }}
                  >
                    <ImageIcon />
                  </span>
                ))}
              </span>
          </div>
       </Box>
      </CardLayout>
      </Col>
     
      
      <Col xl = {6}>
      <CardLayout className="">
        <Box>
          <DivideTitle title="licence details" className="mb-4" />

          <div className="previewText">
             Licence Number:
              <span>{docs?.dl?.documentNumber}</span>
              </div>

              <div className="previewText">
              Expiry Date:
              <span>{docs?.dl?.expirationDate}</span>
              </div>


              <div className="previewText">
              Driving Licence:
              <span className="d-flex cursor-pointer gap-2">
                {docs?.dl?.docImg?.map((imgSrc, index) => (
                  <span 
                    key={index}
                    className="d-flex cursor-pointer mr-2"
                    onClick={() => {
                      const newWindow = window.open();
                      if (newWindow) {
                        newWindow.document.write(`<img src="${imgSrc}" alt="Driving License" style="max-width: 100%; height: auto;" />`);
                        newWindow.document.title = "Driving License";
                      }
                    }}
                  >
                    <ImageIcon />
                  </span>
                ))}
              </span>
          </div>
              {/* <div className="text-[16px] font-400 flex pb-2 justify-between">
              Driving Licence:

              {docs?.dl?.docImg?.map((url, i) => (
                  <ImageIcon />
                //   <Image
                //     src={url}
                //     alt="licence_docs"
                //     key={i}
                //     height="100px"
                //     width="100px"
                //   />
                ))}
              </div> */}
        </Box>
      </CardLayout>
      </Col>

      <Col xl = {6}>
      <CardLayout className="">
        <Box>
          <DivideTitle title="chauffeur detail" className="mb-4" />

          <div className="previewText">
          Chauffeur Number:
              <span>{docs?.chauffeur?.documentNumber}</span>
              </div>

              <div className="previewText">
              Expiry Date:
              <span>{docs?.chauffeur?.expirationDate}</span>
              </div>

              <div className="previewText">
              Chauffeur Card:
              <span className="d-flex cursor-pointer gap-2">
                {docs?.chauffeur?.docImg?.map((imgSrc, index) => (
                  <span 
                    key={index}
                    className="d-flex cursor-pointer mr-2"
                    onClick={() => {
                      const newWindow = window.open();
                      if (newWindow) {
                        newWindow.document.write(`<img src="${imgSrc}" alt="Chauffeur Card" style="max-width: 100%; height: auto;" />`);
                        newWindow.document.title = "Chauffeur Card";
                      }
                    }}
                  >
                    <ImageIcon />
                  </span>
                ))}
              </span>
          </div>

              {/* <div className="text-[16px] font-400 flex pb-2 justify-between">
              Chauffeur Card:
              {docs?.chauffeur?.docImg?.map((url, i) => { return(
                <ImageIcon />
                  // <Image
                  //   src={url}
                  //   alt="chauffeur_docs"
                  //   key={i}
                  //   height="100px"
                  //   width="100px"
                  // />
                )})}
              </div> */}
          
        </Box>
      </CardLayout>
      </Col>
      
      <Col xl = {6}>
      <CardLayout className="">
        <Box>
          <DivideTitle title="vehicle insurance" className="mb-4" />
         

          <div className="previewText">
             Policy Number:
              <span>{docs?.insurance?.documentNumber}</span>
              </div>


          <div className="previewText">
              Company Name:
              <span>{docs?.insurance?.documentName}</span>
              </div>


          <div className="previewText">
              Expiry Date:
              <span>{docs?.insurance?.expirationDate}</span>
              </div>

              <div className="previewText">
              Vehicle Insurance:
              <span className="d-flex cursor-pointer">
                {docs?.insurance?.docImg?.map((imgSrc, index) => (
                  <span 
                    key={index}
                    className="d-flex cursor-pointer mr-2"
                    onClick={() => {
                      const newWindow = window.open();
                      if (newWindow) {
                        newWindow.document.write(`<img src="${imgSrc}" alt="vehicle insurance" style="max-width: 100%; height: auto;" />`);
                        newWindow.document.title = "Vehicle Insurance";
                      }
                    }}
                  >
                    <ImageIcon />
                  </span>
                ))}
              </span>
          </div>
        </Box>
      </CardLayout>
      </Col>

      <Col xl = {6}>
      <CardLayout className="">
        <Box>
          <DivideTitle title="registration certificate" className="mb-4" />
         
          <div className="previewText">
             Registration Number:
              <span>{docs?.registration_certificate?.documentNumber}</span>
              </div>

              <div className="previewText">
              Expiry Date:
              <span>{docs?.registration_certificate?.expirationDate}</span>
              </div>


              <div className="previewText">
              Make:
              <span>{docs?.registration_certificate?.make}</span>
              </div>

              <div className="previewText">
              Model:
              <span>{docs?.registration_certificate?.model}</span>
              </div>

              <div className="previewText">
              Year:
              <span>{docs?.registration_certificate?.year}</span>
              </div>

              <div className="previewText">
              Registration Certificate:
              <span className="d-flex cursor-pointer gap-2">
                {docs?.registration_certificate?.docImg?.map((imgSrc, index) => (
                  <span 
                    key={index}
                    className="d-flex cursor-pointer mr-2"
                    onClick={() => {
                      const newWindow = window.open();
                      if (newWindow) {
                        newWindow.document.write(`<img src="${imgSrc}" alt="Registration Certificate" style="max-width: 100%; height: auto;" />`);
                        newWindow.document.title = "Registration Certificate";
                      }
                    }}
                  >
                    <ImageIcon />
                  </span>
                ))}
              </span>
          </div>
        
        </Box>
      </CardLayout>
      </Col>

      <Col xl = {6}>
      <CardLayout className="">
        <Box>
          <DivideTitle title="vehicle detail" className="mb-4" />
         
            
          <div className="previewText">
             Vehicle Name:
              <span>{driverData?.vehicleData?.vehicleName}</span>
            </div>

            <div className="previewText">
            Vehicle Number:
              <span>{driverData?.vehicleData?.vehicleNumber}</span>
            </div>

            <div className="previewText">
            Vehicle Type:
              <span> {vehicleName}</span>
            </div>

            <div className="previewText">
            Vehicle Color:
            {multiselect?.vehicleColor?.map((color, i) => (
                  <span key={i}>{color.label}</span>
                ))}
              {/* <span> {vehicleName}</span> */}
            </div>


            <div className="previewText">
            Vehicle Images:
              <span className="d-flex cursor-pointer gap-2">
              {driverData?.vehicleData?.vehicleGallery?.map((imgSrc, index) => (
                  <span 
                    key={index}
                    className="d-flex cursor-pointer "
                    onClick={() => {
                      const newWindow = window.open();
                      if (newWindow) {
                        newWindow.document.write(`<img src="${imgSrc}" alt="Vehicle Images" style="max-width: 100%; height: auto;" />`);
                        newWindow.document.title = "Vehicle Images";
                      }
                    }}
                  >
                    <ImageIcon />
                  </span>
                ))}
              </span>
          </div>

           {/* <Col xl={6}>
              <Heading className="text-[17px]">Vehicle Color</Heading>
              <Text>
                {multiselect?.vehicleColor?.map((color, i) => (
                  <Text key={i}>{color.label}</Text>
                ))}
              </Text>
            </Col> */}

            {/* <Col xl={6}>
              <Heading className="text-[17px]">No. of Seats</Heading>
              <Text>{driverData?.vehicleData?.numberOfSeats}</Text>
            </Col> */}
{/* 
            <Col xl={6}>
              <Heading className="text-[17px]">Vehicle Images</Heading>
              <Box className="flex gap-2 my-2">
                {driverData?.vehicleData?.vehicleGallery?.map((url, i) => (
                  <Image
                    src={url}
                    alt="vehicle_image"
                    key={i}
                    height="100px"
                    width="100px"
                  />
                ))}
              </Box>
            </Col> */}
         
        </Box>
      </CardLayout>
      </Col>
      </Row>
    </Box>
  );
}
