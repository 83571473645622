import React, { useEffect, useState } from "react";
import DateRangeSelector from "./DateRange";
import { CalendarIcon } from "../icons/icons";
import { useRef } from "react";

export default function DatePicker({onDateRangeSubmit}) {
    const [showDateRangeSelector, setShowDateRangeSelector] = useState(false);
    const currentDate = new Date().toLocaleDateString()
    const [selectedRange, setSelectedRange] = useState(`${currentDate} -${currentDate}`);
    const containerRef = useRef(null);

    const handleDateRangeSubmit = (range) => {
        const { startDate, endDate } = range;
        const formattedStartDate = startDate.toLocaleDateString();
        const formattedEndDate = endDate.toLocaleDateString();
        setSelectedRange(`${formattedStartDate} - ${formattedEndDate}`);
        setShowDateRangeSelector(false);

        if (onDateRangeSubmit) {
            onDateRangeSubmit(range);
        }
    };

    useEffect(() =>{
        const handleClickOutside = (event) => {
            if(containerRef.current && !containerRef.current.contains(event.target)){
                setShowDateRangeSelector(false);
            }
        }
        document.addEventListener("mousedown" , handleClickOutside);
        return () => document.removeEventListener("mousedown" , handleClickOutside)
    },[])

    const dateRangeSelectorStyles = {
        position: "absolute",
        top: "100%",
        left: "0",
        zIndex: "1",
        backgroundColor: "#f0f0f0",
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "10px"
    };

    const triggerDivStyles = {
        cursor: "pointer",
        padding: "6px",
        border: "1px solid #E5E5E5",
        borderRadius : "10px",
        // display: "inline-block"
        display: "flex",
        alignItems: "center",
        gap: "5px"
    };

    return (
        <div style={{ position: "relative" }} ref = {containerRef}>
            <div className="flex "
                onClick={() => setShowDateRangeSelector(!showDateRangeSelector)} 
                style={triggerDivStyles}
            >
               <span className="mb-1" ><CalendarIcon /> </span>
               <span>{selectedRange}</span>
            </div>
            {showDateRangeSelector && (
                <div style={dateRangeSelectorStyles}>
                    <DateRangeSelector
                        onSubmit={handleDateRangeSubmit}
                        applyFilter={() => console.log("appl")}
                    />
                </div>
            )}
        </div>
    );
}




export const getDateInYYYYMMDD = (date) => {
    // const today = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits for day

    return `${year}-${month}-${day}`;
};

