import { createSlice } from "@reduxjs/toolkit";

const sidebar = createSlice({
  name: "sidebar",
  initialState: {
    label: "",
  },
  reducers: {
    changeLabel(state, action) {
      state.label = action.payload;
    },
  },
});

export default sidebar.reducer;
export const { changeLabel } = sidebar.actions;
