import { Endpoints, HttpMethods } from "../constants";
import ApiCall from "../utils/ApiCall";

export const login = (data) =>
  ApiCall(Endpoints.LOG_IN, HttpMethods.POST, null, data);

export const changePassword = (data) =>
  ApiCall(Endpoints.CHANGE_PASSWORD, HttpMethods.POST, null, data);

export const updateProfile = (data) =>
  ApiCall(Endpoints.PROFILE_UPDATE, HttpMethods.PUT, null, data);

export const viewProfile = () =>
  ApiCall(Endpoints.VIEW_PROFILE, HttpMethods.GET);

export const sendOtpOnEmail = (email) =>
  ApiCall(
    Endpoints.FORGOT_PASSWORD.replace(":email", email),
    HttpMethods.GET
  );

export const resetPassword = (data) =>
  ApiCall(Endpoints.RESET_PASSWORD, HttpMethods.POST, null, data);
