import React from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Text, Item, Anchor, Button } from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { Breadcrumb, DivideTitle } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/addAnnouncement.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addNewAnnouncement } from "../../slices/announcementSlice";
import { changeLabel } from "../../slices/sidebarSlice";
import Multipleselect from "../../components/Multipleselect";

export default function AddAnnouncement() {
  const navigate = useNavigate();

  const [announcement, setAnnouncement] = React.useState({});

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setAnnouncement((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRole = (_, selectedItem) => {
    setAnnouncement((prev) => {
      if (selectedItem.role === "Driver") {
        return {
          ...prev,
          role: selectedItem.role.toLowerCase(),
          isForDriver: true,
        };
      } else {
        return {
          ...prev,
          role: selectedItem.role.toLowerCase(),
        };
      }
    });
  };

  const handleRemove = (_, removedItem) => {
    if (removedItem.role === "Driver") {
      delete announcement.isForDriver;
      delete announcement.role;
    } else {
      delete announcement.role;
    }
  };

  const handleClick = () => {
    console.log(announcement, "announce.......");

    if (!announcement.title) {
      toast.warn("Title is required!");
    } else if (!announcement.message) {
      toast.warn("Message is required!");
    } else if (!announcement.role) {
      toast.warn("Select a role to send notification!");
    } else {
      dispatch(addNewAnnouncement(announcement))
        .unwrap()
        .then((res) => {
          toast.success(res.result);
          setTimeout(() => {
            navigate("/announcement");
          }, 100);
        })
        .catch((error) => toast.error("Something went wrong!"));
    }
  };

  React.useEffect(() => {
    dispatch(changeLabel(""));
  }, []);

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>

      <CardLayout className="p-sm-5">
        <Box className="mb-5">
          <DivideTitle title="create announcement" className="mb-4" />
          <Row className="d-flex flex-column">
            <Col xl={12} className="">
              <Row>
                <Col xl={4}>
                  <Text>Title</Text>
                   <LabelField
                    type="text"
                    placeholder = "Type title"
                    fieldSize={`w-xl h-md`}
                    name="title"
                    onChange={handleChange}
                  />
                </Col>

                <Col xl={4}>
                  <Text>Select Role</Text>
              
                  <Multipleselect
                    options={[
                      { role: "Driver", id: 1 },
                      { role: "User", id: 2 },
                    ]}
                    displayValue="role"
                    placeholder="Choose Role"
                    selectionLimit="1"
                    onSelect={handleRole}
                    onRemove={handleRemove}
                    fieldSize = {`w-xl h-md`}
                  />
                </Col>
                </Row>
                <Row>
                <Col xl={4}>
                  <Text>Message</Text>
               
                  <LabelTextarea
                    type="text"
                    fieldSize={`w-xl h-md`}
                    placeholder= "Type message"
                    name="message"
                    onChange={handleChange}
                  />
                </Col>
                </Row>
            
            </Col>
          </Row>
        </Box>
        <div
          className=" d-inline blackButton"
          onClick={handleClick}
        >Submit</div>
      </CardLayout>
      <ToastContainer />
    </PageLayout>
  );
}
