import React from "react";
import { getDriverProfileById } from "../../slices/driverManagementSlice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Tabs , Tab } from "react-bootstrap";
import { Row, Col, Modal, Form } from "react-bootstrap";
import PageLayout from "../../layouts/PageLayout";
import { CardLayout } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components"
import data from "../../data/master/document.json"
import { Item , Anchor } from "../../components/elements"
import DriverProfile from "./DriverProfile";
import { ridesByProfileId } from "../../slices/rideManagementSlice";
import {DivideTitle} from "../../components";
import DriverRideHistory from "../../components/DriverRideHistory";
import { getAllRides } from "../../slices/rideManagementSlice";
import { useSelector } from "react-redux";
import { changeLabel } from "../../slices/sidebarSlice";
export default function VerifiedDriverPage(){
    const location = useLocation();
    const driver = location.state;
    const dispatch = useDispatch();
    
    const [currentPage , setCurrentPage] = React.useState(1);
    const [itemsPerPage] = React.useState(10)
    const allRides = useSelector((state) => state.rideManagement.allRides);

  // React.useEff44ect(() => {
  //   dispatch(getDriverProfileById(id))
  //     .unwrap()
  //     .then((res) => setDriverProfile(res))
  //     .catch((error) => {
  //       if (error.message === "Rejected")
  //         console.log("Error in getting driver profile");
  //     });
  // }, []);


  const fetchRides = (params) =>{
    dispatch(getAllRides(params))
    .unwrap()
    .catch((error) => console.log(error));

  }
  React.useEffect(() => {
    fetchRides({driver : driver?._id , page : currentPage , limit : itemsPerPage})
    //  dispatch(changeLabel("ridemanagement"));
   }, []);

   const handlePageChange = (newPage) =>{
    setCurrentPage(newPage)
    fetchRides({
      driver : driver?._id,
      page : newPage,
      limit : itemsPerPage
      })
  }


  const getStartIndex = () =>{
    return (currentPage - 1) * itemsPerPage
  }
    return (

        <PageLayout>
        <Row>
          <Col xl={12}>
            <CardLayout>
              <Breadcrumb title="driver profile">
                {data?.breadcrumb.map((item, index) => (
                  <Item key={index} className="mc-breadcrumb-item">
                    {item.path ? (
                      <Anchor className="mc-breadcrumb-link" href={item.path}>
                        {item.text}
                      </Anchor>
                    ) : (
                      item.text
                    )}
                  </Item>
                ))}
              </Breadcrumb>
            </CardLayout>
          </Col>
  
        <div className="">
            {driver?.isVerifiedDriver === true  ? 
        <Tabs
         defaultActiveKey="profile"
         id="mc"
         className="mc-tabs"
         // onSelect={(k) => setKey(k)}
       >
         <Tab eventKey="profile" title="profile" className="mc-tabpane all">
           {/* <AnnouncementTable tbody={announcements} /> */}
           <DriverProfile />
         </Tab>

         <Tab eventKey="rides" title="rides" className="mc-tabpane driver">
           {/* <RidesTable tbody={announcements} /> */}
           <>
             <DriverRideHistory startIndex = {getStartIndex} data = {allRides?.docs} />
             {allRides?.totalDocs  > 0 &&
             <Pagination  
              totalItems={allRides?.totalDocs}
              itemsPerPage={allRides?.pagingCounter}
              currentPage={currentPage}
              limit={allRides?.limit}
              onPageChange={handlePageChange}
             />
            }
    </>
         </Tab>
       </Tabs>
       :
       <DriverProfile />
 }
      </div> 
      </Row>
      </PageLayout>
    )
}


