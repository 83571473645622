import React from "react";
import { Item, Anchor } from "../../components/elements";
import { Breadcrumb } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/reason.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { changeLabel } from "../../slices/sidebarSlice";
import { ReasonTable } from "../../components/tables";
import {
  getAllReasonsList,
  toggleChangeReason,
} from "../../slices/manageSettingsSlice";
import AddReason from "./AddReason";
import {DivideTitle} from "../../components";
import {Box} from "../../components/elements";

export default function ReasonManagement() {
  const dispatch = useDispatch();
  const { reasonsList } = useSelector((state) => state.manageSettings);

  const toggleChange = (id) => {
    dispatch(toggleChangeReason(id));
  };


  React.useEffect(() => {
    console.log("running")
    dispatch(getAllReasonsList())
      .unwrap()
      .catch((error) => toast.error("Something went wrong!"));

    dispatch(changeLabel("managesettings"));
  }, []);

 

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title="Settings">
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>

        
        <CardLayout className="p-sm-5" >
        <Box className="mb-5">
        <DivideTitle title = "Add Reason" className="mb-4"/>
        <AddReason />
        </Box>
        </CardLayout>

       <CardLayout >
        <ReasonTable
          tbody={reasonsList}
          toggleChange={toggleChange}
          toast={toast}
        />
      </CardLayout>
      <ToastContainer />
    </PageLayout>
  );
}
