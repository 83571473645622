import React from "react";
import { Accordion } from "react-bootstrap";
import CardHeader from "./CardHeader";
import { Box, AccordianItems } from "../elements";
import NewAccordian from "../elements/NewAccordian";
// import data from "../../data/master/document.json";

export default function AccordianCard({ data, profile }) {
  
  return (
    <Box className="">
      {/* <CardHeader title={`Documents`} /> */}

      {/* <Accordion>
        {data?.map((item, index) => (
          <AccordianItems
            key={index}
            header={item.header}
            main={item.main}
            eventKey={index}
            // documentId={documentId}
            profile={profile}
          />
        ))}
      </Accordion> */}
      <Accordion>
        {profile?.profile?.documentId?.map((item, index) => (
          <NewAccordian
            key={index}
            eventKey={index}
            // name={item.name}
            firstName={item.firstName}
            lastName={item.lastName}
            documentNumber={item.documentNumber}
            routingNumber={item.routingNumber}
            docType={item.docType}
            // dob={item.dob}
            docImg={item.docImg}
            make={item.make}
            model={item.model}
            year={item.year}
            expirationDate = {item?.expirationDate}
            documentName = {item?.documentName}
            companyName = {item.companyName}
          />
        ))}
        {profile?.profile?.vehicleId &&
        <NewAccordian eventKey="5" vehicle={profile?.profile?.vehicleId} vehicleType = {profile?.profile?.vehicleTypeId} vehicleColor = {profile?.profile?.vehicleColor} />
       } 
      </Accordion>
    </Box>
  );
}
