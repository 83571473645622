import authAxios from "./AxiosMiddleware";
import { logout } from "../pages/master/Login";

const ApiCall = async (url, method, params, data ) => {
  try {
    const res = await authAxios({
      url,
      method,
      params,
      data

    });
    return res;
  } catch (error) {
    if (error.response && error.response.status === 406) {
      logout(); 
      // navigate('/login'); // Redirect to the login page
    }
    return error;
  }
};

export default ApiCall;


