import React from "react";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Text, Box, Button } from "../elements";
import { useNavigate } from "react-router-dom";
import { EditIcon } from "../icons/icons";

export default function VehiclePriceTable({ startIndex , tbody }) {
  const thead = [
    "vehicle type",
    "base price",
    "waiting charge",
    "night charge",
    // "rain charge",
    // "platform fee",
    "action",
  ];

  const [data, setData] = React.useState([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    setData(tbody);
  }, [tbody]);


  console.log("on table page")
  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                <Text>uid</Text>
              </Box>
            </Th>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {data?.length > 0 ? data?.map((item, index) => (
            <Tr key={index}>
              <Td title="id">
                <Box className="mc-table-check">
                  <Text>#{startIndex() + index + 1}</Text>
                </Box>
              </Td>
              <Td title={item.label}>{item.label}</Td>
              <Td>{item.price}</Td>
              <Td>{item.waitingPrice}</Td>
              <Td>{item.nightPrice}</Td>
              {/* <Td>{item.rainyDayPrice}</Td> */}
              {/* <Td>{item?.plateformFee}</Td> */}
               <Td >
                <div onClick={() => navigate("/add-vehicle-price", { state: item._id })}> <EditIcon />
                </div>
                </Td>
            </Tr>
          )) 
        :
        <tr className="">
          <td valign = "top" colspan = {thead.length + 1} className="text-center">No data available for table</td>
        </tr>
        }
        </Tbody>
      </Table>
    </Box>
  );
}
