import React from "react";
import { Row, Col } from "react-bootstrap";
import LabelField from "../../../components/fields/LabelField";
import { Text, Box } from "../../../components/elements";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { uploadImages } from "../../../slices/driverManagementSlice";

export default function RegistrationCertificate({ rcDocs, setDocData, img }) {
  let formData = new FormData();

  const dispatch = useDispatch();

  const handleDocumentChange = (e, type) => {
    if (type === "text" || type =="date") {
      const { name, value } = e.target;
      // setDocData((prev) => ({
      //   ...prev,
      //   [name]: value,
      //   docType: "registration_certificate",
      // }));

      setDocData((prev) => ({
        ...prev,
        registration_certificate: {
          ...prev["registration_certificate"],
          [name]: value,
          docType: "registration_certificate",
        },
      }));
    } else if (type === "file") {
      // if (e.target.files.length > 0) {
      //   formData.set("img", e.target.files[0]);
      // }

      if (e.target.files.length > 0) {
        for (let i = 0; i < e.target.files.length; i++) {
          formData.append("img", e.target.files[i]);
        }
      }

      dispatch(uploadImages(formData))
        .unwrap()
        .then((url) => {
          if (url.result !== undefined) {
            img.current.push(url.result[0]);
          } else {
            img.current.pop();
          }

          // setDocData((prev) => ({
          //   ...prev,
          //   docImg: img.current,
          //   docType: "registration_certificate",
          // }));

          setDocData((prev) => ({
            ...prev,
            // registration_certificate: {
            //   ...prev["registration_certificate"],
            //   docImg: img.current,
            //   docType: "registration_certificate",
            // },

            registration_certificate: {
              ...prev["registration_certificate"],
              docImg: url.result,
              docType: "registration_certificate",
            },
          }));
        })
        .catch((error) => {
          if (error.message === "Rejected")
            toast.error("Something unwanted occured");
        });
    }
  };

  return (
   <div className = "container">
    <div className="row">
      <div className="col-md-3">
        <Text>Registration Number</Text>
      
        <LabelField
          type="text"
          name="documentNumber"
          fieldSize={`w-xl h-md`}
          placeholder = "Type registration no."
          // value={rcDocs?.documentNumber}
          value={rcDocs?.registration_certificate?.documentNumber}
          onChange={(e) => handleDocumentChange(e, "text")}
        />
      </div>

      <div className="col-md-3">
        <Text>Expiry Date</Text>
     
        <LabelField
          type="date"
          name="expirationDate"
          fieldSize={`w-xl h-md`}
          // value={rcDocs?.expirationDate}
          value={rcDocs?.registration_certificate?.expirationDate}
          onChange={(e) => handleDocumentChange(e, "date")}
        />
     </div>
     </div>

     <div className = "row mt-3">
      <div className="col-md-3">
        <Text>Make</Text>
     
        <LabelField
          type="text"
          name="make"
          fieldSize={`w-xl h-md`}
          placeholder = "Type make"
          // value={rcDocs?.make}
          value={rcDocs?.registration_certificate?.make}
          onChange={(e) => handleDocumentChange(e, "text")}
        />
     </div>

     <div className="col-md-3">
        <Text>Model</Text>
     
        <LabelField
          type="text"
          name="model"
          fieldSize={`w-xl h-md`}
          placeholder = "Type model"
          // value={rcDocs?.model}
          value={rcDocs?.registration_certificate?.model}
          onChange={(e) => handleDocumentChange(e, "text")}
        />
     </div>

     <div className="col-md-3">
        <Text>Year</Text>
    
        <LabelField
          type="text"
          name="year"
          fieldSize={`w-xl h-md`}
          placeholder = "Type year"
          // value={rcDocs?.year}
          value={rcDocs?.registration_certificate?.year}
          onChange={(e) => handleDocumentChange(e, "text")}
        />
      </div>
      </div>

     <div className="row mt-3">
      <div className="col-md-6">
        <Text>Upload Registration Certificate</Text>
     
        <LabelField
          type="file"
          // name="reg_certificate"
          name="front"
          fieldSize={`w-xl h-md`}
          multiple
          onChange={(e) => handleDocumentChange(e, "file")}
        />

        <Box className="d-flex gap-2">
          {rcDocs?.registration_certificate?.docImg?.map((url, i) => (
            <img key={i} src={url} alt="" height="100px" width="100px" />
          ))}
        </Box>
        </div>
        </div>

      {/* <ToastContainer /> */}
    </div>
  );
}
