import React from "react";
import { Text} from "../../components/elements";
import { LabelField } from "../../components/fields";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { addNewReason } from "../../slices/manageSettingsSlice";
import Multipleselect from "../../components/Multipleselect";
import { resetPassword } from "../../services/authService";
import { useNavigate } from "react-router-dom";

export default function AddReason() {
  const navigate = useNavigate()
  const [reasonData, setReasonData] = React.useState({
    label: "",
    labelFor: "",
    reasonFor: "",
  });

  const [resetKey, setResetKey] = React.useState(0);

  const dispatch = useDispatch();

  const handleReason = (e) => {
    setReasonData((prev) => ({
      ...prev,
      label: e.target.value,
    }));
  };

  const handleRole = (_, selectedItem) => {
    setReasonData((prev) => ({
      ...prev,
      labelFor: selectedItem.label.toLowerCase(),
    }));
  };

  const handleRemoveRole = () => {
    setReasonData((prev) => ({
      ...prev,
      labelFor: "",
    }));
  };

  const handleReasonFor = (_, selectedItem) => {
    setReasonData((prev) => ({
      ...prev,
      reasonFor: selectedItem.label.toLowerCase(),
    }));
  };

  const handleRemoveReasonFor = () => {
    setReasonData((prev) => ({
      ...prev,
      reasonFor: "",
    }));
  };

  const handleReasonSubmit = () => {
    if (reasonData.label && reasonData.labelFor && reasonData.reasonFor) {
      dispatch(addNewReason(reasonData))
        .unwrap()
        .then((res) => {
          toast.success("Reason added successfully" ,{
            autoClose : 5,
           
            onClose: () => {
              setReasonData({
                label: "",
                labelFor: "",
                reasonFor: "",
              });
              setResetKey(resetKey + 1);
            }
        });
        setTimeout(() => {
          navigate('/manage-reason')
      },10)
          
        })
        .catch((error) => toast.error("Something went wrong!"));
    } else if (!reasonData.label) {
      toast.warn("Add a reason!");
    } else if (!reasonData.labelFor) {
      toast.warn("Select a role!");
    } else {
      toast.warn("Select a reason for!");
    }
   
  
  };

  


  return (
    <>
       <div className="container"> 
          <div className="row ">
            <div className = "col-md-3">
              <Text >Reason</Text>
              <LabelField
                type="text"
                fieldSize={`w-xl h-md`}
                placeholder = "Type reason"
                value={reasonData?.label}
                onChange={handleReason}
              />
             </div>
      
            
                <div className = " p-1 col-md-3">
                   Select role
                
                  <Multipleselect
                  key = {resetKey}
                    options={[
                      { id: 1, label: "Driver" },
                      { id: 2, label: "User" },
                    ]}
                    displayValue="label"
                    placeholder="Choose Role"
                    onSelect={handleRole}
                    onRemove={handleRemoveRole}
                    selectionLimit="1"
                  // width = "auto"
                  />
                </div>

                <div className="p-1 col-md-3">Reason For
               
                  <Multipleselect
                  key = {resetKey}
                    options={[
                      { id: 1, label: "Cancel" },
                      { id: 2, label: "Deactivate" },
                      {id : 3 , label : "Concern"}
                    ]}
                    displayValue="label"
                    placeholder="Choose Reason For"
                    onSelect={handleReasonFor}
                    onRemove={handleRemoveReasonFor}
                    selectionLimit="1"
                    //  width = "auto"
                  />
                </div>
            
        
       
                <div className="d-flex justify-content-end">
        <div className="blackButton"
                  onClick={()=>handleReasonSubmit()}>
          Submit
        </div>
        </div>
        </div>
        </div>

    
   
       <ToastContainer />
    </>
  );
}
