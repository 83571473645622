import React from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/ecommerce.json";
import { Box, Item, Anchor, Heading, Text } from "../../components/elements";
import {
  CardHeader,
  CardLayout,
  OrdersCard,
  RidesCard,
  SalesCard,
  ClientsCard,
} from "../../components/cards";
import { changeLabel } from "../../slices/sidebarSlice";
import { useDispatch, useSelector } from "react-redux";
import { CarIcon, DriveIconOnCard , DriverIcon , UserIcon , EarningIcon} from "../../components/icons/icons";
import { dashboard } from "../../slices/rideManagementSlice";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function HomePage() {

  const dispatch = useDispatch();
   const navigate = useNavigate()
  const home = useSelector((state) => state?.rideManagement?.homeData)

const DashboardCount = () =>{
  dispatch(dashboard()).unwrap()
  .catch((error) => console.log(error));
}

  React.useEffect(() => {
    if(!localStorage.getItem("token")) {
      navigate("/login");
    }
    DashboardCount();
    dispatch(changeLabel(""));
  }, []);

  console.log(home, "klk")

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <Box className="mc-card">
            <Breadcrumb title="Dashboard">
              {data?.breadcrumb?.map((item, index) => (
                <Item key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </Item>
              ))}
            </Breadcrumb>
          </Box>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <CardHeader title="total rides" />
            <Row>
              <Col xl={3}>
            <Box className = "d-flex topCards mc-card align-items-center justify-content-between  rounded p-4">
                  <Box>
                    <Heading className="">{home?.totalRideCount}</Heading>
                    <Text>Rides</Text>
                  </Box>
                  <Box className = "completeColor">
                    <CarIcon />
                  </Box>
                </Box>
              </Col>
              <Col xl={3}>
                <Box className="d-flex topCards  mc-card align-items-center justify-content-between rounded p-4">
                  <Box>
                    <Heading className="">{home?.totalDriverCount}</Heading>
                    <Text>Drivers</Text>
                  </Box>

                  <Box className="driverColor">
                    <DriverIcon />
                  </Box>
                </Box>
              </Col>
              <Col xl={3}>
                <Box className="d-flex topCards mc-card align-items-center justify-content-between rounded p-4">
                  <Box>
                    <Heading className="">{home?.totalUserCount}</Heading>
                    <Text>Users</Text>
                  </Box>
                  <Box className="userColor">
                    <UserIcon />
                  </Box>
                </Box>
              </Col>
              <Col xl={3}>
                <Box className="d-flex topCards mc-card align-items-center justify-content-between rounded p-4">
                  <Box>
                    <Heading className="text-2xl font-[600]">${home?.totalEarnings?.toFixed(2)}</Heading>
                    <Text>Total Earnings</Text>
                  </Box>
                  <Box className="earningColor">
                    {/* <Icon fontSize="large">wallet</Icon> */}
                    <EarningIcon />
                  </Box>
                </Box>
              </Col>
              </Row>

              <Row>

                <Col xl={4}>
                  {/* <Box className="bg-cover overflow-hidden rounded pb-3" style={{ backgroundImage: 'url(/images/greenContainer.png)' }}> */}
               <Box className = 'complete overflow-hidden rounded '>
                    <Box className="d-flex p-4  align-items-center justify-content-between">
                      <Box className="">
                        <Heading className="">
                          {home?.completedRide}
                        </Heading>
                        <Text className="">Completed Rides</Text>
                      </Box>
                      <Box className="completeColor">
                        {/* <NoTransferIcon fontSize="large" /> */}
                        <DriveIconOnCard />
                      </Box>
                    </Box>

                    {/* <Box>
                    <Text className="px-4"><span className="cancelledColor p-1">+ 95%</span><span className="p-1">Last Month</span></Text>
                    </Box> */}

                  </Box>
                </Col>

              
                <Col xl={4}>
                  <Box className="upcoming rounded overflow-hidden">
                    <Box className=" d-flex p-4  align-items-center justify-content-between">
                      <Box>
                        <Heading className="">{home?.scheduleRide}</Heading>
                        <Text className=""> Upcoming Rides</Text>
                      </Box>
                      <Box className=" upcomingColor">
                        <DriveIconOnCard />
                      </Box>
                    </Box>

                    {/* <Box>
                    <Text className="px-4"><span className="upcomingColor p-1">+ 95%</span><span className="p-1">Last Month</span></Text>
                    </Box> */}
                  </Box>
                </Col>

                <Col xl={4}>
                  {/* <Box className="bg-cover overflow-hidden rounded pb-3" style={{ backgroundImage: 'url(/images/RedContainer.png)' }}> */}
                  <Box className = "owerflow-hidden rounded cancelled">
                    <Box className="d-flex p-4  align-items-center justify-content-between">
                      <Box className="">
                        <Heading className="">
                          {home?.cancelledRide}
                        </Heading>
                        <Text className="">Cancelled Rides</Text>
                      </Box>
                      <Box className="cancelledColor">
                        {/* <NoTransferIcon fontSize="large" /> */}
                        <DriveIconOnCard />
                      </Box>
                    </Box>

                    {/* <Box>
                    <Text className="px-4"><span className="cancelledColor p-1">+ 95%</span><span className="p-1">Last Month</span></Text>
                    </Box> */}

                  </Box>
                </Col>
            </Row>
          </CardLayout>
        </Col>

       <Col xl={12}>
          <RidesCard
            title={data?.products.title}
            table={data?.products.table}
            ride = {home?.getLatestRide}
          />
        </Col>

        <Col xl={6}>
          <SalesCard
            title={data?.sales.title}
            amount={home?.sales?.totalEarnings?.toFixed(2)}
            // percent={data?.sales.percent}
            // trendIcon={data?.sales.trendIcon}
            // dotsMenu={data?.sales.dotsMenu}
            compare={home?.sales?.previousMonthSale?.toFixed(2)}
            chart={home?.sales?.chart}
          />
        </Col>

        <Col xl={6}>
          <OrdersCard title={data?.orders.title} items={data?.orders.items} />
        </Col>

        {/* <Col xl={6}>
          <ClientsCard
            title={data?.drivers.title}
            table={data?.drivers.table}
          />
        </Col> */}
      </Row>
    </PageLayout>
  );
}
