import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addAnnouncement,
  getAnnouncement,
} from "../services/announcementService";

export const getAllAnnouncement = createAsyncThunk(
  "manage_announcement/getAllAnnouncement",
  async (params, { rejectWithValue }) => {
    try {
      const res = await getAnnouncement(params);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewAnnouncement = createAsyncThunk(
  "manage_announcement/addNewAnnouncement",
  async (announcement, { rejectWithValue }) => {
    try {
      const res = await addAnnouncement(announcement);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const announcement = createSlice({
  name: "manage_announcement",
  initialState: {
    announcements: [],
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAnnouncement.fulfilled, (state, action) => {
      state.announcements = action.payload.result;
    });

    builder.addCase(addNewAnnouncement.fulfilled);
  },
});

export default announcement.reducer;
