import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import {
  Button,
  Image,
  Input,
  Text,
  Box,
  Icon,
  Anchor,
  Option,
  Heading,
} from "../elements";
import userInfo from "../../data/master/teamList.json";
import Multiselect from "multiselect-react-dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAdminPermission,
  getAllActivePermissions,
} from "../../slices/teamManagementSlice";
import { ActiveToggleIcon, EditIcon } from "../icons/icons";
import { ToggleIcon } from "../icons/icons";

export default function TeamTable({ startIndex ,tbody, toggleChange }) {
  let permissionId = [];

  const [data, setData] = useState([]);
  const [userData, setUserData] = React.useState("");
  const [editModal, setEditModal] = React.useState(false);
  const [blockModal, setBlockModal] = React.useState(false);

  const dispatch = useDispatch();

  const activePermissions = useSelector(
    (state) => state.teamManagement.activePermissions
  );

  const thead = ["uid","name", "role", "email", "gender", "status", "action"];

  const getActivePermissions = () => {
    dispatch(getAllActivePermissions({ isActive: true }))
      .unwrap()
      .catch((error) => console.log("Something went wrong!"));
  };

  const onSelect = (selectedList) => {
    // console.log(selectedList, 'list');
    selectedList.map((item) => {
      if (!permissionId.includes(item._id)) {
        permissionId.push(item._id);
      }
    });
  };

  const onRemove = (_, removedItem) => {
    permissionId = permissionId.filter((id) => id !== removedItem._id);
  };

  useEffect(() => {
    setData(tbody);
    getActivePermissions();
  }, [tbody]);

  // const handleCheckbox = (event) => {
  //   const { name, checked } = event.target;

  //   if (name === "allCheck") {
  //     const checkData = data?.map((item) => {
  //       return { ...item, isChecked: checked };
  //     });
  //     setData(checkData);
  //   } else {
  //     const checkData = data?.map((item) =>
  //       item.name === name ? { ...item, isChecked: checked } : item
  //     );
  //     setData(checkData);
  //   }
  // };

  const onEditPermission = (id) => {
    if (permissionId?.length !== 0) {
      const data = {
        Id: id,
        permissions: permissionId,
      };
      dispatch(changeAdminPermission(data))
        .unwrap()
        .then(() => toast.success("Permissions changed successfully!"))
        .catch((error) => toast.error("Error occurred!"));
    } else {
      toast.warn("Permission is required!");
    }
    setEditModal(false);
  };

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            {/* <Th>
              <Box className="mc-table-check">
                
                <Text>uid</Text>
              </Box>
            </Th> */}
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {data?.length > 0 ? data?.map((item, index) => (
            <Tr key={index} >
              <Td title="id">
                <Box className="mc-table-check">
                  {/* <Input
                    type="checkbox"
                    name={item.name}
                    checked={item?.isChecked || false}
                    onChange={handleCheckbox}
                  /> */}
                  <Text>#{startIndex() + index + 1}</Text>
                </Box>
              </Td>
              <Td title={item.name}>
                <Box className="mc-table-profile">
                  {/* <Image src={item.src} alt={item.alt} /> */}
                  <Text className = "text-capitalize">{item.name}</Text>
                </Box>
              </Td>
              <Td title={item.role}>
                <Box className="mc-table-icon role">
                  {/* {item.role.text === "vendor" && (
                    <Icon className="material-icons yellow">
                      {item.role.icon}
                    </Icon>
                  )}
                  {item.role.text === "member" && (
                    <Icon className="material-icons green">
                      {item.role.icon}
                    </Icon>
                  )} */}
                  {/* {item.role.text === "admin" && (
                    <Icon className="material-icons purple">
                      {item.role.icon}
                    </Icon>
                  )} */}
                  {/* {item.role.text === "founder" && (
                    <Icon className="material-icons blue">
                      {item.role.icon}
                    </Icon>
                  )} */}
                  {/* <Text as="span">{item.role.text}</Text> */}
                  {/* {item.role === "admin" && (
                    <Icon className="material-icons purple">settings</Icon>
                  )} */}
                  <Text className = "text-capitalize" as="span">{item.role}</Text>
                </Box>
              </Td>
              <Td title={item.email}><Text className = "text-capitalize">{item.email}</Text></Td>
              {/* <Td title={item.phone}>{item.phone}</Td> */}
              <Td title={item.gender}><Text className="text-capitalize">{item.gender}</Text></Td>
              <Td title={item.isActive}>
                {/* {item.isActive && (
                  <Text className="mc-table-badge green">active</Text>
                )}

                {!item.isActive && (
                  <Text className="mc-table-badge red">inactive</Text>
                )} */}
                {item?.isActive && (
                    <Text className="activeTag ">active</Text>
                  )}

                  {!item?.isActive && (
                    <Text className="inActiveTag">inactive</Text>
                  )}
              
              </Td>
              <Td>
                <Box className="mc-table-action align-items-center">
                  {/* <Anchor
                    href="/user-profile"
                    title="View"
                    className="material-icons view"
                  >
                    {item.action.view}
                  </Anchor> */}
                  <div
                    // title="Edit"
                    // className="material-icons edit"
                    onClick={() => setEditModal(true, setUserData(item))}
                  >
                    <EditIcon />
                  </div>
                  <div onClick = {async() => await toggleChange(item?.authId)}>
                  {item?.isActive ? <ActiveToggleIcon /> : <ToggleIcon />}
                 </div>
                  {/* <Form.Check
                    type="switch"
                    id="switch"
                    defaultChecked={item.isActive}
                    onChange={() => toggleChange(item.authId)}
                  /> */}
                </Box>
              </Td>

              {/* <Td title={item.status}>
                {item.status === "approved" && (
                  <Text className="mc-table-badge green">{item.status}</Text>
                )}
                {item.status === "pending" && (
                  <Text className="mc-table-badge purple">{item.status}</Text>
                )}
                {item.status === "blocked" && (
                  <Text className="mc-table-badge red">{item.status}</Text>
                )}
              </Td> */}
              {/* <Td title={item.created}>{item.created}</Td> */}
              {/* <Td>
                <Box className="mc-table-action">
                  <Anchor
                    href="/user-profile"
                    title="View"
                    className="material-icons view"
                  >
                    {item.action.view}
                  </Anchor>
                  <Button
                    title="Edit"
                    className="material-icons edit"
                    onClick={() => setEditModal(true, setUserData(item))}
                  >
                    {item.action.edit}
                  </Button>
                  <Button
                    title="Block"
                    className="material-icons block"
                    onClick={() => setBlockModal(true)}
                  >
                    {item.action.block}
                  </Button>
                </Box>
              </Td> */}
            </Tr>
          ))
        :
        <tr  className = "">
       <td colspan = {thead.length + 1} valign = "top" className = "text-center">
         No Data available in table
         </td>
        </tr>
        }
        </Tbody>
      </Table>

      <Modal
        show={editModal}
        onHide={() => setEditModal(false, setUserData(""))}
      >
        <Box className="mc-user-modal mt-3">
          {/* <Image src={userData.src} alt={userData?.alt} /> */}
          <Heading as="h4">{userData?.name}</Heading>
          <Text as="p">{userData?.email}</Text>
          <div className="d-flex flex-column gap-2">
            <h6>Edit Permission</h6>
            {/* <Form.Select>
              <Option>{userData?.role ? userData?.role.text : ""}</Option>
              {userInfo.role.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Form.Select> */}
            <Multiselect
              options={activePermissions}
              displayValue="label"
              placeholder="Select Permissions..."
              hidePlaceholder="true"
              onSelect={onSelect}
              onRemove={onRemove}
              avoidHighlightFirstOption
              customCloseIcon={
                <button
                  type="button"
                  className="btn-close ms-2"
                  aria-label="Close"
                ></button>
              }
              className="arrow-dropdown"
              style={{
                multiselectContainer: {
                  // width: "75%",
                  backgroundColor: "#FAFAFA",
                  borderRadius: "8px",
                  cursor: "pointer",
                },
                searchBox: {
                  borderRadius: "8px",
                },
                inputField: {
                  // padding: "3px",
                  marginLeft: "5px",
                },
                chips: {
                  background: "#f9c935",
                  fontSize: "15px",
                  color: "#5e5d72",
                  // padding: "8px",
                  padding: "5px",
                },
              }}
            />
          </div>
          {/* <Form.Group className="form-group inline">
            <Form.Label>status</Form.Label>
            <Form.Select>
              <Option>{userData?.status}</Option>
              {userInfo.status.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Form.Select>
          </Form.Group> */}
          <Modal.Footer>
            <Box className = "d-flex gap-2">
            <div
              // type="button"
              // className="btn btn-secondary"
               className="whiteButton"
              onClick={() => setEditModal(false)}
            >
              close
            </div>
            <div
              // type="button"
              className="blackButton"
              // className="font-[500] border-[0.5px] border-solid uppercase py-2 px-3 rounded-md bg-[#262626] text-white"
              onClick={() => onEditPermission(userData.authId)}
            >
              save
            </div>
            </Box>
          </Modal.Footer>
        </Box>
      </Modal>

      {/* <Modal show={blockModal} onHide={() => setBlockModal(false)}>
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">Want to block this user's account?</Text>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setBlockModal(false)}
            >
              nop, close
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => setBlockModal(false)}
            >
              yes, block
            </Button>
          </Modal.Footer>
        </Box>
      </Modal> */}
      <ToastContainer />
    </Box>
  );
}
