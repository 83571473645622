import { Endpoints, HttpMethods } from "../constants";
import ApiCall from "../utils/ApiCall";

export const getRiders = (params) =>
  ApiCall(Endpoints.GET_ALL_PROFILE, HttpMethods.GET, params);

export const toggleChangeRiders = (id) =>
  ApiCall(Endpoints.TOGGLE_USER.replace(":id", id), HttpMethods.GET);

export const getRiderProfile = (profileId) =>
  ApiCall(Endpoints.RIDER_PROFILE.replace(":profileId" , profileId), HttpMethods.GET )

  
  

