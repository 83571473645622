import React from "react";
import { Row, Col } from "react-bootstrap";
import LabelField from "../../../components/fields/LabelField";
import { Text, Box } from "../../../components/elements";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { uploadImages } from "../../../slices/driverManagementSlice";

export default function ChauffeurDetail({ chauffeurDocs, setDocData, img }) {
  let formData = new FormData();

  const dispatch = useDispatch();

  const handleDocumentChange = (e, type) => {
    if (type === "text" || type == "date") {
      const { name, value } = e.target;
      // setDocData((prev) => ({
      //   ...prev,
      //   [name]: value,
      //   docType: "chauffeur",
      // }));

      setDocData((prev) => ({
        ...prev,
        chauffeur: {
          ...prev["chauffeur"],
          [name]: value,
          docType: "chauffeur",
        },
      }));
    } else if (type === "file") {
      // if (e.target.files.length > 0) {
      //   formData.set("img", e.target.files[0]);
      // }

      if (e.target.files.length > 0) {
        for (let i = 0; i < e.target.files.length; i++) {
          formData.append("img", e.target.files[i]);
        }
      }

      dispatch(uploadImages(formData))
        .unwrap()
        .then((url) => {
          if (url.result !== undefined) {
            img.current.push(url.result[0]);
          } else {
            img.current.pop();
          }

          // setDocData((prev) => ({
          //   ...prev,
          //   docImg: img.current,
          //   docType: "chauffeur",
          // }));

          setDocData((prev) => ({
            ...prev,
            // chauffeur: {
            //   ...prev["chauffeur"],
            //   docImg: img.current,
            //   docType: "chauffeur",
            // },
            chauffeur: {
              ...prev["chauffeur"],
              docImg: url.result,
              docType: "chauffeur",
            },
          }));
        })
        .catch((error) => {
          if (error.message === "Rejected")
            toast.error("Something unwanted occured");
        });
    }
  };

  return (
   <div className = "container">
    <div className="row">
      <div className="col-md-3">
        <Text>Chauffeur Number</Text>
      
        <LabelField
          type="text"
          name="documentNumber"
          fieldSize={`w-xl h-md`}
          placeholder = "Type chauffeur no."
          // value={chauffeurDocs?.documentNumber}
          value={chauffeurDocs?.chauffeur?.documentNumber}
          onChange={(e) => handleDocumentChange(e, "text")}
        />
     </div>

      <div className="col-md-3">
        <Text>Expiry Date</Text>
     
        <LabelField
          type="date"
          name="expirationDate"
          fieldSize={`w-xl h-md`}
          // value={chauffeurDocs?.expirationDate}
          value={chauffeurDocs?.chauffeur?.expirationDate}
          onChange={(e) => handleDocumentChange(e, "date")}
        />
      </div>
      </div>

     <div className="row">
      <div className="col-md-3">
        <Text>Upload Chauffeur Card Both Side</Text>
     
        <LabelField
          type="file"
          // name="chauffeur_front"
          name="front"
          fieldSize={`w-xl h-md`}
          multiple
          onChange={(e) => handleDocumentChange(e, "file")}
        />
        {/* {chauffeurDocs?.chauffeur?.docImg[0] && (
          <img
            src={chauffeurDocs?.chauffeur?.docImg[0]}
            alt=""
            height="100px"
            width="100px"
          />
        )} */}
        <Box className="d-flex gap-2">
          {chauffeurDocs?.chauffeur?.docImg?.map((url, i) => (
            <img key={i} src={url} alt="" height="100px" width="100px" />
          ))}
        </Box>
        </div>
        </div>


      {/* <Col xl={4}>
        <Text>Upload Chauffeur Card Back Side</Text>
      </Col>
      <Col xl={8}>
        <LabelField
          type="file"
          // name="chauffeur_back"
          name="back"
          fieldSize={`w-xl h-md`}
          onChange={(e) => handleDocumentChange(e, "file")}
        />
      </Col> */}

      {/* <ToastContainer /> */}
      </div>
  );
}
