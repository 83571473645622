import React from "react";
import { Box, Heading } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { useDispatch } from "react-redux";
import {
  getPolicyToEdit,
} from "../../slices/manageSettingsSlice";
import { useLocation } from "react-router-dom";

export default function PrivacyPolicy() {
  const [policyData, setPolicyData] = React.useState({});

  const dispatch = useDispatch();

  const location = useLocation();

  const getPolicy = (id) => {
    dispatch(getPolicyToEdit(id))
      .unwrap()
      .then((res) =>
        setPolicyData(() => ({
          heading: res.heading,
          content: res.content,
        }))
      )
      .catch((error) => console.log("Something went wrong!"));
  };

  React.useEffect(() => {
    if (location.state) {
      getPolicy(location.state);
    }
  }, []);

  return (
    <PageLayout>
      <Box className="mc-card">
        <Box>
          <Heading as="h3" className="pb-3 font-bold text-xl">
            {policyData?.heading ? policyData.heading : null}
          </Heading>
          <div
            dangerouslySetInnerHTML={{
              __html: policyData?.content ? policyData.content : null,
            }}
          ></div>
        </Box>
      </Box>
    </PageLayout>
  );
}
