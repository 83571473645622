import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Box, Text, Item, Anchor, Button } from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { Breadcrumb, DivideTitle } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/permissions.json";
import { PermissionsTable } from "../../components/tables";
// import data from "../../data/master/teamList.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CardHeader } from "../../components/cards";
import { useDispatch, useSelector } from "react-redux";
import { AddIcon } from "../../components/icons/icons";
import {
  addNewPermission,
  getAllActivePermissions,
} from "../../slices/teamManagementSlice";
import { changeLabel } from "../../slices/sidebarSlice";
import Multipleselect from "../../components/Multipleselect";
export default function Permissions() {
  const [permission, setPermission] = React.useState("");

  const dispatch = useDispatch();

  const permissions = useSelector(
    (state) => state.teamManagement.activePermissions
  );

  const handleChange = (e) => {
    setPermission(e.target.value);
  };

  const handleClick = async () => {
    console.log(permission, "permission");
    if (permission) {
      dispatch(addNewPermission({ label: permission }))
        .unwrap()
        .then(() => toast.success("Permission added successfully"))
        .catch((error) => toast.error("Something went wrong!"));
    } else {
      toast.warning("Add a permission");
    }
    setPermission("");
  };

  React.useEffect(() => {
    dispatch(getAllActivePermissions())
      .unwrap()
      .catch((error) => console.log("Something went wrong!"));

    dispatch(changeLabel("teammanagement"));
  }, []);
console.log(permissions , "kl")
  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>

      <CardLayout className="p-sm-5">
        <Box className="mb-5">
          <DivideTitle title="add permission" className="mb-5" />
       
          {/* <div className = "col-md-3 ">
         <Text>Create Permission</Text>
          </div> */}

         <div className="d-flex gap-2">
         <LabelField
          type="text"
          name="email"
          placeholder = "Type permission"
          fieldSize={`w-xl h-md`}
          value={permission}
          onChange={(e) => handleChange(e)}
        />

       
                <div className=" blackButton" onClick= {() => handleClick() } >
            <span className="px-1"> <AddIcon /></span>
            Add Permission
           </div>
                </div>
            
              {/* </Row>
            </Col>
          </Row> */}
        </Box>
      </CardLayout>

      <CardLayout className={`mt-4`}>
        <CardHeader title="Give Permission to New Members " />
        <PermissionsTable
          // tbody={data?.table.tbody}
          // tbody={permissions}
          permissions={permissions}
          toast={toast}
        />
      </CardLayout>
      <ToastContainer />
    </PageLayout>
  );
}
