import React, { useState, useEffect } from "react";
import PageLayout from "../../layouts/PageLayout";
import { Row , Col } from "react-bootstrap";
import { CardLayout } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import data from "../../data/master/requestedPayout.json";
import { Item } from "../../components/elements";
import {Anchor} from "../../components/elements";
import {CardHeader} from "../../components/cards";
import DatePicker from "../../components/elements/DatePicker";
import { DownloadIcon } from "../../components/icons/icons";
import { SearchIcon } from "../../components/icons/icons";
import { Search } from "../../components/fields/search";
import { getDateInYYYYMMDD } from "../../components/elements/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { getAllPayments } from "../../slices/paymentSlice";
import { changeLabel } from "../../slices/sidebarSlice";
import PaymentsTable from "../../components/tables/PaymentsTable";
import { downloadCsv } from "../../components/elements/DownloadCsv";
import { toast, ToastContainer } from "react-toastify";
import { downloadPayment , searchPayment } from "../../slices/paymentSlice";
import RequestPayoutTable from "../../components/tables/RequestPayoutTable";


export const DriverRequestedPayouts = () =>{
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(10);
  const dispatch = useDispatch();

  const [searchValue , setSearchValue ] = React.useState("");
  const searchData = useSelector((state) => state.driverManagement.searchData)
  const [searchActive, setSearchActive] = React.useState(false);
  

  const allPayments = useSelector((state) => state?.payment?.allPayments);
  const fetchPayments = (params) =>{
  //   dispatch(getAllPayments(params))
  //   .unwrap()
  //   .catch((error) => console.log("Something went wrong"))
  }

  useEffect(()=>{
     const res = fetchPayments({page : currentPage , limit : itemsPerPage})
   
     dispatch(changeLabel("payments"));
   },[])

  const [selectedRange , setSelectedRange] = React.useState({
    startDate : new Date(),
    endDate : new Date()
  })

 const downloadPayments = async(params) =>{
   try{
    const csvContent = await dispatch(downloadPayment(params)).unwrap();
    downloadCsv(csvContent , "PaymentList.csv")
   }catch(error){
    toast.error("No Data found")
   }
 }

  const handleDateRange = (range) =>{
    let {startDate , endDate } = range;
    setSelectedRange(range);
    let formattedStart =  getDateInYYYYMMDD(startDate);
    let formattedEnd = getDateInYYYYMMDD(endDate);
    fetchPayments({ fromDate : formattedStart  , toDate  : formattedEnd , page : currentPage , limit : itemsPerPage})
   }

   const DownloadData = () =>{
    let startDate = getDateInYYYYMMDD(selectedRange?.startDate)
    let endDate = getDateInYYYYMMDD(selectedRange?.endDate)
    downloadPayments({ fromDate : startDate , toDate : endDate })
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    
    applyFilters()
  };

 const search = async(data) =>{
  setSearchActive(true)
  const res = await dispatch(searchPayment(data))
   .unwrap()
   .catch((error) => console.log(error));
}
  
  const applyFilters = () => {
    const params = { page : currentPage , limit: itemsPerPage};
  
    if (selectedRange) {
        params.fromDate = getDateInYYYYMMDD(selectedRange.startDate);
        params.toDate = getDateInYYYYMMDD(selectedRange.endDate);
        fetchPayments(params)
    }
  
     if(searchValue) {
        params.searchTerms = searchValue;
        search(params);
    } else {
        fetchPayments({page : currentPage , limit : itemsPerPage});
    }
  };
  const getStartIndex = () =>{
    return (currentPage - 1 ) * itemsPerPage
  }


  const handleChange = (e)=>{
    if(e.target.value === ""){
      setSearchActive(false)
    }
    setSearchValue(e.target.value)
   
  }
  
  useEffect(() => {
    applyFilters();
  }, [currentPage ,searchValue, selectedRange]);

  const tbody  = searchActive && searchData.length === 0 ? [] : (searchActive ? searchData : allPayments)
  
    return(
    <PageLayout>
    <Row>
      <Col xl={12}>
        <CardLayout>
          <Breadcrumb title={data?.pageTitle}>
            {data?.breadcrumb?.map((item, index) => (
              <Item key={index} className="mc-breadcrumb-item">
                {item.path ? (
                  <Anchor className="mc-breadcrumb-link" href={item.path}>
                    {item.text}
                  </Anchor>
                ) : (
                  item.text
                )}
              </Item>
            ))}
          </Breadcrumb>
        </CardLayout>
      </Col>

    

      <Col xl={12}>
        <CardLayout>
          <CardHeader title={data?.cardTitle} />

          <div className="d-flex justify-content-between flex-nowrap pb-4">
          <div className="d-flex gap-2">
          <DatePicker onDateRangeSubmit  = {handleDateRange} />
           <div className="d-flex cursor-pointer wrap-content " onClick = {DownloadData}>
            
       <div className="px-1"><DownloadIcon /> </div>
       <div className="text-uppercase">Export</div>
    
          </div>
          </div>

         <Col xl = {5}>
         <div className="d-flex wrap-content"   >
          <span className="px-1"><SearchIcon /> </span>
         <span className="search-input"><Search placeholder="Search by name/email" value = {searchValue || "" } onChange = {handleChange} /> </span></div>

         </Col>
         </div>

        
         <RequestPayoutTable 
         tbody = ""
        // tbody={searchData.length  > 0 ? tbody : tbody?.docs}
       startIndex = {getStartIndex}
        //  data = {data}
         />
           {/* <Pagination
            totalItems={tbody?.totalDocs}
            itemsPerPage={tbody?.pagingCounter}
            currentPage={currentPage}
            limit={tbody?.limit}
            onPageChange={handlePageChange}
          />  */}
        </CardLayout>
      </Col>
    </Row>
    <ToastContainer />
  </PageLayout>
);
}

