import { Endpoints, HttpMethods } from "../constants";
import ApiCall from "../utils/ApiCall";

export const getDrivers = (params) =>
  ApiCall(Endpoints.GET_ALL_PROFILE, HttpMethods.GET, params);

export const toggleChangeDrivers = (id) =>
  ApiCall(Endpoints.TOGGLE_USER.replace(":id", id), HttpMethods.GET);

export const getDriverProfile = (id) =>
  ApiCall(Endpoints.USER_PROFILE_VIEW.replace(":id", id), HttpMethods.GET);

// export const verifyDriverProfile = (id) =>
//   ApiCall(Endpoints.VERIFY_DRIVER_PROFILE.replace(":id", id), HttpMethods.GET);

// export const rejectDriverProfile = (data) =>
//   ApiCall(Endpoints.REJECT_DRIVER_PROFILE, HttpMethods.POST, null, data);

export const uploadImage = (data) =>
  ApiCall(Endpoints.UPLOAD_IMAGE, HttpMethods.POST, null, data);

export const addDriver = (data) =>
  ApiCall(Endpoints.ADD_USER, HttpMethods.POST, null, data);

export const getStates = (params) =>
  ApiCall(Endpoints.GET_STATE, HttpMethods.GET, params);

export const getStateById = (id) =>
  ApiCall(Endpoints.GET_STATE_BY_ID.replace(":id", id), HttpMethods.GET);

export const getCounty = (id) =>
  ApiCall(Endpoints.GET_COUNTY_BY_STATE.replace(":id", id), HttpMethods.GET);

export const getCountyById = (id) =>
  ApiCall(Endpoints.GET_COUNTY_BY_ID.replace(":id", id), HttpMethods.GET);

export const getLanguage = (params) =>
  ApiCall(Endpoints.GET_LANGUAGE, HttpMethods.GET, params);

export const getLanguageById = (id) =>
  ApiCall(Endpoints.GET_LANGUAGE_BY_ID.replace(":id", id), HttpMethods.GET);

export const getVehicleType = (params) =>
  ApiCall(Endpoints.GET_VEHICLE_TYPE, HttpMethods.GET , params);

export const getVehicleTypeLists = () =>
  ApiCall(Endpoints.GET_VEHICLE_LIST , HttpMethods.GET )

export const getVehicleTypeById = (id) =>
  ApiCall(Endpoints.GET_VEHICLE_BY_ID.replace(":id", id), HttpMethods.GET);

export const verifyDocument = (profileId , docType) =>
  ApiCall(Endpoints.VERIFY_DOCUMENT.replace(":profileId", profileId ).replace(":docType", docType), HttpMethods.GET);

export const rejectDocument = (data) =>
  ApiCall(Endpoints.REJECT_DOCUMENT ,  HttpMethods.POST, null, data);

export const verifyProfile = (id) =>
  ApiCall(Endpoints.VERIFY_PROFILE.replace(":id" , id) , HttpMethods.GET)


export const getCompany = (params) =>
  ApiCall(Endpoints.GET_ALL_COMPANY, HttpMethods.GET, params);

export const getCompanyById = (id) =>
  ApiCall(Endpoints.GET_COMPANY_BY_ID.replace(":id", id), HttpMethods.GET);

export const downloadDrivers = (params) =>
  ApiCall(Endpoints.DOWNLOAD_PROFILES , HttpMethods.GET , params);

export const downloadVehicleType = (params) =>
  ApiCall(Endpoints.DOWNLOAD_VEHICLE_TYPE , HttpMethods.GET , params);

export const searchProfile = (params) =>
  ApiCall(Endpoints.SEARCH_PROFILE , HttpMethods.GET ,params);

export const driverCount = () =>
  ApiCall(Endpoints.DRIVER_COUNT , HttpMethods.GET);

export const searchVehicleType = (params) =>
  ApiCall(Endpoints.SEARCH_VEHICLE_TYPE , HttpMethods.GET , params)
