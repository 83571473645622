import { ThemeProvider } from "./context/Themes";
import { LoaderProvider } from "./context/Preloader";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import { Suspense, lazy } from "react";
import { Login } from "./pages/master";
import {RideManagement} from "./pages/master";
import PendingRide from "./pages/master/PendingRides";
// import CancelledRide from "./pages/master/CancelledRides";
// import CompletedRide from "./pages/master/CompletedRides";
import "./index.css";
import { DriverRequestedPayouts } from "./pages/master/DriverRequestedPayouts"; 
import {Payouts} from "./pages/master/Payouts";
import { Transactions } from "./pages/master/Transactions";
import RequestedPayout from "./pages/master/RequestedPayout";
import OngoingRide from "./pages/master/OngoingRides";
import ContactUs from "./pages/master/ContactUs";

// GLOBAL ROUTES

// const Login = lazy(() => import("./pages/master/Login"));
const ForgotPassword = lazy(() => import("./pages/master/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/master/ResetPassword"));
const MyAccount = lazy(() => import("./pages/master/MyAccount"));
const Notification = lazy(() => import("./pages/master/Notification"));
const PrivacyPolicy = lazy(() => import("./pages/master/PrivacyPolicy"));
const Announcement = lazy(() => import("./pages/master/Announcement"));
const AddAnnouncement = lazy(() => import("./pages/master/AddAnnouncement"));
const DocumentsDetail = lazy(() => import("./pages/master/DocumentsDetail"));
const Error = lazy(() => import("./pages/supports/Error"));
const RideInfo = lazy(() => import("./pages/master/RideInfo"));
const GoogleMaps = lazy(() => import("./pages/supports/GoogleMaps"));
// const MapView = lazy(() => import("./pages/supports/MapView"));


// PROTECTED ROUTES

const HomePage = lazy(() => import("./pages/master/HomePage"));
// const UserProfile = lazy(() => import("./pages/master/UserProfile"));
// const RideManagement = lazy(() => import("./pages/master/RideManagement"));
// const PendingRides = lazy(() => import("./pages/master/PendingRides"));
const CompletedRides = lazy(() => import("./pages/master/CompletedRides"));
const CancelledRides = lazy(() => import("./pages/master/CancelledRides"));

// const RideInfo= lazy(() => import("./pages/master/RideInfo"));
const DriverManagement = lazy(() => import("./pages/master/DriverManagement"));
const PendingDrivers = lazy(() => import("./pages/master/PendingDrivers"));
const DriverProfile = lazy(() => import("./pages/master/DriverProfile"));
const RiderManagement = lazy(() => import("./pages/master/RiderManagement"));
const RiderProfile = lazy(() => import("./pages/master/RiderProfile"));
// const FareManagement = lazy(() => import("./pages/master/FareManagement"));
const RatingsList = lazy(() => import("./pages/master/RatingsList"));
const TeamList = lazy(() => import("./pages/master/TeamList"));
const AddAdmin = lazy(() => import("./pages/master/AddAdmin"));
const Permissions = lazy(() => import("./pages/master/Permissions"));
const FeesManagement = lazy(() => import("./pages/master/FeesManagement"));
const ApiCredentials = lazy(() => import("./pages/master/ApiCredentials"));
const CountryManagement = lazy(() =>
  import("./pages/master/CountryManagement")
);
const PolicyManagement = lazy(() => import("./pages/master/PolicyManagement"));
const AddPolicy = lazy(() => import("./pages/master/AddPolicy"));
const AddLanguage = lazy(() => import("./pages/master/AddLanguage"));
// const VehicleType = lazy(() => import("./pages/master/VehicleType"));
const AddVehiclePrice = lazy(() => import("./pages/master/AddVehiclePrice"));
const Company = lazy(() => import("./pages/master/Company"));
const VehicleColor = lazy(() => import("./pages/master/VehicleColor"));
const VerifiedDriverPage = lazy(() => import("./pages/master/VerifiedDriverPage"))
const MultiStepAddDriver = lazy(() =>
  import("./pages/master/MultiStepAddDriver")
);
const AddReason = lazy(() => import("./pages/master/AddReason"));
const ReasonManagement = lazy(() => import("./pages/master/ReasonManagement"));

export default function App() {
  return (
    <ThemeProvider>
      {/* <LoaderProvider> */}
        <BrowserRouter>
          <Routes>
            {/* master Pages */}

            {/* //////////////////UNPROTECTED ROUTES////////////////////  */}

            {/* <Route
              path="/login"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Login />
                </Suspense>
              }
            /> */}

            <Route path="/" element={<ContactUs />} />

            <Route path="/login" element={<Login />} />

            <Route
              path="/forgot-password"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ForgotPassword />
                </Suspense>
              }
            />

            <Route
              path="/reset-password"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ResetPassword />
                </Suspense>
              }
            />

            <Route
              path="/my-account"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <MyAccount />
                </Suspense>
              }
            />

            <Route
              path="/notification"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Notification />
                </Suspense>
              }
            />

            <Route
              path="*"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Error />
                </Suspense>
              }
            />

            <Route
              path="/privacy-policy"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />

            {/* <Route
              path="/maintenance"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Maintenance />
                </Suspense>
              }
            /> */}

            <Route
              path="/announcement"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Announcement />
                </Suspense>
              }
            />

            <Route
              path="/add-announcement"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <AddAnnouncement />
                </Suspense>
              }
            />

            <Route
              path="/riding"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <RideInfo />
                </Suspense>
              }
            />

            <Route
              path="/documents"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <DocumentsDetail />
                </Suspense>
              }
            />

            {/* <Route
              path="/map"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <MapView />
                </Suspense>
              }
            /> */}

            <Route
              path="/map"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <GoogleMaps />
                </Suspense>
              }
            />

            {/* //////////////////PROTECTED ROUTES////////////////////  */}

            {/* <Route path="/" element={<Navigate to="/home" replace />} /> */}
            {/* <Route path="/" element={<Navigate to="/dashboard" replace />} /> */}
            {/* <Route
              path="/dashboard"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <Dashboard />
                  </ProtectedRoutes>
                </Suspense>
              }
            /> */}

            <Route
              path="/home"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <HomePage />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            {/* <Route
              path="/user-profile"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <UserProfile />
                  </ProtectedRoutes>
                </Suspense>
              }
            /> */}

            {/* <Route
              path="/pending-rides"
              element={
                // <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <PendingRide />
                  </ProtectedRoutes>
                //  </Suspense> 
              }
            /> */}

           <Route 
              path="/scheduled-rides"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <RideManagement />
                  </ProtectedRoutes>
                </Suspense>
              }
            />


           <Route
              path="/completed-rides"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <CompletedRides />
                  </ProtectedRoutes>
                </Suspense>
              }
            />


           <Route
              path="/ongoing-rides"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <OngoingRide />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/cancelled-rides"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <CancelledRides />
                  </ProtectedRoutes>
                </Suspense>
              } 
            />  

           

            <Route
              path="/pending-rides"
              element={
                // <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <RideManagement />
                  </ProtectedRoutes>
                // </Suspense>
              }
            />
            

            {/* <Route
              path="/ride-detail"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <RideDetail />
                  </ProtectedRoutes>
                </Suspense>
              }
            /> */}

            <Route
              path="/riding"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <RideInfo />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/verified-drivers"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <DriverManagement />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/driver-profile"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <VerifiedDriverPage />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

           <Route
              path="/pending-drivers"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <PendingDrivers />
                  </ProtectedRoutes>
                </Suspense>
              }
            />


            <Route
              path="/mui"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <MultiStepAddDriver />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/rider-management"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <RiderManagement />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/rider-profile"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <RiderProfile />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

          

            <Route
              path="/rating-list"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <RatingsList />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/team-list"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <TeamList />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/add-admin"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <AddAdmin />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/permissions"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <Permissions />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/fee-management"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <FeesManagement />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/api-credential"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <ApiCredentials />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/country-management"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <CountryManagement />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/policy-management"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <PolicyManagement />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/add-policy"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <AddPolicy />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/add-language"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <AddLanguage />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            {/* <Route
              path="/vehicle-type"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <VehicleType />
                  </ProtectedRoutes>
                </Suspense>
              }
            /> */}

            <Route
              path="/add-vehicle-price"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <AddVehiclePrice />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/company"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <Company />
                  </ProtectedRoutes>
                </Suspense>
              }
            />


          
            <Route
              path="/add-color"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <VehicleColor />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/add-reason"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <AddReason />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

            <Route
              path="/manage-reason"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <ReasonManagement />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

           <Route
              path="/requested-payouts"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <DriverRequestedPayouts />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

          
           <Route
              path="/payouts"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <Payouts />
                  </ProtectedRoutes>
                </Suspense>
              }
            />
           <Route
              path="/transactions"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <Transactions />
                  </ProtectedRoutes>
                </Suspense>
              }
            />

           <Route
              path="/requested-payout"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ProtectedRoutes>
                    <RequestedPayout />
                  </ProtectedRoutes>
                </Suspense>
              }
            />
          </Routes>
        </BrowserRouter>
      {/* </LoaderProvider> */}
    </ThemeProvider>
  );
}
