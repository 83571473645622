import React from "react";
import Multiselect from "multiselect-react-dropdown";

export default function Multipleselect({
  options,
  displayValue,
  placeholder,
  onSelect,
  onRemove,
  selectionLimit,
  width,
  selectedValues,
  marginLeft,
  onInputChange
}) {
  return (
    <>
      <Multiselect
        options={options}
        displayValue={displayValue}
        placeholder={placeholder}
        // hidePlaceholder="true"
        selectionLimit={selectionLimit}
        avoidHighlightFirstOption = "true"
        closeOnSelect="true"
        onSelect={onSelect}
        onRemove={onRemove}
        // showCheckbox={true}
        selectedValues={selectedValues}
        onChange = {onInputChange}
        customCloseIcon={
          <button
            type="button"
            className="btn-close ms-2"
            aria-label="Close"
          ></button>
        }
        className="arrow-dropdown"
        style={{
          multiselectContainer: {
            width: width || '100%',
            backgroundColor: "#fAfAfA",
            borderRadius: "8px",
            cursor: "pointer",
            marginLeft: marginLeft || ""   ,
            flexWrap: "wrap", 
           },
           inputField: {
            padding: "3px",
            boxSizing : 'border-box'
          }, 
          chips: {
            background: "#f9c935",
            fontSize: "13px",
            color: "#5e5d72",
            padding: "4px 6px", 
             margin: "2px",
 
          },
        }}
      />
    </>
  );
}
