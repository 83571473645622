
import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, LoadScript, DirectionsRenderer, Marker } from "@react-google-maps/api";

function GoogleMaps({ locations }) {
  const pickup = locations?.pickupLocation?.coordinates;
  const drop = locations?.dropoffLocation?.coordinates;
  const stop1 = locations?.stopList[0]?.coordinates;
  const stop2 = locations?.stopList[1]?.coordinates;

  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [mapLoaded, setMapLoaded] = useState(false);

  const fetchDirections = useCallback(() => {
    if (window.google && window.google.maps) {
      const pickLat = pickup[0];
      const pickLng = pickup[1];
      const dropLat = drop[0];
      const dropLng = drop[1];

      const origin = { lat: pickLat, lng: pickLng };
      const destination = { lat: dropLat, lng: dropLng };

      const waypoints = [];
      if (stop1) {
        waypoints.push({ location: new window.google.maps.LatLng(stop1[0], stop1[1]), stopover: true });
      }
      if (stop2) {
        waypoints.push({ location: new window.google.maps.LatLng(stop2[0], stop2[1]), stopover: true });
      }

      const latitudes = [pickLat, dropLat, ...(stop1 ? [stop1[0]] : []), ...(stop2 ? [stop2[0]] : [])];
      const longitudes = [pickLng, dropLng, ...(stop1 ? [stop1[1]] : []), ...(stop2 ? [stop2[1]] : [])];
      const average = arr => arr.reduce((sum, value) => sum + value, 0) / arr.length;

      setMapCenter({
        lat: average(latitudes),
        lng: average(longitudes),
      });

      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: new window.google.maps.LatLng(origin.lat, origin.lng),
          destination: new window.google.maps.LatLng(destination.lat, destination.lng),
          waypoints: waypoints,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirectionsResponse(result);
          } else {
            console.error(`Error fetching directions: ${status}`, result);
          }
        }
      );
    }
  }, [pickup, drop, stop1, stop2]);

  useEffect(() => {
    if (mapLoaded && pickup && drop) {
      fetchDirections();
    }
  }, [mapLoaded, pickup, drop, stop1, stop2, fetchDirections]);

  const containerStyle = {
    width: "100%",
    height: "315px",
  };

  const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
    disableDoubleClickZoom: true,
    scrollwheel: true,
    draggable: false,
  };

  const directionsRendererOptions = {
    polylineOptions: {
      strokeColor: "#000",
      strokeOpacity: 0.8, // Slightly transparent
      strokeWeight: 4, // Thicker line
    },
  };

  if (!pickup || !drop) {
    return <div>Loading...</div>; // Render a loading state if coordinates are not yet available
  }





  console.log('Pickup:', pickup);
  console.log('Drop:', drop);
  console.log('Stop1:', stop1);
  console.log('Stop2:', stop2);

  return (
    <div className="App">
      <LoadScript
        googleMapsApiKey="AIzaSyBkOfRs10Kr8r83VYCwkQld9XzM--mJG7U"
        onLoad={() => setMapLoaded(true)}
        loadingElement={<div style={{ height: `100%` }} />}
      >
        <div style={{ display: "flex" }}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={13}
            options={mapOptions}
          >
            {directionsResponse && (
              <DirectionsRenderer
                directions={directionsResponse}
                options={directionsRendererOptions}
              />
            )}
            {pickup && <Marker position={{ lat: pickup[0], lng: pickup[1] }}  />}
            {drop && <Marker position={{ lat: drop[0], lng: drop[1] }}  />}
            {stop1 && <Marker position={{ lat: stop1[0], lng: stop1[1] }}  />}
            {stop2 && <Marker position={{ lat: stop2[0], lng: stop2[1] }}  />}
          </GoogleMap>
        </div>
      </LoadScript>
    </div>
  );
}

export default GoogleMaps;





