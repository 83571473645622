import React from "react";
import {
  Box,
  Form,
  Heading,
  Button,
  Anchor,
  Image,
  Text,
} from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/forgot.json";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { sendOtpOnAdminEmail } from "../../slices/authSlice";

export default function ForgotPassword() {
  const email_pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const [email, setEmail] = React.useState("");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleClick = () => {
    if (!email) {
      toast.warn("Enter a email!");
    } else if (!email_pattern.test(email)) {
      toast.warn("Enter a valid email!");
    } else {
      dispatch(sendOtpOnAdminEmail(email))
        .unwrap()
        .then((res) => {
          toast.success(res.result);
          setTimeout(() => {
            console.log(res, "response");
            navigate("/reset-password", {
              state: email,
            });
          }, 1000);
        })
        .catch((error) => toast.error("Something went wrong!"));
    }
  };

  return (
    <Box className="mc-auth">
      <Image
        className="mc-auth-pattern"
        src={data?.pattern.src}
        alt={data?.pattern.alt}
      />
      <Box className="mc-auth-group">
        <Logo
          src={data?.logo.src}
          alt={data?.logo.alt}
          href={data?.logo.path}
          className="mc-auth-logo"
        />

        <Form className="mc-auth-form">
          <Heading as="h4" className="mc-auth-title">
            {data?.title}
          </Heading>
          {data?.input.map((item, index) => (
            <IconField
              key={index}
              icon={item.icon}
              type={item.type}
              classes={item.fieldSize}
              placeholder={item.placeholder}
              passwordVisible={item.passwordVisible}
              onChange={handleChange}
            />
          ))}
          <Button
            className={`mc-auth-btn ${data?.button.fieldSize}`}
            type={data?.button.type}
            onClick={handleClick}
          >
            {data?.button.text}
          </Button>
        </Form>
        <Box className="mc-auth-navigate">
          <Text as="span">{data?.navigate.title}</Text>
          <Anchor href={data?.navigate.path}>{data?.navigate.text}</Anchor>
        </Box>
      </Box>
      <ToastContainer />
    </Box>
  );
}
