import React, { Fragment, useContext } from "react";
import { Row, Col, Accordion, Card } from "react-bootstrap";
import { Text, Heading, Image } from ".";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { getDateInYYYYMMDD } from "./DatePicker";

export default function NewAccordian({
  eventKey,
  firstName,
  lastName,
  documentNumber,
  routingNumber,
  docType,
  // dob,
  docImg,
  make,
  model,
  year,
  vehicle,
  expirationDate,
  documentName,
  vehicleType,
  vehicleColor,
  companyName
}) {
  function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <div
        type="button"
        style={{
          // color: isCurrentEventKey ? "#fbbf24" : "",
          color: isCurrentEventKey ? "#000" : "",
          fontWeight: isCurrentEventKey ? 600 : null,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
      
        }}
        onClick={decoratedOnClick}
      >
        {children}
        <img
          src="/images/black_arrow.png"
          alt=""
          style={
            isCurrentEventKey
              ? {
                  transform: "rotate(-180deg)",
                  transition: "transform .2s ease-in-out",
                  height: "1.25rem",
                  width: "1.25rem",
                }
              : { height: "1.25rem", width: "1.25rem" }
          }
        />
      </div>
    );
  }

  return (
    <Card style={{ marginBottom: "15px" }}>
      <Card.Header
        style={{
          backgroundColor: "#fff",
          padding: "13px",
          fontWeight: "500",
          // border: "none",
        }}
      >
        {/* <ContextAwareToggle eventKey={eventKey}>{docType}</ContextAwareToggle> */}
        {docType === "bank" ? (
          <ContextAwareToggle eventKey={eventKey}>
            Bank Details
          </ContextAwareToggle>
        ) : docType === "dl" ? (
          <ContextAwareToggle eventKey={eventKey}>
            Licence Details
          </ContextAwareToggle>
        ) : docType === "chauffeur" ? (
          <ContextAwareToggle eventKey={eventKey}>
            Chauffeur Details
          </ContextAwareToggle>
        ) : docType === "insurance" ? (
          <ContextAwareToggle eventKey={eventKey}>
            Vehicle Insurance Details
          </ContextAwareToggle>
        ) : docType === "registration_certificate" ? (
          <ContextAwareToggle eventKey={eventKey}>
            RC Details
          </ContextAwareToggle>
        ) : vehicle ? (
          <ContextAwareToggle eventKey={eventKey}>
            Vehicle Details
          </ContextAwareToggle>
        ) : null}
      </Card.Header>

      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>
          {/* {main.map((data, index) => (
            <Row key={index}>
              <Col xl={6}>
                <Heading as="h6">{data.heading}</Heading>
              </Col>
              <Col xl={6}>
                <Text>{data.text}</Text>
              </Col>
            </Row>
          ))} */}
          {docType === "bank" ? (
            <Row>
              <Col xl={6}>
                <Heading as="h6">First Name</Heading>
              </Col>
              <Col xl={6}>
                <Text  className = "text-capitalize">{firstName}</Text>
              </Col>
              <Col xl={6}>
                <Heading as="h6">Last Name</Heading>
              </Col>
              <Col xl={6}>
                <Text  className = "text-capitalize">{lastName}</Text>
              </Col>
              <Col xl={6}>
                <Heading  as="h6">Account Number</Heading>
              </Col>
              <Col xl={6}>
                <Text className = "text-uppercase">{documentNumber}</Text>
              </Col>
              <Col xl={6}>
                <Heading as="h6">Routing Number</Heading>
              </Col>
              <Col xl={6}>
                <Text className = "text-uppercase">{routingNumber}</Text>
              </Col>
              {docImg.map((item, i) => (
                <Fragment key={i}>
                  <Col xl={6}>
                    <Heading as="h6">Cheque</Heading>
                  </Col>
                  <Col xl={6}>
                    {/* <a href={item} target="_blank"> */}
                    <span  
                    className="d-flex cursor-pointer" 
                    onClick={() => {
                      const newWindow = window.open();
                      if (newWindow) {
                         newWindow.document.write(`<img src="${item}" alt="cancelled cheque" style="max-width: 100%; height: auto;" />`);
                        newWindow.document.title = "Cancelled cheque";
                      }
                    }}
                  >
                      <Image src={item} alt="" height="50px" width="50px" />
                    {/* </a> */}
                    </span>
                  </Col>
                </Fragment>
              ))}
            </Row>
          ) : docType === "dl" ? (
            <Row>
              <Col xl={6}>
                <Heading as="h6">Licence Number</Heading>
              </Col>
              <Col xl={6}>
                <Text className = "text-uppercase">{documentNumber}</Text>
              </Col>
              <Col xl={6}>
                <Heading as="h6">Expiry Date</Heading>
              </Col>
              <Col xl={6}>
                <Text>{new Date(expirationDate).toLocaleDateString()}</Text>
              </Col>

              <Col xl={6}>
                <Heading as="h6">Licence</Heading>
              </Col>
              <Col xl={6}>
                <div className="d-flex align-items-center gap-2">
                  {docImg?.map((item, i) => (
                     <span 
                     className="d-flex cursor-pointer" 
                     onClick={() => {
                       const newWindow = window.open();
                       if (newWindow) {
                          newWindow.document.write(`<img src="${item}" alt="license" style="max-width: 100%; height: auto;" />`);
                         newWindow.document.title = "License ";
                       }
                     }}
                   >
                    <img src={item} alt="" height="50px" width="50px" key={i} /></span>
                  ))}
                </div>
              </Col>
            </Row>
          ) : docType === "chauffeur" ? (
            <Row>
              <Col xl={6}>
                <Heading as="h6">Chauffeur Number</Heading>
              </Col>
              <Col xl={6}>
                <Text className = "text-uppercase">{documentNumber}</Text>
              </Col>

              <Col xl={6}>
                <Heading as="h6">Expiration Date</Heading>
              </Col>
              <Col xl={6}>
                <Text>{new Date(expirationDate).toLocaleDateString()}</Text>
              </Col>

              <Col xl={6}>
                <Heading as="h6">Chauffeur Card</Heading>
              </Col>
              <Col xl={6}>
                <div className="d-flex align-items-center gap-2">
                  {docImg?.map((item, i) => (
                     <span 
                     className="d-flex cursor-pointer" 
                     onClick={() => {
                       const newWindow = window.open();
                       if (newWindow) {
                          newWindow.document.write(`<img src="${item}" alt="chauffeur card" style="max-width: 100%; height: auto;" />`);
                         newWindow.document.title = "Chauffeur card";
                       }
                     }}
                   >
                    <img src={item} alt="" height="50px" width="50px" key={i} /></span>
                  ))}
                </div>
              </Col>
            </Row>
          ) : docType === "insurance" ? (
            <Row>
              <Col xl={6}>
                <Heading as="h6">Insurance Number</Heading>
              </Col>
              <Col xl={6}>
                <Text className = "text-uppercase">{documentNumber}</Text>
              </Col>

              <Col xl={6}>
                <Heading as="h6">Company Name</Heading>
              </Col>
              <Col xl={6}>
              <Text className = "text-capitalize">{companyName}</Text>
              </Col>

             <Col xl={6}>
                <Heading as="h6">Expiration Date</Heading>
              </Col>
              <Col xl={6}>
                <Text>{new Date(expirationDate).toLocaleDateString()}</Text>
              </Col>


              {docImg?.map((item, i) => (
                <Fragment key={i}>
                  <Col xl={6}>
                    <Heading as="h6">Insurance</Heading>
                  </Col>
                  <Col xl={6}>
                  <span 
                     className="d-flex cursor-pointer" 
                     onClick={() => {
                       const newWindow = window.open();
                       if (newWindow) {
                          newWindow.document.write(`<img src="${item}" alt="insurance" style="max-width: 100%; height: auto;" />`);
                         newWindow.document.title = "Insurance";
                       }
                     }}
                   >
                    <img src={item} alt="" height="50px" width="50px" />
                    </span>
                  </Col>
                </Fragment>
              ))}
            </Row>
          ) : docType === "registration_certificate" ? (
            <Row>
              <Col xl={6}>
                <Heading as="h6">Registration Number</Heading>
              </Col>
              <Col xl={6}>
                <Text className = "text-uppercase">{documentNumber}</Text>
              </Col>

              <Col xl={6}>
                <Heading as="h6">Make</Heading>
              </Col>
              <Col xl={6}>
                <Text className = "text-capitalize">{make}</Text>
              </Col>

              <Col xl={6}>
                <Heading as="h6">Model</Heading>
              </Col>
              <Col xl={6}>
                <Text  className = "text-capitalize">{model}</Text>
              </Col>

              <Col xl={6}>
                <Heading as="h6">Year</Heading>
              </Col>
              <Col xl={6}>
                <Text  className = "text-capitalize">{year}</Text>
              </Col>

              <Col xl={6}>
                <Heading as="h6">Expiration Date</Heading>
              </Col>
              <Col xl={6}>
                <Text>{new Date(expirationDate).toLocaleDateString()}</Text>
              </Col>

              {docImg?.map((item, i) => (
                <Fragment key={i}>
                  <Col xl={6}>
                    <Heading as="h6">Registration Certificate</Heading>
                  </Col>
                  <Col xl={6}>
                  <span 
                     className="d-flex cursor-pointer" 
                     onClick={() => {
                       const newWindow = window.open();
                       if (newWindow) {
                          newWindow.document.write(`<img src="${item}" alt="registration certificate" style="max-width: 100%; height: auto;" />`);
                         newWindow.document.title = "Registration Certificate";
                       }
                     }}
                   >
                    <img src={item} alt="" height="50px" width="50px" />
                    </span>
                  </Col>
                </Fragment>
              ))}
            </Row>
          ) : vehicle ? (
            <Row>
              <Col xl={6}>
                <Heading as="h6">Vehicle Name</Heading>
              </Col>
              <Col xl={6}>
                <Text className="text-capitalize">{vehicle.vehicleName}</Text>
              </Col>
              <Col xl={6}>
                <Heading as="h6">Vehicle Number</Heading>
              </Col>
              <Col xl={6}>
                <Text className = "text-uppercase">{vehicle.vehicleNumber}</Text>
              </Col>
              <Col xl={6}>
                <Heading as="h6">Vehicle Type</Heading>
              </Col>
              <Col xl={6}>
                <Text className = "text-capitalize">{vehicleType?.label}</Text>
              </Col>
              <Col xl={6}>
                <Heading as="h6">Vehicle Color</Heading>
              </Col>
              <Col xl={6}>
                <Text className="text-capitalize">{vehicleColor?.label}</Text>
              </Col>
              {/* <Col xl={6}>
                <Heading as="h6">No. of Seat</Heading>
              </Col>
              <Col xl={6}>
                <Text>{vehicle.numberOfSeats}</Text>
              </Col> */}

              <Col xl={6}>
                <Heading as="h6">Vehicle Images</Heading>
              </Col>
              <Col xl={6}>
                <div className="d-flex align-items-center gap-2">
                  {vehicle?.vehicleGallery.map((item, i) => (
                    <span 
                    className="d-flex cursor-pointer" 
                    onClick={() => {
                      const newWindow = window.open();
                      if (newWindow) {
                         newWindow.document.write(`<img src="${item}" alt="vehicle images" style="max-width: 100%; height: auto;" />`);
                        newWindow.document.title = "vehicle images";
                      }
                    }}
                  >
                    <img src={item} alt="" height="50px" width="50px" key={i} />
                    </span>
                  ))}
                </div>
              </Col>
            </Row>
          ) : null}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
