import React from "react";
import { List, Item, Anchor, Button } from "../elements";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeLabel } from "../../slices/sidebarSlice";
import iconMapping from "../icons/iconsMapping";

export default function MenuItem({ item }) {
  const location = useLocation();
  const path = location.pathname;
  const dispatch = useDispatch();
  const { label } = useSelector((state) => state.sidebar);

  const renderIcon = (iconName, isActive) => {
    const activeIconName = `${iconName}Active`;
    const IconComponent = isActive ? iconMapping[activeIconName] : iconMapping[iconName];
    return IconComponent ? <IconComponent /> : null;
  };
  
  const isActive = item.url?.includes(path) || label === item.label;


  return item.submenu ? (
    <Item className={`mc-sidebar-menu-item ${isActive ? "active" : ""}`}>
      <>
        <Button
          icon={renderIcon(item.icon, isActive)}
          text={item.text}
          badge={item.badge}
          arrow="expand_more"
          className={`mc-sidebar-menu-btn`}
          onClick={() => {
            if (label !== item.label) dispatch(changeLabel(item.label));
            else dispatch(changeLabel(""));
          }}
        />
        <List className="mc-sidebar-dropdown-list">
          {item.submenu.map((subItem, index) => (
            <Item
              key={index}
              className={`mc-sidebar-dropdown-item ${
                subItem.href === path ||
                (subItem.text === "team list" && path === "/add-admin") ||
                (subItem.text === "fees management" && path === "/add-vehicle-price") ||
                (subItem.text === "county" && path === "/add-county") ||
                (subItem.text === "reason" && path === "/add-reason") ||
                (subItem.text === "policy" && path === "/add-policy") ||
                (subItem.text === "pending rides" && path == "/pending-rides") ||
                (subItem.text === "scheduled rides" && path == "/scheduled-rides") ||
                (subItem.text === "completed rides" && path == "/completed-rides")   ||
                (subItem.text === "cancelled rides" && path == "/cancelled-rides") ||
                (subItem.text === "pending drivers" && path === "/pending-drivers") ||
                (subItem.text === "verified drivers" && path === "/verified-drivers")
                  ? "active"
                  : ""
              }`}
            >
              <Anchor href={subItem.href} className="mc-sidebar-dropdown-link">
                {subItem.text}
              </Anchor>
            </Item>
          ))}
        </List>
      </>
    </Item>
  ) : (
    <Item className={`mc-sidebar-menu-item ${isActive ? "active" : ""}`}>
      <Anchor
        href={item.href}
        icon={renderIcon(item.icon, isActive)}
        text={item.text}
        badge={item.badge}
        className="mc-sidebar-menu-btn"
      />
    </Item>
  );
}



