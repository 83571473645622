import React from "react";
import { Text, Item, Anchor } from "../../components/elements";
import { LabelField } from "../../components/fields";
import { Breadcrumb } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/addPolicy.json";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JoditEditor from "jodit-react";
import { useDispatch } from "react-redux";
import {
  addNewPolicy,
  getPolicyToEdit,
  updateExistingPolicy,
} from "../../slices/manageSettingsSlice";
import { changeLabel } from "../../slices/sidebarSlice";

export default function AddPolicy() {
  const navigate = useNavigate();

  const location = useLocation();

  const dispatch = useDispatch();

  const [policy, setPolicy] = React.useState({
    heading: "",
    content: "",
  });
  const editor = React.useRef(null);

  const handlePolicyChange = (e) => {
    setPolicy((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePolicySubmit = async () => {
    console.log(policy, "policy");
    dispatch(addNewPolicy(policy))
      .unwrap()
      .then((res) => {
        toast.success(res.result);
        setTimeout(() => {
          navigate("/policy-management");
        }, 1000);
      })
      .catch((error) => toast.error("Something went wrong!"));
  };

  const handleUpdatePolicy = async () => {
    console.log(policy, "updated policy");
    dispatch(updateExistingPolicy(policy))
      .unwrap()
      .then((res) => {
        toast.success(res.result);
        setTimeout(() => {
          navigate("/policy-management");
        }, 1000);
      })
      .catch((error) => toast.error("Something went wrong!"));
  };

  const config = {
    width: "75%",
    height: "50vh",
    controls: {
      ul: {
        list: ["circle", "square"],
      },
      ol: {
        list: [
          "number",
          "lower-alpha",
          "upper-alpha",
          "lower-roman",
          "upper-roman",
        ],
      },
    },
    disablePlugins: [
      "paste",
      "file",
      "classSpan",
      "video",
      "source",
      "ai-commands",
      "ai-assistant",
      "copyformat",
      "about",
      "speechRecognize",
      "image",
      "spellcheck",
      "powered-by-jodit",
    ],
    style: {
      paddingLeft: "20px",
    },
  };

  const getPolicy = (id) => {
    dispatch(getPolicyToEdit(id))
      .unwrap()
      .then((res) =>
        setPolicy(() => ({
          heading: res.heading,
          content: res.content,
          id,
        }))
      )
      .catch((error) => console.log("Something went wrong!"));
  };

  React.useEffect(() => {
    if (location.state) {
      getPolicy(location.state);
    }
    dispatch(changeLabel("managesettings"));
  }, []);

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>

      <CardLayout className="">
     
          <div className="container">
            <div className="row mb-4">
              <div className="col-md-6">
                  <Text>Policy Heading</Text>
                
                  <LabelField
                    type="text"
                    fieldSize="w-75 h-md"
                    name="heading"
                    placeholder = {"Type Policy Heading"}
                    value={policy?.heading}
                    onChange={handlePolicyChange}
                  />
               </div>
               <div className="">
                  <Text>Policy Content</Text>
                
                  <JoditEditor
                    ref={editor}
                    tabIndex={1}
                    config={config}
                    value={policy?.content}
                    onBlur={(newContent) =>
                      setPolicy((prev) => ({
                        ...prev,
                        content: newContent,
                      }))
                    }
                  />
                </div>
            
            </div>
            </div>
        {location.state ? (
          <div className = "d-flex justify-content-end">
          <div 
            className=" blackButton d-inline "
          onClick={handleUpdatePolicy}
          >Update </div>
          </div>
        ) : (
         <div className ="d-flex justify-content-end">
          <div
            className="blackButton   "
           
            onClick={handlePolicySubmit}
          >Submit</div>
         </div>
        )}
      </CardLayout>
      <ToastContainer />
    </PageLayout>
  );
}
