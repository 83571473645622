import ForgotPassword from "../pages/master/ForgotPassword";

const Endpoints = {
  LOG_IN: `/auth/login`,

  FETCH_ALL: `/ride/fetchAll`,
  // FETCH_ALL : `/ride/filterRide`,
  RIDE_DETAIL: `/ride/getDetails/:id`,
  DOWNLOAD_RIDES: `/dashboard/downloadRideCsv`,

  CHANGE_PASSWORD: `/auth/changePassword`,
  // FORGOT_PASSWORD: `/auth/sendOtpOnEmail/:email`,
  FORGOT_PASSWORD : `/auth/sendOtpOnEmailForWeb/:email`,
  RESET_PASSWORD: `/auth/forgotPassword`,
  TOGGLE_USER: `/auth/togglerUser/:id`,

  ADD_USER: `/profile/addUser`,
  VIEW_PROFILE: `/profile/viewProfile`,
  PROFILE_UPDATE: `/profile/update`,
  GET_ALL_PROFILE: `/profile/getAll`,
  // GET_ALL_PROFILE : `/profile/filterUser`,
  USER_PROFILE_VIEW: `/profile/view/:id`,
  RIDER_PROFILE : `/ride/fetchRideByProfile/:profileId`,
  // VERIFY_DRIVER_PROFILE: `/profile/verifyDriverProfile/:id`,
  // REJECT_DRIVER_PROFILE: `/profile/rejectDriverProfile`,
  CHANGE_PERMISSION: `/profile/changePermission`,
  UPLOAD_IMAGE: `/profile/uploadImg`,
  DOWNLOAD_PROFILES : `/dashboard/downloadProfileCsv`,

  ADD_PERMISSION: `/permission/add`,
  GET_ALL_PERMISSION: `/permission/getAll`,
  TOGGLE_PERMISSION: `/permission/change/:id`,
  EDIT_PERMISSION: `/permission/edit`,

  ADD_ANNOUNCEMENT: `/notify/sendNotification`,
  GET_ANNOUNCEMENTS: `/notify/fetchAll`,

  // ADD_RIDE_PRICE: `/ridePrice/add`,
  GET_RIDE_PRICE: `/ridePrice/getAll`,
  GET_RIDE_PRICE_BY_ID: `/ridePrice/get/:id`,
  UPDATE_RIDE_PRICE: `/ridePrice/edit`,
  SEARCH_RIDE_ID : `/dashboard/searchRide`,


  ADD_COUNTRY: `/country/add`,
  GET_COUNTRY: `/country/get`,
  DELETE_COUNTRY: `/country/delete/:name`,

  ADD_POLICY: `/policy/add`,
  GET_POLICY: `/policy/getAll`,
  GET_POLICY_BY_ID: `/policy/get/:id`,
  UPDATE_POLICY: `/policy/edit`,

  ADD_STATE: `/state/add`,
  GET_STATE: `/state/getAll`,
  GET_STATE_BY_ID: `/state/get/:id`,
  TOGGLE_STATE: `/state/toggle/:id`,
  EDIT_STATE: `/state/edit`,

  ADD_COUNTY: `/county/add`,
  GET_ALL_COUNTY: `/county/getAll`,
  GET_COUNTY_BY_ID: `/county/get/:id`,
  GET_COUNTY_BY_STATE: `/county/getCounties/:id`,
  TOGGLE_COUNTY: `/county/toggle/:id`,
  EDIT_COUNTY: `/county/edit`,

  ADD_LANGUAGE: `/language/add`,
  GET_LANGUAGE: `/language/getAll`,
  GET_LANGUAGE_BY_ID: `/language/get/:id`,
  TOGGLE_LANGUAGE: `/language/change/:id`,
  EDIT_LANGUAGE: `/language/edit`,

  ADD_RIDE_PRICE: `/vehicleType/add`,
  GET_VEHICLE_TYPE: `/vehicleType/fetchAll`,
  GET_VEHICLE_LIST : `/vehicleType/getAll`,
  // GET_VEHICLE_LIST : `/vehicleType/filterVehicleType`,
  GET_VEHICLE_BY_ID: `/vehicleType/get/:id`,
  TOGGLE_VEHICLE_TYPE: `/vehicleType/change/:id`,
  EDIT_VEHICLE_TYPE: `/vehicleType/edit`,
  DOWNLOAD_VEHICLE_TYPE : `/dashboard/downloadVehicleTypeCsv`,
  SEARCH_VEHICLE_TYPE : `/dashboard/searchVehicleType`,

  ADD_COMPANY: `/company/add`,
  GET_ALL_COMPANY: `/company/getAll`,
  GET_COMPANY_BY_ID : `/company/get/:Id`,
  TOGGLE_COMPANY: `/company/change/:id`,
  EDIT_COMPANY: `/company/edit`,

  ADD_COLOR: `/color/add`,
  GET_ALL_COLOR: `/color/getAll`,
  TOGGLE_COLOR: `/color/change/:id`,
  EDIT_COLOR: `/color/edit`,

  ADD_REASON: `/reasons/add`,
  GET_ALL_REASON: `/reasons/getAll`,
  TOGGLE_REASON: `/reasons/change/:id`,
  EDIT_REASON: `/reasons/edit`,

  VERIFY_DOCUMENT : `/document/verify/:profileId/:docType`,
  REJECT_DOCUMENT : `/document/rejectDoc`,
  VERIFY_PROFILE : `/profile/verifyDriverProfile/:id`,

  FETCH_ALL_CASH_PAYMENTS  : `/pay/fetchAll`,
  DOWNLOAD_PAYMENTS : `/dashboard/downloadPaymentCsv`,
  SEARCH_PAYMENTS : `/dashboard/searchUser`,
  SEARCH_PROFILE : `/dashboard/searchUser`,

  DASHBOARD_COUNT : `/dashboard/mainDashboardData`,
  DRIVER_COUNT : `/dashboard/driverCount`,
  // FETCH_RIDES_BY_PROFILEID : `/ride/fetchRideByProfile/:profileId`

  CONTACT_FORM : `/country/addInfo`
};

export default Endpoints;
