import React from "react";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Text, Box, Button } from "../elements";
import { useNavigate } from "react-router-dom";
import { DriverIcon, EditIcon, ViewIcon } from "../icons/icons";

export default function PolicyTable({ tbody }) {
  const thead = ["policy type", "status", "action"];

  const [data, setData] = React.useState([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    setData(tbody);
  }, [tbody]);

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                <Text>uid</Text>
              </Box>
            </Th>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {data?.map((item, index) => (
            <Tr key={index}>
              <Td title="id">
                <Box className="mc-table-check">
                  <Text>#{index + 1}</Text>
                </Box>
              </Td>
              <Td title={item.heading}>
                <Box className="mc-table-profile">
                  <Text className = "text-capitalize">{item.heading}</Text>
                </Box>
              </Td>
              <Td>
              {item?.isActive && (
                    <Text className="activeTag">active</Text>
                  )}

                  {!item?.isActive && (
                    <Text className="inActiveTag">inactive</Text>
                  )}
              </Td>
              <Td>
                <Box className="mc-table-action align-items-center">
                  <div
                    // title="View"
                    // className="material-icons view"
                    onClick={() => navigate("/privacy-policy", { state: item._id })}
                  >
                    <ViewIcon />
                  </div>

                  <div
                    // title="Edit"
                    // className="material-icons edit"
                    onClick={() => navigate("/add-policy", { state: item._id })}
                  >
                    
                    <EditIcon />
                  </div>
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
