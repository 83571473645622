import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  Item,
  Text,
  Box,
  Anchor,
  Image,
  Button,
} from "../../components/elements";
import { Breadcrumb, DivideTitle } from "../../components";
import { CardLayout, CardHeader } from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/rideDetail.json";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getRideDetailById } from "../../slices/rideManagementSlice";
import { RatingBar } from "../../components/elements/RatingBar";
// import MapView from "./MapView";
import { Icon } from "@mui/material";
import GoogleMaps from "../supports/GoogleMaps";
import { VerifiedIcon } from "../../components/icons/icons";

export default function RideInfo() {
  const location = useLocation();
  const id = location.state;

  const dispatch = useDispatch();

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1); 
  const rideDetail = useSelector((state) => state.rideManagement.rideDetail);

  React.useEffect(() => {
    dispatch(getRideDetailById(id))
      .unwrap()
      .catch((error) => console.log("Something unwanted occurred!"));
  }, []);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title="ride details">
              {data?.breadcrumb.map((item, index) => (
                <Item key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </Item>
              ))}
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={6}>
                <CardLayout>
                  <DivideTitle title = "general details" className = "mb-4" />
                 
                  <Box className="rideText">
                   Ride Id
                    <span>{(rideDetail?.rideId)?.toUpperCase()}</span>
                  </Box>
                
                  <Box className="rideText">
                    Date Created
                    <span>{new Date(rideDetail?.pickupDate).toLocaleString()}</span>
                    
                  </Box>
                  
                  <Box className="rideText">
                    Payment Status
                    <span className="text-capitalize">
                      {rideDetail?.paymentStatus ? rideDetail?.paymentStatus : "-"}
                    </span>
                  </Box>
                  <Box className="rideText">
                    Payment Mode
                    <span className="text-capitalize">
                      {rideDetail?.paymentType}
                    </span>
                  </Box>
                
                  <Box className="rideText">
                    Ride Distance
                    <span>{rideDetail?.distance}</span>
                  </Box>
                  
                  <Box className="rideText">
                    Ride Status
                    <span>
                     {rideDetail?.status === "ongoing" && (
                        <Text 
                        className="pendingTag px-2 py-0 "
                        >
                          {rideDetail?.status}
                        </Text>
                      )}
                      {rideDetail?.status === "completed" && (
                        <Text 
                       className="completedTag px-2 py-0 "
                        >
                          {rideDetail?.status}
                        </Text>
                      )}
                      {rideDetail?.status === "scheduled" && (
                        <Text
                         className="scheduledTag px-2 py-0 "
                         >
                          {rideDetail?.status}
                        </Text>
                      )}
                      {rideDetail?.status === "cancelled" && (
                        <Text 
                        className="cancelledTag px-2 py-0 "
                        >
                          {rideDetail?.status}
                        </Text>
                      )}
                    </span>
                  </Box>
                </CardLayout>

                {/* Location details */}
                <CardLayout className="my-4">
                  <DivideTitle title = "location Details"  className="mb-4"/>
                  <Box className=""></Box>
                  <Box className="d-flex gap-2  ">
                  <div className="m-1 greenCircle">
                    <span className="dot position-absolute"></span>
                    </div>
                    <Box >
                      {rideDetail?.locations?.pickupLocation?.name}
                    </Box>
                  </Box>
                {rideDetail?.locations?.stopList.length >= 1 && 
                <>
                  <div className="vl "></div>
                  <Box className="d-flex gap-2 position-relative">
                    
                    <div className="m-1 p-2  blackCircle">1</div>
                    <Box className="">
                      {rideDetail?.locations?.stopList[0].name}
                    </Box>
                  </Box>
                  </>
                 }
                {rideDetail?.locations?.stopList.length == 2 && 
                <>
                <div className="vl"></div>
                  <Box className="d-flex gap-2 ">
                      <div className="m-1 p-2 blackCircle">2</div>
                    <Box className="">
                      {rideDetail?.locations?.stopList[1].name}
                    </Box>
                  </Box>
                  </>
                 }
                
                
                <div className="vl"></div>
                 <Box className="d-flex gap-2 ">
                  
                     <div className="m-1 p-2 redCircle"><span className = "dot position-absolute"></span></div>
                    <Box className="">
                      {rideDetail?.locations?.dropoffLocation?.name}
                    </Box>
                  </Box>
                  <Box className="border-b-2 mt-4"></Box>
                </CardLayout>



                
              </Col>

              <Col xl={6}>
                {/* Passenger detail */}
                <CardLayout>
                 <DivideTitle title = "Passenger Details" className="mb-4" />
                  <Box className="rideText text-capitalize">
                    Name
                    <span>{rideDetail?.passenger?.name}</span>
                  </Box>
                  <Box className="rideText text-capitalize">
                    Email
                    <span>{rideDetail?.passenger?.email}</span>
                  </Box>
                  <Box className="rideText">
                   Mobile No.
                    <span>{rideDetail?.passenger?.phone}</span>
                  </Box>
                  {rideDetail?.passenger?.gender  && 
                  <Box className="rideText">
                   Gender
                    <span className="text-capitalize">
                      {rideDetail?.passenger?.gender ?  rideDetail?.passenger?.gender : "-"}
                    </span>
                  </Box>
                }
                </CardLayout>

                {/* GoogleMap View */}
                <Box className="my-4">
                  <GoogleMaps locations = {rideDetail?.locations} />
                </Box>
              </Col>

              <Col xl={6}>
              

                {/* Price detail */}
                <CardLayout>
                  <DivideTitle title = "price Details" className="mb-4" />
                 <Box className="rideText">
                    <Box>Sub Total</Box>
                    <Box>${(rideDetail?.totalPrice)}</Box>
                  </Box>


                
                  <Box className="rideText">
                    <Box className="font-[600]">Total Amount</Box>
                    <Box className="text-green-600 font-[500]">
                      ${(rideDetail?.totalPrice)}
                    </Box>
                  </Box>
                </CardLayout>
              </Col>
  
           {rideDetail.status !== "pending" && rideDetail.isScheduled === true &&
              <Col xl={6}>
                {/* Driver detail */}
                <CardLayout>
                  <DivideTitle title = "Driver Details " className="mb-4" />
                  <Box className="d-flex gap-4 mb-4">
                    <Box className="mc-round-avatar img ">
                      <Image
                        src={rideDetail?.driver?.profileUrl}
                        alt="driver_picture"
                        style = {{height : "80px" , width : "80px"}}
                      />
                    </Box>
                    <Box className = "">
                      <div className = "p-2 d-flex text-center fw-semibold">
                      <Text className="text-bold">
                        {rideDetail?.driver?.name}
                      </Text>
                      <VerifiedIcon />
                      </div>
                      <Box className="d-flex justify-content-start ml-1">
                         <RatingBar stars = {rideDetail?.driverRatingReview?.averageRating} />
                        </Box>
                      {/* </Box> */}
                      
                    </Box>
                  </Box>
                  <CardHeader title="contact info" />
                  <Box className=""></Box>
                  <Box className="rideText text-capitalize">
                    Email
                    <span>{rideDetail?.driver?.email}</span>
                  </Box>
                  <Box className="rideText">
                   Mobile No.
                    <span>{rideDetail?.driver?.phone}</span>
                  </Box>
                  <Box className="mb-4"></Box>
                  <CardHeader title="Vehicle info" />
                  <Box className=""></Box>
                  <Box className="rideText text-capitalize">
                    Vehicle Name
                    <span>{rideDetail?.vehicleId?.vehicleName}</span>
                  </Box>
                  <Box className="rideText">
                   Vehicle Number
                    <span>{(rideDetail?.vehicleId?.vehicleNumber).toUpperCase()}</span>
                  </Box>
                  <Box className="rideText text-capitalize">
                   Vehicle Color
                    <span>{rideDetail?.colorId?.label}</span>
                  </Box>
                  <Box className="rideText">
                    Vehicle Picture
                    {rideDetail?.vehicleId?.vehicleGallery?.map((imgUrl, i) => (
                      <Image
                        key={i}
                        src={imgUrl}
                        alt="vehicle_image"
                        style = {{width : "50px", height : "50px"}}
                      />
                    ))}
                  </Box>
                </CardLayout>
              </Col>
           }
            </Row>
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}
