import React, { useDeferredValue, useEffect, useState } from "react";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { Anchor, Item, Box } from "../../components/elements";
import { CardLayout, CardHeader, FloatCard } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import LabelField from "../../components/fields/LabelField";
import DriversTable from "../../components/tables/DriversTable";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/driverManagement.json";
import { useDispatch, useSelector } from "react-redux";
import {
  addKey,
  driverCount,
  fetchDriver,
  toggleChange,
} from "../../slices/driverManagementSlice";
import { changeLabel } from "../../slices/sidebarSlice";
import {Text, Heading  } from "../../components/elements";
import { AddIcon, DriveIconOnCard, SearchIcon , DownloadIcon } from "../../components/icons/icons";
import { Search } from "../../components/fields/search";
import {Button} from "../../components/elements";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDateInYYYYMMDD } from "../../components/elements/DatePicker";
import DatePicker from "../../components/elements/DatePicker";
import { downloadProfiles } from "../../slices/driverManagementSlice";
import { downloadCsv } from "../../components/elements/DownloadCsv";
import { searchProfiles } from "../../slices/driverManagementSlice";
import { driverCard } from "../../slices/driverManagementSlice";
import { current } from "@reduxjs/toolkit";

export default function DriverManagement() {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const [searchValue , setSearchValue ] = React.useState("");

  const [searchActive, setSearchActive] = React.useState(false);
  const searchData = useSelector((state) => state.driverManagement.searchData)
  const cardCount = useSelector((state) => state.driverManagement.CardsCount)

  
  const defaultKey = useSelector((state) => state.driverManagement.key);
  const driver = useSelector((state) => state.driverManagement.drivers);
  const [selectedRange , setSelectedRange] = React.useState({
    startDate : new Date(),
    endDate : new Date()
  })



  const navigate = useNavigate();


  const params = {
    role: "driver",
    isVerifiedDriver : true,
  };

  const findDriver = (params) => {
    dispatch(fetchDriver(params))
      .unwrap()
      .catch((error) => console.log("Something went wrong..."));

    
  };

  const Card = () =>{
    dispatch(driverCard())
    .unwrap()
    .catch((error) => console.log(error , "error"))
  }

  
  const downloadDriversDetail = async (params) => {
    try {
      const csvContent = await dispatch(downloadProfiles(params)).unwrap();
      
         downloadCsv(csvContent, "VerifiedDriversList.csv");
     } catch (error) {
      toast.error("No Data found");
    }
  };

 

  React.useEffect(() => {
    findDriver({role : "driver" , isVerifiedDriver : true ,  page : currentPage,
      limit : itemsPerPage});
   Card();
     dispatch(changeLabel("drivermanagement"))
  }, []);

  const toggle = (id) => {
    dispatch(toggleChange({ id, params }))
    .unwrap()
    .catch((error) =>{
      if(error.message === "Rejected"){
        toast.error("Something went wrong")
      }
    });
  };





 const handleDateRange = (range) =>{
  let {startDate , endDate } = range;
  setSelectedRange(range);
  let formattedStart =  getDateInYYYYMMDD(startDate);
  let formattedEnd = getDateInYYYYMMDD(endDate);
  findDriver({role : "driver" ,isVerifiedDriver : true , fromDate : formattedStart  , toDate  : formattedEnd , page : currentPage , limit : itemsPerPage})
 }


 const DownloadData = () =>{
   let startDate = getDateInYYYYMMDD(selectedRange?.startDate)
   let endDate = getDateInYYYYMMDD(selectedRange?.endDate)

   downloadDriversDetail({role : "driver" , isVerifiedDriver : true , fromDate : startDate , toDate : endDate})
 }

 const searchProfile = async(data) =>{
  setSearchActive(true)
  const res = await dispatch(searchProfiles(data))
   .unwrap()
   .catch((error) => console.log(error));
}

const handleChange = (e)=>{
  if(e.target.value === ""){
    setSearchActive(false)
  }
  setSearchValue(e.target.value)

}


const handlePageChange = (newPage) =>{
  setCurrentPage(newPage);
  applyFilters()
}

const getStartIndex = () =>{
  return (currentPage - 1 ) * itemsPerPage
}
  

const applyFilters = () => {
  const params = {role : "driver" , isVerifiedDriver : true ,  page : currentPage , limit : itemsPerPage };

  if (selectedRange) {
      params.fromDate = getDateInYYYYMMDD(selectedRange.startDate);
      params.toDate = getDateInYYYYMMDD(selectedRange.endDate);
       findDriver(params)
    } 

   if(searchValue) {
      params.searchTerms = searchValue;
      searchProfile(params);
   }
     else {
      findDriver({role : "driver" , isVerifiedDriver : true , page : currentPage , limit : itemsPerPage});
    
  }
};

useEffect(() => {
  applyFilters();
}, [currentPage ,searchValue, selectedRange]);

  const tbody = searchActive && searchData.length === 0 ? [] : (searchActive ? searchData : driver)


  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={data?.pageTitle}>
              {data?.breadcrumb.map((item, index) => (
                <Item key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </Item>
              ))}
            </Breadcrumb>
          </CardLayout>
        </Col>
       
                <Col xl={4}>
                
                 {/* <Box className="bg-cover overflow-hidden rounded pb-3 "  style={{ backgroundImage: 'url(/images/greenContainer.png)' }}>  */}
             <Box className = "complete rounded overflow-hidden">
                    <Box className="d-flex p-4  align-items-center justify-content-between">
                      <Box className="">
                        <Heading className="">
                          {cardCount?.approvedCount}
                        </Heading>
                        <Text className="">Approved Drivers</Text>
                      </Box>
                      <Box className="completeColor p-2 rounded-xl" >
                        {/* <EventAvailableIcon fontSize="large" /> */}
                        <DriveIconOnCard />
                      </Box>
                    </Box>
                  </Box>
                </Col>

                <Col xl={4}>
                  <Box className="upcoming rounded overflow-hidden">
                    <Box className="d-flex p-4  align-items-center justify-content-between">
                      <Box>
                        <Heading className="">{cardCount?.pendingCount}</Heading>
                        <Text className="" >Pending Drivers</Text>
                      </Box>
                      <Box className="upcomingColor">
                        {/* <LocalTaxiIcon fontSize="large" /> */}
                        <DriveIconOnCard />
                      </Box>
                    </Box>
                  </Box>
                </Col>

                <Col xl={4}>
                  {/* <Box className="bg-cover overflow-hidden rounded pb-3" 
                   style={{ backgroundImage: 'url(/images/RedContainer.png)' }}
                   > */}
                   <Box className = "cancelled rounded overflow-hidden">
                    <Box className="d-flex p-4  align-items-center justify-content-between">
                      <Box className="">
                        <Heading className="">
                          {cardCount?.blockCount}
                        </Heading>
                        <Text className="">Blocked Drivers</Text>
                      </Box>
                      <Box className=" cancelledColor">
                        {/* <NoTransferIcon fontSize="large" /> */}
                        <DriveIconOnCard />
                      </Box>
                    </Box>
                   </Box>
                </Col>
            {/* </Row> */}

          
       <Col xl={12}>
          <CardLayout>
            <div className = "d-flex justify-content-between mb-2">
            <CardHeader
              title="Verified Drivers"
            /> 

      <div className=" ">
       <button className="blackButton" onClick={()=>navigate('/mui')}>
            <span className="px-1"> <AddIcon /></span>
           Add Driver
           </button>
        </div>

          
         </div>

           
            <div className="d-flex justify-content-between flex-nowrap pb-4">
              <div className="d-flex gap-2">
           <DatePicker onDateRangeSubmit  = {handleDateRange} />
              <div className="d-flex cursor-pointer  wrap-content" onClick = {DownloadData}>
              
         <span className="px-1"><DownloadIcon /> </span>
         <span className="text-uppercase">Export</span>
      
            </div>
            </div>

           <Col xl = {5}>
           <div className="d-flex searchClass"   >
            <span className="px-1"><SearchIcon /> </span>
           <span className="search-input"><Search placeholder="Search by rideID/name/email"  value= {searchValue || ""} onChange = {handleChange}/> </span></div>
           
           </Col>
           </div>

         
                <DriversTable
                  isVerifiedDriver={true}
                  startIndex = {getStartIndex}
                  tbody={searchData?.length > 0 ? tbody : tbody?.docs}
                  toggle={toggle}
                />
                {tbody.totalDocs > 0 &&
                <Pagination
                  totalItems={tbody?.totalDocs}
                  itemsPerPage={tbody?.pagingCounter}
                  currentPage={currentPage}
                  limit={tbody?.limit}
                  onPageChange={handlePageChange}
                />
                } 
          </CardLayout> 
        
         </Col>
      
      </Row>
      <ToastContainer />
    </PageLayout>
    
  );
}


