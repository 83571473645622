import { Endpoints, HttpMethods } from "../constants";
import ApiCall from "../utils/ApiCall";

export const getRides = (params) =>
  ApiCall(Endpoints.FETCH_ALL, HttpMethods.GET, params);

export const getRideDetail = (id) =>
  ApiCall(Endpoints.RIDE_DETAIL.replace(":id", id), HttpMethods.GET);


export const downloadRide = (params) =>
  ApiCall(Endpoints.DOWNLOAD_RIDES , HttpMethods.GET , params )

export const searchRideById = (params) =>
  ApiCall(Endpoints.SEARCH_RIDE_ID , HttpMethods.GET , params);

export const dashboardData = () =>
  ApiCall(Endpoints.DASHBOARD_COUNT , HttpMethods.GET)

export const rideDetailsByProfileId = (id) =>
  ApiCall(Endpoints.FETCH_RIDES_BY_PROFILEID.replace(":profileId" , id) , HttpMethods.GET)