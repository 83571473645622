import React from "react";
import { Row, Col } from "react-bootstrap";
import LabelField from "../../../components/fields/LabelField";
import { Text, Box } from "../../../components/elements";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { uploadImages } from "../../../slices/driverManagementSlice";

export default function VehicleInsurance({ insuranceDocs, setDocData, img }) {
  let formData = new FormData();

  const dispatch = useDispatch();

  const handleDocumentChange = (e, type) => {
    if (type === "text" || type == "date") {
      const { name, value } = e.target;
      // setDocData((prev) => ({
      //   ...prev,
      //   [name]: value,
      //   docType: "insurance",
      // }));

      setDocData((prev) => ({
        ...prev,
        insurance: {
          ...prev["insurance"],
          [name]: value,
          docType: "insurance",
        },
      }));
    } else if (type === "file") {
      // if (e.target.files.length > 0) {
      //   formData.set("img", e.target.files[0]);
      // }

      if (e.target.files.length > 0) {
        for (let i = 0; i < e.target.files.length; i++) {
          formData.append("img", e.target.files[i]);
        }
      }

      dispatch(uploadImages(formData))
        .unwrap()
        .then((url) => {
          if (url.result !== undefined) {
            img.current.push(url.result[0]);
          } else {
            img.current.pop();
          }

          // setDocData((prev) => ({
          //   ...prev,
          //   docImg: img.current,
          //   docType: "insurance",
          // }));

          setDocData((prev) => ({
            ...prev,
            // insurance: {
            //   ...prev["insurance"],
            //   docImg: img.current,
            //   docType: "insurance",
            // },

            insurance: {
              ...prev["insurance"],
              docImg: url.result,
              docType: "insurance",
            },
          }));
        })
        .catch((error) => {
          if (error.message === "Rejected")
            toast.error("Something unwanted occured");
        });
    }
  };

  return (
   <div className="container">
      <div className="row">
        <div className="col-md-3">
        <Text>Policy Number</Text>
     
        <LabelField
          type="text"
          name="documentNumber"
          fieldSize={`w-xl h-md`}
          placeholder = "Type vehicle's policy no."
          // value={insuranceDocs?.documentNumber}
          value={insuranceDocs?.insurance?.documentNumber}
          onChange={(e) => handleDocumentChange(e, "text")}
        />
      </div>

     <div className="col-md-3">
        <Text>Company Name</Text>
      
        <LabelField
          type="text"
          name="documentName"
          fieldSize={`w-xl h-md`}
          // value={insuranceDocs?.documentName}
          placeholder = "Type vehicle's insurance company"
          value={insuranceDocs?.insurance?.documentName}
          onChange={(e) => handleDocumentChange(e, "text")}
        />
      </div>

      <div className="col-md-3">
        <Text>Expiry Date</Text>
     
        <LabelField
          type="date"
          name="expirationDate"
          fieldSize={`w-xl h-md`}
          // value={insuranceDocs?.expirationDate}
          value={insuranceDocs?.insurance?.expirationDate}
          onChange={(e) => handleDocumentChange(e, "date")}
        />
      </div>
    </div>

     <div className="row mt-3">
      <div className="col-md-6">
        <Text>Upload Vehicle Insurance</Text>
      
        <LabelField
          type="file"
          // name="vehicle_insurance"
          name="front"
          fieldSize={`w-xl h-md`}
          multiple
          onChange={(e) => handleDocumentChange(e, "file")}
        />

        <Box className="d-flex gap-2">
          {insuranceDocs?.insurance?.docImg?.map((url, i) => (
            <img key={i} src={url} alt="" height="100px" width="100px" />
          ))}
        </Box>
      </div>
</div>
      {/* <ToastContainer /> */}
  
    </div>
  );
}
