import React from "react";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Text, Box, Button } from "../elements";
import { useNavigate } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { editExistingReason } from "../../slices/manageSettingsSlice";
import { ActiveToggleIcon ,EditIcon,ToggleIcon } from "../icons/icons";
import { CardLayout } from "../cards";
import DivideTitle from "../DivideTitle";
export default function ReasonTable({ tbody, toggleChange, toast }) {
  const thead = ["reason", "reason for", "role", "status", "action"];

  const [data, setData] = React.useState([]);
  const [editModal, setEditModal] = React.useState(false);
  const [userData, setUserData] = React.useState("");
  const [editData, setEditData] = React.useState({
    Id: "",
    label: "",
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();

  React.useEffect(() => {
    setData(tbody);
  }, [tbody]);

  const handleEditData = (e, id) => {
    setEditData({
      Id: id,
      label: e.target.value,
    });

    setUserData((prev) => ({
      ...prev,
      label: e.target.value,
    }));
  };

  const onEditReason = () => {
    if (editData?.label) {
      dispatch(editExistingReason(editData))
        .unwrap()
        .then((res) => {
          toast.success(res.result);
          setEditModal(false);
          setEditData({});
        })
        .catch((error) => toast.error("Something went wrong!"));
    } else {
      toast.warn("No changes made!");
      setEditModal(false);
    }
  };

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                <Text>uid</Text>
              </Box>
            </Th>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {data?.map((item, index) => (
            <Tr key={index}>
              <Td title="id">
                <Box className="mc-table-check">
                  <Text>#{index + 1}</Text>
                </Box>
              </Td>
              <Td className = "text-capitalize" title={item.label}>{item.label}</Td>
              <Td className = "text-capitalize" title={item.reasonFor}>{item.reasonFor}</Td>
              <Td className = "text-capitalize" title={item.labelFor}>{item.labelFor}</Td>
              <Td>
              {item?.isActive && (
                    <Text className="activeTag">active</Text>
                  )}

                  {!item?.isActive && (
                    <Text className="inActiveTag">inactive</Text>
                  )}
              </Td>
              <Td>
                <Box className="mc-table-action align-items-center">
                  <div
                    onClick={() => setEditModal(true, setUserData(item))}
                  >
                    <EditIcon />
                  </div>
                 

   <div onClick = {async() => await toggleChange(item?._id)}>
                  {item?.isActive ? <ActiveToggleIcon /> : <ToggleIcon />}
                 </div>
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal
        show={editModal}
        onHide={() => setEditModal(false, setUserData(""))}
      >
        <CardLayout >
        <DivideTitle title= "Edit Reason" className="mb-5" />
          <Form.Group className="form-group">
            {/* <Form.Label>edit reason</Form.Label> */}
            <Form.Control
              type="text"
              value={userData?.label}
              onChange={(e) => handleEditData(e, userData?._id)}
            />
          </Form.Group>

          <Modal.Footer>
            <Box className="d-flex gap-2">
            <div
             
              className="whiteButton"
              onClick={() => setEditModal(false)}
            >
              close
            </div>
            <div
              type="button"
              className="blackButton"
              onClick={onEditReason}
            >
              save
            </div>
            </Box>
          </Modal.Footer>
        </CardLayout>
      </Modal>
    </Box>
  );
}
