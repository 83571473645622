import { DashBoardIcon, ActiveDashboardIcon,
    RideManagementIcon  , ActiveRideManagementIcon , 
    DriverManagementIcon , ActiveDriverManagementIcon ,
     RiderManagementIcon , TeamManagementIcon , AnnouncementIcon , ManageSettingsIcon,
     VehicleManagementIcon,ActiveRiderManagementIcon,
     ActiveVehicleManagementIcon,
     ActiveTeamManagementIcon,
     ActiveAnnouncementIcon,
     ActiveManageSettingsIcon,} from "./icons";

const iconMapping = {
    "dashboard" : DashBoardIcon,
    "dashboardActive" : ActiveDashboardIcon,
    "ride" : RideManagementIcon,
    "rideActive" : ActiveRideManagementIcon,
    "driver" : DriverManagementIcon,
    "driverActive" : ActiveDriverManagementIcon ,
    "rider" : RiderManagementIcon,
    "riderActive" : ActiveRiderManagementIcon,
    "vehicle" : VehicleManagementIcon,
    "vehicleActive" : ActiveVehicleManagementIcon,
    "team" : TeamManagementIcon,
    "teamActive" : ActiveTeamManagementIcon,
     "announcements"  : AnnouncementIcon,
     "announcementsActive" : ActiveAnnouncementIcon,
     "settings" : ManageSettingsIcon,
     "settingsActive" : ActiveManageSettingsIcon
}

export default iconMapping;