import { Endpoints, HttpMethods } from "../constants";
import ApiCall from "../utils/ApiCall";

export const addCountry = (data) =>
  ApiCall(Endpoints.ADD_COUNTRY, HttpMethods.POST, null, data);

export const getCountry = () =>
  ApiCall(Endpoints.GET_COUNTRY, HttpMethods.GET);

export const deleteCountry = (name) =>
  ApiCall(
    Endpoints.DELETE_COUNTRY.replace(":name", name),
    HttpMethods.DELETE
  );


export const addContact = (data) =>
  ApiCall(Endpoints.CONTACT_FORM , HttpMethods.POST , null , data)
