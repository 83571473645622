import React, { useState, useEffect } from "react";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Text, Box, Button, Heading, Image } from "../elements";
import { Modal, Row, Col, Form } from "react-bootstrap";
import { rejectDocumentByProfile, verifyDocumentByProfile , handleVerifyProfile } from "../../slices/driverManagementSlice";
import { useDispatch } from "react-redux";
import { Router, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ViewIcon } from "../icons/icons";
import { CardLayout } from "../cards";
import DivideTitle from "../DivideTitle";
import { useForkRef } from "@mui/material";

export default function DocumentsTable({ tbody , toast}) {
  const [data, setData] = useState({});
  const [editModal, setEditModal] = React.useState(false);
  const [rejectModal, setRejectModal] = React.useState(false);
  const [userData, setUserData] = React.useState("");
  const [rejectReason, setRejectReason] = React.useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    setData(tbody);
  }, [tbody]);

  // useEffect(()=>{
  //   navigate("/documents")
  // },[data])

  const thead = ["document name", "status", "view", "action"];


    

  const handleVerify = (profileId , docType) => {
    dispatch(verifyDocumentByProfile({profileId , docType}))
    .unwrap()
    .then((res ) =>{
      console.log(res , "approve res")
       toast.success(res.result);
    }).catch((err) => {
      toast.error("Something went wrong!")
    })
    // console.log(profileId , docType , "on handleveridfy");
    // location.reload();
    // navigate("/documents");

    setTimeout(() =>{
      // navigate("/documents")
     window.location.reload();
    },4000)
 
  };

  
 

  const handleReject = (e) => {
 
   
    setRejectReason((prev) => ({
      ...prev,
      // id: profileId,
      reason: e.target.value,
      // docType : docType 
    }));
  
   
  };



  const submitReject = () => {

    // console.log(rejectReason, "reason for rejection!!!");
     dispatch(rejectDocumentByProfile(rejectReason))
    .unwrap()
    .then((res) =>{
      console.log(res , "reject res")
      toast.success(res.result);
      setRejectReason({})
    }).catch((err)=>{
      toast.error("Something went wrong!")
    })
  
    // window.location.reload();
    // navigate("/documents")
    setTimeout(() =>{
      // navigate("/documents")
     window.location.reload();
    },4000)
  };

  const handleProfileVerify = (profileId) =>{
    console.log(profileId)
    dispatch(handleVerifyProfile(profileId))
    .unwrap()
    .then((res)=> {
      toast.success(res.result)
    }).catch((err) =>{
      toast.error("Something went Wrong!")
    })
    // refreshPage();
    setTimeout(() =>{
      // window.location.reload();
      navigate('/verified-drivers')
    },1000)
 
  }


  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                <Text>uid</Text>
              </Box>
            </Th>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>

        <Tbody className="mc-table-body even">
          {data?.documentId?.map((item, index) => (
            <Tr key={index} onClick = {() => setEditModal(true, setUserData(item))}>
              <Td title="id">
                <Box className="mc-table-check">
                  <Text>#{index + 1}</Text>
                </Box>
              </Td>
              <Td title={item?.docType}>
                {item?.docType === "bank"
                  ? "Bank Document"
                  : item?.docType === "dl"
                  ? "Driving Licence"
                  : item?.docType === "chauffeur"
                  ? "Chauffeur Card"
                  : item?.docType === "insurance"
                  ? "Vehicle Insurance"
                  : item?.docType === "registration_certificate"
                  ? "Registration Certificate"
                  : null}
              </Td>
            
      {/* <Td title="status" className={statusClass}>{statusText}</Td> */}
      {/* const statusText = item.isVerified ? "verified" : (item.reason ? "rejected" : "pending"); */}
   
 
            <Td title="status">{item.isVerified == true ? <span className="completedTag">verified</span>  : (item?.reason ? <span className="cancelledTag" >rejected</span> : <span className="pendingTag">pending</span>)}</Td>
              <Td>
                <Box className="mc-table-action">
                  <div className="cursor-pointer" onClick={() => setEditModal(true, setUserData(item))}>  
                    <ViewIcon />
                    </div>
                   
                 
                </Box>
              </Td>
              <Td>
                <Box className="d-flex">
                  <div
                    className={item?.isVerified == true ? "blackButton disabled" :  "blackButton"}
                    onClick={(e)=>{
                      if(item.isVerified == true ){
                        e.stopPropagation();
                        return;
                      }else{
                        e.stopPropagation();
                      handleVerify(item.profileId , item.docType)
                      }
                    }}
                  >Approve</div>

                  <div
                   className={item?.isVerified == false && item?.reason ? "whiteButton disabled" : "whiteButton"}
                    onClick={(e) => {
                      if(item.isVerified == false && item.reason){
                        e.stopPropagation();
                        return;
                      }else{
                      e.stopPropagation();
                      setRejectModal(true) ;
                       setRejectReason((prev)=> ({...prev , profileId : item.profileId , docType : item.docType})
                      )
                    }
                  }
                }
                  >Reject</div>
                </Box>
              </Td>
            </Tr>
          )) }
          

          {data?.profileUrl ? (
            <Tr>
              <Td title="id">
                <Box className="mc-table-check">
                  <Text>
                    #
                    {data?.documentId?.length
                      ? data?.documentId?.length + 1
                      : data?.profileUrl
                      ? 1
                      : null}
                  </Text>
                </Box>
              </Td>
              <Td>Profile Image</Td>

              <Td title="status">{data?.isVerifyDriverProfileImg == true ? <span  className="completedTag">verified</span>: (data?.reason ? <span className="cancelledTag">rejected</span> : <span className="pendingTag">pending</span>)}</Td>
              <Td>
                <Box className="mc-table-action">
                    <div className="cursor-pointer" onClick={() =>
                      setEditModal(
                        true,
                        setUserData({ profileUrl: data?.profileUrl })
                      )
                    }>
                     <ViewIcon />
                 </div>
              
                </Box>
              </Td>

              <Td>
                <Box className="d-flex">
                  <div
                    className={data?.isVerifyDriverProfileImg == true ? 'blackButton disabled':"blackButton"}
                    onClick={(e) => 
                      {
                         if(data?.isVerifyDriverProfileImg == true){
                          e.stopPropagation();
                          return;
                         }else{
                          e.stopPropagation();
                          handleVerify(data?._id, "profile")
                         }
                         }
                      }
                  >Approve</div>

                  <div
                    className={data?.isVerifyDriverProfileImg == false && data?.reason ?"whiteButton disabled"  : "whiteButton" }
                    onClick={(e) => 
                      { 
                        if(data?.isVerifyDriverProfileImg == false && data?.reason){
                          e.stopPropagation();
                          return;
                        }else{
                          e.stopPropagation();
                        setRejectModal(true); setRejectReason((prev)=>({...prev , profileId : data._id , docType : "profile"}))
                      }}
                    }
                  >Reject </div>
                </Box>
              </Td>
            </Tr>
          ) : null}

          {data?.vehicleId ? (
            <Tr>
              <Td title="id">
                <Box className="mc-table-check">
                  <Text>
                    #
                    {data?.documentId?.length && data?.profileUrl
                      ? data?.documentId?.length + 2
                      : data?.documentId?.length && !data?.profileUrl
                      ? data?.documentId?.length + 1
                      : data?.profileUrl
                      ? 2
                      : 1}
                  </Text>
                </Box>
              </Td>
              <Td>Vehicle</Td>


        <Td title="status">{data?.vehicleId?.isVerified == true? <span className="completedTag">verified</span> : (data?.vehicleId?.reason ? <span className="cancelledTag">rejected</span> : <span className="pendingTag">pending</span>) }</Td>


              <Td>
                <Box className="mc-table-action ">
                 
                  <div className=" cursor-pointer" onClick={() =>
                      setEditModal(true, setUserData(data?.vehicleId))
                    }>
                      <ViewIcon />
                  </div>
                    
                </Box>
              </Td>

              <Td>
                <Box className="d-flex">
                  <div
                    className={data?.vehicleId?.isVerified == true ? "blackButton disabled" : "blackButton"}
                    onClick={(e)=>{
                      if(data?.vehicleId?.isVerified == true){
                        e.stopPropagation();
                        return;
                      }else{
                       e.stopPropagation();
                      handleVerify(data?._id, "vehicle")}
                    }
                    }
                  >Approve</div>

                  <div
                   className={data?.vehicleId?.isVerified == false &&  data?.vehicleId?.reason ? "whiteButton disabled" : "whiteButton" }
                    onClick={(e) => 
                      {
                        if(data?.vehicleId?.isVerified == false && data?.vehicleId?.reason){
                          e.stopPropagation();
                          return;
                        }else{
                          e.stopPropagation();
                        setRejectModal(true); setRejectReason((prev)=>({...prev , profileId : data._id , docType : "vehicle"}))
                        }
                      }
                    }
                  >Reject</div>
                </Box>
              </Td>
            </Tr>
          ) : null}
        </Tbody>
      </Table>

      <Modal
        show={editModal}
        onHide={() => setEditModal(false, setUserData(""))}
      >
        {/* <Box className="w-full pt-0 px-[40px] pb-[40px] mt-3"> */}
        <CardLayout className="p-sm-5">
        <Box className="mb-2">
          <DivideTitle title="Verify Documents" className="mb-4" />
           <Row>
            {console.log(userData  ,"user")}
              {userData?.firstName && (
                <Col xl={6}>
                  <Text className="documentText">First Name:</Text>
                  <Text className="documentData">{userData?.firstName}</Text>
                </Col>
              )}

              {userData?.lastName && (
                <Col xl={6}>
                  <Text className="documentText">Last Name</Text>
                  <Text className="documentData">{userData?.lastName}</Text>
                </Col>
              )}

            

            {userData?.routingNumber && (
                <Col xl={6}>
                  <Text className="documentText">Routing Number</Text>
                  <Text className="documentData">{userData?.routingNumber}</Text>
                </Col>
              )}


              {userData?.docType && (
                <Col xl={6}>
                  <Text className="documentText">Document Type</Text>
                  <Text className="documentData">
                    {userData?.docType === "bank"
                      ? "Bank Document"
                      : userData?.docType === "dl"
                      ? "Driving Licence"
                      : userData?.docType === "chauffeur"
                      ? "Chauffeur Card"
                      : userData?.docType === "insurance"
                      ? "Vehicle Insurance"
                      : userData?.docType === "registration_certificate"
                      ? "Registration Certificate"
                      : null}
                  </Text>
                </Col>
              )}

              {userData?.documentNumber && (
                <Col xl={6}>
                  <Text className="documentText">Document Number</Text>
                  <Text className="documentData">{userData?.documentNumber}</Text>
                </Col>
              )}


        {userData?.expirationDate && (
                <Col xl={6}>
                  <Text className="documentText">Expiration Date</Text>
                  <Text className="documentData">{new Date(userData?.expirationDate).toLocaleDateString()}</Text>
                </Col>
              )}

         {userData?.make && (
                <Col xl={6}>
                  <Text className="documentText">Make</Text>
                  <Text className="documentData">{userData?.make}</Text>
                </Col>
              )}

          {userData?.model && (
                <Col xl={6}>
                  <Text className="documentText">Model</Text>
                  <Text className="documentData">{userData?.model}</Text>
                </Col>
              )}

         {userData?.year && (
                <Col xl={6}>
                  <Text className="documentText">Year</Text>
                  <Text className="documentData">{userData?.year}</Text>
                </Col>
              )}

              {/* {userData?.swiftCode && (
                <Col xl={6}>
                  <Text className="documentText">SwiftCode</Text>
                  <Text className="documentData">{userData?.swiftCode}</Text>
                </Col>
              )} */}

              {userData?.docImg && (
                <Col xl={12}>
                  <Text className="documentText">Document Image</Text>
                  <Box className="d-flex gap-2 my-2">
                    {userData?.docImg?.map((imgUrl, i) => (
                      <span 
                      className="d-flex cursor-pointer" 
                      onClick={() => {
                        const newWindow = window.open();
                        if (newWindow) {
                           newWindow.document.write(`<img src="${imgUrl}" alt="cancelled cheque" style="max-width: 100%; height: auto;" />`);
                          newWindow.document.title = "Cancelled Cheque";
                        }
                      }}
                    >
                      <Image
                        src={imgUrl}
                        alt="document_picture"
                        height="200px"
                        width="200px"
                        key={i}
                      />
                      </span>
                    ))}
                  </Box>
                </Col>
              )}

              {userData?.profileUrl && (
                <Col xl={6}>
                  <Text className="documentText">Profile Picture</Text>
                  <span 
                      className="d-flex cursor-pointer" 
                      onClick={() => {
                        const newWindow = window.open();
                        if (newWindow) {
                           newWindow.document.write(`<img src="${userData?.profileUrl}" alt="profile image" style="max-width: 100%; height: auto;" />`);
                          newWindow.document.title = "Profile Picture";
                        }
                      }}
                    >
                  <Image
                    src={userData?.profileUrl}
                    alt="profile_picture"
                    height="400px"
                    width="400px"
                  /></span>
                </Col>
              )}

              {userData?.vehicleName && (
                <Col xl={6}>
                  <Text className="documentText">Vehicle Name</Text>
                  <Text className="documentData">{userData?.vehicleName}</Text>
                </Col>
              )}

              {userData?.vehicleNumber && (
                <Col xl={6}>
                  <Text className="documentText">Vehicle Number</Text>
                  <Text className="documentData">{userData?.vehicleNumber}</Text>
                </Col>
              )}

              {userData?.vehicleTypeId && (
                <Col xl={6}>
                  <Text className="documentText">Vehicle Type</Text>
                  <Text className="documentData">{userData?.vehicleTypeId?.label}</Text>
                </Col>
              )}

              {userData?.vehicleColor && (
                <Col xl={6}>
                  <Text className="documentText">Vehicle Color</Text>
                  <Text className="documentData">{userData?.vehicleColor?.label}</Text>
                </Col>
              )}

              {userData?.numberOfSeats && (
                <Col xl={6}>
                  <Text className="documentText">Number of Seats</Text>
                  <Text className="documentData">{userData?.numberOfSeats}</Text>
                </Col>
              )}

              {userData?.vehicleGallery && (
                <Col xl={12}>
                  <Text className="documentText">Vehicle Pictures</Text>
                  <Box className="d-flex gap-2 my-2">
                    {userData?.vehicleGallery?.map((imgUrl, i) => (
                      <span 
                      className="d-flex cursor-pointer" 
                      onClick={() => {
                        const newWindow = window.open();
                        if (newWindow) {
                           newWindow.document.write(`<img src="${imgUrl}" alt="vehicle images" style="max-width: 100%; height: auto;" />`);
                          newWindow.document.title = "vehicle images";
                        }
                      }}
                    >
                      <Image
                        src={imgUrl}
                        alt="document_picture"
                        height="300px"
                        width="300px"
                        key={i}
                      /></span>
                    ))}
                  </Box>
                </Col>
              )}
              </Row>
             

          <Modal.Footer>
            <div className = "blackButton cursor-pointer"
              onClick={() => setEditModal(false)}
            >
              Close
            
            </div>
           
          </Modal.Footer>
        {/* </Box> */}
        </Box>
        </CardLayout>
      </Modal>

      <Modal show={rejectModal} onHide={() => setRejectModal(false)} size="xl" // This sets the modal to an extra-large size
  // aria-labelledby="contained-modal-title-vcenter"
  // centered
   >
      <CardLayout className="p-sm-10 custom-modal-content ">
        <Box className="mb-2">
          <DivideTitle title="Rejection Reason" className="mb-4" />
        
          <Form.Group className="form-group">
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Type your reason here(maximum 250 charcaters)"
              onChange={(e) => handleReject(e)}
             maxLength = {250}
             style={{ width: '300px', height: '200px' , resize  : 'none'}} 
            />
          </Form.Group>
          <Modal.Footer>
            <div
              type="button"
              // className="btn btn-secondary"
              className="whiteButton"
              onClick={() => setRejectModal(false)}
            >
              Close
            </div>
            <div
              className="blackButton"
              onClick={() => {
                submitReject();
                setRejectModal(false);
              }}
            >
              Submit
            </div>
          </Modal.Footer>
        </Box>
        </CardLayout> 
        
      </Modal>

      {data?.driverDoc?.length === 7 &&
      data?.driverDoc?.filter((item) => item.isApproved === false).length ===
        0 ? (
        <Box className="d-flex flex-wrap justify-content-end mt-3">
          <div
            // text="Approve Profile"
           className="blackButton" 
            onClick={() => handleProfileVerify(data?._id)}
          >Approve Profile
        </div>
        </Box>
      ) : null}
      
    </Box>
   
  );
}
