import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Anchor, Item } from "../../components/elements";
import { CardLayout, CardHeader, FloatCard } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import LabelField from "../../components/fields/LabelField";
import TeamTable from "../../components/tables/TeamTable";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/teamList.json";
import { useDispatch, useSelector } from "react-redux";
import { downloadTeam, getAllAdmins, toggleChange } from "../../slices/teamManagementSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { changeLabel } from "../../slices/sidebarSlice";
import {Box , Button , Text , Heading } from "../../components/elements";
import { DownloadIcon , SearchIcon , AddIcon } from "../../components/icons/icons";
import { Search } from "../../components/fields/search";
import { useNavigate } from "react-router-dom";
import { getDateInYYYYMMDD } from "../../components/elements/DatePicker";
import DatePicker from "../../components/elements/DatePicker";
import { downloadCsv } from "../../components/elements/DownloadCsv";
import { searchProfiles } from "../../slices/driverManagementSlice";
import { current } from "@reduxjs/toolkit";

export default function TeamList() {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(10);

  const dispatch = useDispatch();
  const [selectedRange , setSelectedRange] = React.useState({
    startDate : new Date(),
    endDate : new Date()
  })

  const [searchValue , setSearchValue ] = React.useState("");
  const [searchActive, setSearchActive] = React.useState(false);

  const searchData = useSelector((state) => state.driverManagement.searchData)

  const adminList = useSelector((state) => state.teamManagement.adminList);

  const params = {
    role: "admin",
  };

  const getAdmins = (params) =>{
   dispatch(getAllAdmins(params))
    .unwrap()
    .catch((error) => console.log("Something went wrong!"));
  }

  React.useEffect(() => {
    getAdmins({role  : "admin" , page : currentPage , limit : itemsPerPage});
    dispatch(changeLabel("teammanagement"))
  }, []);

  const toggleChangeStatus = (id) => {
    dispatch(toggleChange({ id, params }))
      .unwrap()
      .catch((error) => {
        if (error.message === "Rejected") {
          toast.error("Something went wrong!");
        }
      });
  };

  // const handleDateRange = (range) => {
  //   setSelectedRange(range);
  //   fetchAdminsWithDate();
  // };

 const handleDateRange = (range) =>{
  let {startDate , endDate } = range;
  setSelectedRange(range);
  let formattedStart =  getDateInYYYYMMDD(startDate);
  let formattedEnd = getDateInYYYYMMDD(endDate);
  getAdmins({role : "admin" , fromDate : formattedStart  , toDate  : formattedEnd , page : currentPage , limit : itemsPerPage})
 }

  // const fetchAdminsWithDate = () => {
  //   const formattedStart = getDateInYYYYMMDD(selectedRange.startDate);
  //   const formattedEnd = getDateInYYYYMMDD(selectedRange.endDate);
  //   try{
  //   dispatch(getAllAdmins({ role: "admin", fromDate: formattedStart, toDate: formattedEnd }));
  // }catch(error){
  //     toast.error("No Data found")
  //   }
  // };

 const downloadTeamList = async (params) => {
  try {

    const csvContent =  await  dispatch(downloadTeam(params)).unwrap();
    downloadCsv(csvContent, "TeamList.csv")

  } catch (error) {
      toast.error("No Data found");
  }
};

 const DownloadData = () =>{
   let startDate = getDateInYYYYMMDD(selectedRange?.startDate)
   let endDate = getDateInYYYYMMDD(selectedRange?.endDate)
   downloadTeamList({role : "admin" , fromDate : startDate , toDate : endDate})
 }

 const searchProfile = async(data) =>{
  setSearchActive(true)
  const res = await dispatch(searchProfiles(data))
   .unwrap()
   .catch((error) => console.log(error));
}

const handleChange = (e)=>{
  if(e.target.value === ""){
    setSearchActive(false);
  }
  setSearchValue(e.target.value)
  
}



  const handlePageChange = (newPage) => {
    setCurrentPage(newPage); // Update the current page
    // getAdmins({
    //   role: "admin",
    //   page: newPage,
    //   limit: itemsPerPage, // Set the items per page if necessary
    // });
    applyFilters()
  };

  const getStartIndex = () => {
    return (currentPage - 1) * itemsPerPage;
  };

      const applyFilters = () => {
        const params = { role: "admin" , page : currentPage , limit : itemsPerPage };
    
        if (selectedRange) {
            params.fromDate = getDateInYYYYMMDD(selectedRange.startDate);
            params.toDate = getDateInYYYYMMDD(selectedRange.endDate);
             getAdmins(params)
          }
    
         if(searchValue) {
            params.searchTerms = searchValue;
            searchProfile(params);
        } else {
            getAdmins({role : "admin" , page : currentPage , limit : itemsPerPage});
        }
    };
    
    useEffect(() => {
        applyFilters();
    }, [currentPage , searchValue, selectedRange]);
    


const navigate = useNavigate();

const tbody = searchActive && searchData.length === 0 ? [] : (searchActive ? searchData : adminList);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={data?.pageTitle}>
              {data?.breadcrumb.map((item, index) => (
                <Item key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </Item>
              ))}
            </Breadcrumb>
          </CardLayout>
        </Col>
        {/* {data?.float.map((item, index) => (
          <Col xl={4} key={index}>
            <FloatCard
              variant={item.variant}
              digit={item.digit}
              title={item.title}
              // icon={item.icon}
            />
          </Col>
        ))} */}

     

        <Col xl={12}>
          <CardLayout>
            <div className = "d-flex justify-content-between mb-2">
            <CardHeader
              title="Details of Registered Team Members "
              // dotsMenu={data?.dotsMenu}
              // button={data?.button}
            />
            <div className="">
            <button className="blackButton" onClick={()=>navigate('/add-admin')}>
            <span className=""> <AddIcon /></span>
            Add admin
           </button>
            </div>
            </div>

         <div className="d-flex justify-content-between flex-nowrap pb-4">
              <div className="d-flex gap-2">
            {/* <span className="border rounded-xl  p-2 m-auto ">date and time</span>  */}
           <DatePicker onDateRangeSubmit  = {handleDateRange} />

             <div className="d-flex  cursor-pointer wrap-content" onClick={DownloadData}>
              
         <span className="px-1"><DownloadIcon /> </span>
         <span className="text-uppercase">Export</span>
      
            </div>
            </div>

           <Col xl = {5}>
           <div className="d-flex searchClass"   >
            <span className="px-1"><SearchIcon /> </span>
           <span className="search-input"><Search placeholder="Search by name/email"  value= {searchValue || ""} onChange = {handleChange}/> </span></div>
           
           </Col>
           </div>


            {/* <Row xs={1} sm={4} className="mb-4">
              {data?.filter.map((item, index) => (
                <Col key={index}>
                  <LabelField
                    type={item.type}
                    label={item.label}
                    option={item.option}
                    placeholder={item.placeholder}
                    labelDir="label-col"
                    fieldSize="w-100 h-sm"
                  />
                </Col>
              ))}
            </Row> */}
            <TeamTable
              thead={data?.table.thead}
              startIndex = {getStartIndex}
              // tbody={data?.table.tbody}
              tbody={searchData?.length > 0 ? tbody : tbody?.docs}
              toggleChange={toggleChangeStatus}
            />
            {tbody.totalDocs > 0 &&
             <Pagination
              totalItems={tbody?.totalDocs}
              itemsPerPage={tbody?.pagingCounter}
              currentPage={currentPage}
              limit = {tbody?.limit}
              onPageChange={handlePageChange}
            />
            }
            {/* <TeamTable
              thead={data?.table.thead}
              // tbody={searchData.length > 0  ? searchData : adminList}
              tbody = {tbody}
              toggleChange={toggleChangeStatus}
            />
            <Pagination /> */}
          </CardLayout>
        </Col>
      </Row>
      <ToastContainer />
    </PageLayout>
  );
}


