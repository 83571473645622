import React from "react";
import { Link } from "react-router-dom";
import { Text, Image } from "./elements";

export default function Logo({ src, alt, name, href, className }) {
  return (
    <>
      <Link to={href} className={`mc-logo ${className}`}>
        <Image src={src} alt={alt} />
      </Link>
    </>
  );
}
