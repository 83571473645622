import React, { useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import {
  Button,
  Image,
  Input,
  Text,
  Box,
  Icon,
  Anchor,
  Option,
  Heading,
} from "../elements";
import userInfo from "../../data/master/driverManagement.json";
import { useNavigate } from "react-router-dom";
import { DocumentIcon, ViewIcon , ToggleIcon , ActiveToggleIcon, ViewProfileIcon } from "../icons/icons";

export default function DriversTable({
  isVerifiedDriver,
  startIndex ,
  tbody,
  // filterData = [],
  toggle,
  pagingCounter,
}) {
  const [data, setData] = React.useState([]);
  const [userData, setUserData] = React.useState("");
  const [editModal, setEditModal] = React.useState(false);
  const [blockModal, setBlockModal] = React.useState(false);

  const navigate = useNavigate();

  const thead = [
    "image",
    "driver name",
    "documents",
    "email",
    "phone",
    "created at",
    "profile",
    // "action",
  ];

  const theadVerified = [
    "image",
    "driver name",
    // "gender",
    "email",
    "phone",
    "status",
    "created at",
    "profile",
    "action",
  ];

  


  React.useEffect(() => {
      setData(tbody);
  }, [tbody]);



  // console.log(data , "data")
  const handleDriverProfile = (data) => {
    // navigate("/driver-profile", { state: item });
    navigate("/driver-profile", { state: data  });
  };

  

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                <Text>uid</Text>
              </Box>
            </Th>
            {isVerifiedDriver === true
              ? theadVerified.map((item, index) => <Th key={index}>{item}</Th>)
              : thead.map((item, index) => <Th key={index}>{item}</Th>)}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
         {data?.length > 0 ? data?.map((item, index) => 
            
            <Tr key={index} onClick={() => handleDriverProfile(item)}>
              <Td title="id">
                <Box className="mc-table-check">
                  <Text>#{startIndex() + index + 1}</Text>
                </Box>
              </Td>
              <Td title={item?.profileUrl}>
                {item?.profileUrl && (
                   <span 
                   className="d-flex cursor-pointer" 
                   onClick={() => {
                     const newWindow = window.open();
                     if (newWindow) {
                        newWindow.document.write(`<img src="${item?.profileUrl}" alt="profile image" style="max-width: 100%; height: auto;" />`);
                       newWindow.document.title = "Profile Picture";
                     }
                   }}
                 >
                  <Image
                    src={item?.profileUrl}
                    alt={item?.alt}
                    width="40px"
                    height="40px"
                  />
                  </span>
                )}
              </Td>
              <Td title={item?.name}>
                <Box className="mc-table-profile">
                  
                  <Text className = "text-capitalize">{item?.name}</Text>
                </Box>
              </Td>
              
              {isVerifiedDriver === false && (
                <Td>
                 <Box className="mc-table-action   cursor-pointer">
                  {/* <div onClick={()=> navigate("/documents" , {state : item?._id})}> */}
                  <div
                  onClick={(event) => {
                   event.stopPropagation(); // Prevent the row click event
                   navigate("/documents", { state: item?._id });
                 }}
                >
                  <DocumentIcon />
                  </div> 
                  </Box>
                  </Td>
              )
              }
              {/* //  : (
              //   <Td title={item?.gender}>{item?.gender}</Td>
              // )} */}

              <Td title={item?.email}><Text className="text-capitalize">{item?.email ? item?.email : ""}</Text></Td>
              <Td title={item?.phone}>{item?.phone}</Td>
              {isVerifiedDriver === true ? (
                <Td title={item?.isActive}>
                  {item?.isActive && (
                    <Text className="activeTag">active</Text>
                  )}

                  {!item?.isActive && (
                    <Text className="inActiveTag">inactive</Text>
                  )}
                </Td>
              ) : null}
             

              <Td title={item?.createdAt}>
                {new Date(item?.createdAt).toLocaleDateString()}
              </Td>
              <Td title = {item?.profile}>
                <Box className="mc-table-action pe-auto align-items-center  cursor-pointer">
                 
                  <div  onClick={() => handleDriverProfile(item)}>
                    <ViewProfileIcon />
                  </div>
                 
                </Box>
              </Td>
              <Td title = {item.action}>
              <Box className="mc-table-action pe-auto align-items-center  cursor-pointer">
              {isVerifiedDriver === true ? (
                <div onClick = {async(event) => {
                     event.stopPropagation();
                     await toggle(item?.authId)
                    }}>
                  {item?.isActive ? <ActiveToggleIcon /> : <ToggleIcon />}
                 </div>
              )
                 : null }
                </Box>
              </Td>
            </Tr>
          
          )
          :
          <tr  className = "">
       <td colspan = {thead.length + 1} valign = "top" className = "text-center">
         No Data available in table
         </td>
        </tr>
          }
        </Tbody>
      </Table>

      <Modal
        show={editModal}
        onHide={() => setEditModal(false, setUserData(""))}
      >
        <Box className="mc-user-modal mt-3">
          {/* <Image src={userData.src} alt={userData?.alt} /> */}
          <Heading as="h4">{userData?.name}</Heading>
          <Text as="p">{userData?.email}</Text>
          
          <Form.Group className="form-group inline">
            <Form.Label>status</Form.Label>
            <Form.Select>
              {/* <Option>{userData?.status}</Option> */}
              <Option>Change Status</Option>
              {userInfo?.status?.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Form.Select>
          </Form.Group>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setEditModal(false)}
            >
              close popup
            </Button>
            <Button
              type="button"
              className="btn btn-success"
              onClick={() => setEditModal(false)}
            >
              save Changes
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>

      <Modal show={blockModal} onHide={() => setBlockModal(false)}>
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">Want to block this user's account?</Text>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setBlockModal(false)}
            >
              nop, close
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => setBlockModal(false)}
            >
              yes, block
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>
    </Box>
  );
}
