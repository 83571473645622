import React, { useState } from "react";
import { Item, Anchor } from "../../components/elements";
import { Breadcrumb , Pagination} from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import { CardHeader } from "../../components/cards";
import data from "../../data/master/feesManagement.json";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { changeLabel } from "../../slices/sidebarSlice";
import { VehiclePriceTable } from "../../components/tables";
import { useNavigate } from "react-router-dom";
import { AddIcon } from "../../components/icons/icons";
import {Button} from "../../components/elements";
import { getAllVehicleType, searchVehicleTypes } from "../../slices/driverManagementSlice";
import DatePicker from "../../components/elements/DatePicker";
import { getDateInYYYYMMDD } from "../../components/elements/DatePicker";
import { DownloadIcon } from "../../components/icons/icons";
import { SearchIcon } from "../../components/icons/icons";
import { Search } from "../../components/fields/search";
import { Col } from "react-bootstrap";
import { downloadVehicleTypeWithPrice } from "../../slices/driverManagementSlice";
import { downloadCsv } from "../../components/elements/DownloadCsv";
import { searchVehicleType } from "../../services/driverManagementService";

export default function FeesManagement() {
  const [currentPage , setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [searchActive , setSearchActive] = useState(false)
  const searchData = useSelector((state ) => state.driverManagement.vehicleSearch)
  const priceList  = useSelector((state) => state.driverManagement.vehicleTypeData);
  const [selectedRange , setSelectedRange] = React.useState({
    startDate : new Date(),
    endDate : new Date()
  })


  const getVehicleType = (params) =>{
    dispatch(getAllVehicleType(params))
    .unwrap()
    .catch((error) => console.log("Something went wrong"))
  } 

  const navigate = useNavigate();
  React.useEffect(() => {
    // dispatch(getNewRidePrice());
   getVehicleType({isActive : true , page : currentPage , limit : itemsPerPage});
    // dispatch(changeLabel("managesettings"));
    dispatch(changeLabel("vehiclesettings"));
  }, []);

  const downloadVehicleList = async(params) =>{
    try{
      const csvContent = await dispatch(downloadVehicleTypeWithPrice(params)).unwrap();
      downloadCsv(csvContent , "VehicleWithPrice.csv")
    }catch(error){
      toast.error("No Data found")
    }
  }


 const handleDateRange = (range) =>{
  let {startDate , endDate } = range;
  setSelectedRange(range);
  let formattedStart =  getDateInYYYYMMDD(startDate);
  let formattedEnd = getDateInYYYYMMDD(endDate);
  getVehicleType({isActive : true , fromDate : formattedStart  , toDate  : formattedEnd , page : currentPage , limit : itemsPerPage})
 }


 const DownloadData = () =>{
   let startDate = getDateInYYYYMMDD(selectedRange?.startDate)
   let endDate = getDateInYYYYMMDD(selectedRange?.endDate)
   downloadVehicleList({isActive : true , fromDate : startDate , toDate : endDate})
 }

 const search = async(data) =>{
  setSearchActive(true);
  const res = await dispatch(searchVehicleTypes(data))
  .unwrap()
  .catch((error)=> console.log(error));
 }

 const handlePageChange = (newPage) =>{
  setCurrentPage(newPage);
  applyFilters()
 }

 const handleChange = (e) =>{
  if(e.target.value === ""){
    setSearchActive(false);
  }
  setSearchValue(e.target.value)
 }

 const getStartIndex = () =>{
  return (currentPage - 1) * itemsPerPage
 }

 const applyFilters = () =>{
  const params = {isActive : true , page : currentPage , limit : itemsPerPage}

  if(selectedRange){
    params.fromDate = getDateInYYYYMMDD(selectedRange.startDate);
    params.toDate = getDateInYYYYMMDD(selectedRange.endDate);
    getVehicleType(params);
  }

  if(searchValue){
    params.searchTerms = searchValue;
    search(params);
  }else{
    getVehicleType({isActive : true , page : currentPage , limit : itemsPerPage})
  }
 }

 React.useEffect(() =>{
  applyFilters();
 },[currentPage , searchValue , selectedRange])

 const tbody = searchActive && searchData.length === 0 ? [] : (searchActive ? searchData : priceList)

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      <CardLayout>
        <div className = "d-flex justify-content-between mb-2 ">
        <CardHeader title="Details of the Rides' Fees as per Vehicle Type" />
        
        <div className=" ">
        <div className="blackButton" onClick={()=>navigate('/add-vehicle-price')}>
            <span className=""> <AddIcon /></span>
            Add Vehicle Type
           </div>
          </div>

          </div>

          <div className="d-flex justify-content-between flex-nowrap pb-4">
              <div className="d-flex gap-2">
            {/* <span className="border rounded-xl  p-2 m-auto ">date and time</span>  */}
           <DatePicker onDateRangeSubmit  = {handleDateRange} />
              <div className=" cursor-pointer d-flex wrap-content" onClick = {DownloadData}>
              
         <span className="px-1"><DownloadIcon /> </span>
         <span className="text-uppercase">Export</span>
      
            </div>
            </div>

           <Col xl = {5}>
           <div className="d-flex wrap-content"   >
            <span className="px-1"><SearchIcon /> </span>
           <span className="search-input"><Search placeholder="Search by vehicleType" value = {searchValue || ""} onChange={handleChange}/> </span></div>

           </Col>
           </div>
        <VehiclePriceTable  startIndex = {getStartIndex} tbody={searchData?.length > 0 ? tbody : tbody?.docs} />
        {tbody.totalDocs > 0 &&
        <Pagination 
          totalItems =  {tbody?.totalDocs}
          itemsPerPage = {tbody?.pagingCounter}
          currentPage = {currentPage}
          limit = {tbody?.limit}
          onPageChange = {handlePageChange}
        />
        }
      </CardLayout>
      <ToastContainer />
    </PageLayout>
  );
}
