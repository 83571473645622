import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import {
  Button,
  Image,
  Input,
  Text,
  Box,
  Icon,
  Anchor,
  Option,
  Heading,
} from "../elements";
import userInfo from "../../data/master/riderManagement.json";
import { useNavigate } from "react-router-dom";
import { ViewProfileIcon , ActiveToggleIcon , ToggleIcon } from "../icons/icons";


// { thead, tbody, filterData }
export default function PayoutsTable( {tbody , startIndex}) {
  const [data, setData] = useState([]);
  

  const navigate = useNavigate();

  const thead = [
    "payout ID", 
    "driver ID",
     "requested date", 
     " amount", 
     "payout status",
     "action"
    ]

  useEffect(() => {
    setData(tbody);
  }, [tbody]);

  
  const handleClick = () => {
    // navigate("/requested-payout"
        // , { state: item }
    // );
  };

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
             
                <Text>uid</Text>
              </Box>
            </Th>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {/* {data?.length > 0 ? data?.map((item, index) => ( */}
            <Tr key="" >
             <Td title="id">
                <Box className="mc-table-check">
                  <Text>1</Text>
                  {/* <Text>#{startIndex()  + index + 1}</Text> */}
                </Box>
              </Td>
              <Td title="">
                  
                  <Text>ABCD123</Text>
              </Td>
              <Td title="">ansvh3ej</Td>
              <Td title="">12/09/24</Td>
              <Td title="">$500</Td>
              {/* <Td title=""></Td> */}
              {/* <Td title="">{item?.paymentType}</Td> */}
              <Td title="">
                {/* {item?.paymentStatus && (
                  <Text className="activeTag">{item.paymentStatus}</Text>
                )} */}
                <Text className="activeTag">Requested</Text>

               
              </Td>


              <Td>
                <Box className="d-flex">
                  <div
                    className="blackButton"
                    // onClick={() => handleVerify(data?._id, "profile")}
                  >Paid</div>

                  <div
                    className="whiteButton"
                    // onClick={() => { setRejectModal(true); setRejectReason((prev)=>({...prev , profileId : data._id , docType : "profile"}))}}
                  >Not Paid </div>
                </Box>
              </Td>
              {/* <Td title={item?.createdAt}>{new Date(item?.createdAt).toLocaleDateString()}</Td> */}
              {/* <Td>
                <Box className="mc-table-action pe-auto align-items-center cursor-pointer">
                <div  
                onClick={() => handleClick()}
                >
                    <ViewProfileIcon />
                  </div>
                </Box>
                
              </Td> */}
              
              {/* <Td title = {item.action}>
              <Box className="mc-table-action pe-auto align-items-center  cursor-pointer">
                
                </Box> */}
              {/* </Td>  */}
            </Tr>
          {/* )) */}
          {/* :
          <tr  className = "">
          <td colspan = {thead.length + 1} valign = "top" className = "text-center">
            No Data available in table
            </td>
           </tr> */}
           {/* } */}
        </Tbody>
      </Table>

      {/* <Modal
        show={editModal}
        onHide={() => setEditModal(false, setUserData(""))}
      >
        <Box className="mc-user-modal">
          <Image src={userData?.src} alt={userData?.alt} />
          <Heading as="h4">{userData?.name}</Heading>
          <Text as="p">{userData?.email}</Text>
          <Form.Group className="form-group inline mb-4">
            <Form.Label>role</Form.Label>
            <Form.Select>
              <Option>{userData?.role ? userData?.role.text : ""}</Option>
              {userInfo?.role?.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="form-group inline">
            <Form.Label>status</Form.Label>
            <Form.Select>
              <Option>{userData?.status}</Option>
              {userInfo?.status?.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Form.Select>
          </Form.Group>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setEditModal(false)}
            >
              close popup
            </Button>
            <Button
              type="button"
              className="btn btn-success"
              onClick={() => setEditModal(false)}
            >
              save Changes
            </Button>
          </Modal.Footer>
        </Box>
      </Modal> */}

      {/* <Modal show={blockModal} onHide={() => setBlockModal(false)}>
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">Want to block this user's account?</Text>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setBlockModal(false)}
            >
              nop, close
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => setBlockModal(false)}
            >
              yes, block
            </Button>
          </Modal.Footer>
        </Box>
      </Modal> */}
    </Box>
  );
}
