import React, { useState, useEffect } from "react";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Text, Box, Button, Heading, Icon } from "../elements";
import { useDispatch } from "react-redux";
import { deleteCountryByName } from "../../slices/countryManagementSlice";
import { Modal, ToastHeader } from "react-bootstrap";


export default function CountriesTable({ thead, list, toast }) {
  const [data, setData] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const [deleteCountry, setDeleteCountry] = React.useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setData(list);
  }, [list]);

  const handleDeleteCountry = (name) => {
    dispatch(deleteCountryByName(name))
      .unwrap()
      .then((res) => toast.success(res))
      .catch((error) => toast.error("Something went wrong!"));

    setAlertModal(false);
  };

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                <Text>uid</Text>
              </Box>
            </Th>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        {data?.length > 0 ? (
          <Tbody className="mc-table-body even">
            {data?.map((item, index) => (
              <Tr key={index}>
                <Td title="id">
                  <Box className="mc-table-check">
                    <Text>#{index + 1}</Text>
                  </Box>
                </Td>
                <Td title={item.name}>{item.name}</Td>
                <Td title={item.isoCode}>{item.isoCode}</Td>
                <Td title={item.flag}>{item.flag}</Td>
                <Td title={item.phonecode}>{item.phoneCode}</Td>
                {/* <Td title={item.currency}>{item.currency}</Td> */}
                <Td>
                  <Button
                    title="Delete"
                    className="material-icons delete"
                    onClick={() => setAlertModal(true, setDeleteCountry(item))}
                  >
                    delete
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        ) : null}
      </Table>

      <Modal show={alertModal} onHide={() => setAlertModal(false)}>
        <Box className="mc-alert-modal mt-5">
          {/* <Icon type="new_releases" /> */}
          <Heading as="h3">are you sure!</Heading>
          <Text as="p">Want to delete this country?</Text>
          <Modal.Footer>
            <Box className="d-flex gap-2">
            <div
              // type="button"
              className="whiteButton"
              onClick={() => setAlertModal(false)}
            >
              close
            </div>
            <div
              type="button"
              className="blackButton"
              onClick={() => handleDeleteCountry(deleteCountry._id)}
            >
              delete
            </div>
            </Box>
          </Modal.Footer>
        </Box>
      </Modal>
    </Box>
  );
}
