import React from "react";
import { Item, Anchor } from "../../components/elements";
import { CardLayout, CardHeader } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/announcement.json";
import { Tab, Tabs } from "react-bootstrap";
import { AnnouncementTable } from "../../components/tables";
import { useDispatch, useSelector } from "react-redux";
import { getAllAnnouncement } from "../../slices/announcementSlice";
import { changeLabel } from "../../slices/sidebarSlice";
import { AddIcon } from "../../components/icons/icons";
import { useNavigate } from "react-router-dom";

export default function Announcement() {
  const [key, setKey] = React.useState("all");
const navigate = useNavigate()
  const dispatch = useDispatch();

  const announcements = useSelector(
    (state) => state.announcement.announcements
  );

  const getAnnouncements = () => {
    if (key === "all") {
      dispatch(getAllAnnouncement());
    } else if (key === "driver") {
      dispatch(getAllAnnouncement({ isForDriver: true }));
    } else {
      dispatch(getAllAnnouncement({ isForDriver: false }));
    }
  };

  React.useEffect(() => {
    getAnnouncements();
  }, [key]);

  React.useEffect(() => {
    dispatch(changeLabel(""));
  }, []);

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      <CardLayout>
      <div className = "d-flex justify-content-between mb-2">
            <CardHeader
              title={data?.cardTitle}
            /> 

      <div className=" ">
       <button className="blackButton" onClick={()=>navigate('/add-announcement')}>
            <span className="px-1"> <AddIcon /></span>
            Add Announcement
           </button>
        </div>

          
         </div>

        <Tabs
          defaultActiveKey="all"
          id="mc"
          className="mc-tabs"
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="all" title="all" className="mc-tabpane all">
            <AnnouncementTable tbody={announcements} />
          </Tab>
          <Tab eventKey="driver" title="driver" className="mc-tabpane driver">
            <AnnouncementTable tbody={announcements} />
          </Tab>
          <Tab eventKey="rider" title="rider" className="mc-tabpane rider">
            <AnnouncementTable tbody={announcements} />
          </Tab>
        </Tabs>
      </CardLayout>
    </PageLayout>
  );
}
