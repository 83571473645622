import React from "react";
import { Row, Col } from "react-bootstrap";
import LabelField from "../../../components/fields/LabelField";
import { Text } from "../../../components/elements";
import Multipleselect from "../../../components/Multipleselect";
import { useDispatch, useSelector } from "react-redux";

import {
  addDriverDetail,
  getAllLanguage,
  getAllState,
  getCountyByState,
  emptyCounty,
  uploadImages,
  manageMultiselect,
  managePhoneCode,
  getStateFromId,
  getCountyFromId,
  getCompanyFromId,
  getAllCompany
} from "../../../slices/driverManagementSlice";
import { getAllCountry } from "../../../slices/countryManagementSlice";
import Fileupload from "./Uploader";
let languageId = [];
export default function DriverDetail({ toast }) {
  let formData = new FormData();

  const dispatch = useDispatch();

  const countryList = useSelector(
    (state) => state.countryManagement.countryList
  );

  const { driverData, language, multiselect, phoneCode , company } =
    useSelector((state) => state.driverManagement);

    const [code, setCode] = React.useState(countryList?.[0]?.phoneCode || "");
 
  const [driverPhone , setDriverPhone] = React.useState({
    phone : "",
    secondaryPhone : ""
  })
  const handleChange = (e) => {
    const { name, value } = e.target;

    dispatch(addDriverDetail({ name, value }));
  };

  const handleGender = (selectedList, selectedItem) => {
    dispatch(manageMultiselect({ key: "gender", value: selectedList }));

    dispatch(
      addDriverDetail({
        name: "gender",
        value: selectedItem.gender.toLowerCase(),
      })
    );
  };

  const handleRemoveGender = (selectedList) => {
    dispatch(manageMultiselect({ key: "gender", value: selectedList }));

    dispatch(
      addDriverDetail({
        name: "gender",
        value: "",
      })
    );
  };

  const handleLanguageKnown = (selectedList) => {
    dispatch(manageMultiselect({ key: "language", value: selectedList }));

    selectedList.map((item) => {
      if (!languageId.includes(item._id)) {
        languageId.push(item._id);
      }
    });

    dispatch(addDriverDetail({ name: "language", value: languageId }));
  };

  const handleRemoveLanguage = (selectedList, removedItem) => {
    dispatch(manageMultiselect({ key: "language", value: selectedList }));

    languageId = languageId.filter((id) => id !== removedItem._id);

    dispatch(addDriverDetail({ name: "language", value: languageId }));
  };

  const handleState = (selectedList, selectedItem) => {
    dispatch(manageMultiselect({ key: "state", value: selectedList }));

    dispatch(addDriverDetail({ name: "driverState", value: selectedItem._id }));
    dispatch(getCountyByState(selectedItem._id))
      .unwrap()
      .catch((error) =>
        console.log("Something went wrong in getting counties!")
      );

    dispatch(getStateFromId(selectedItem._id));
  };

  const handleRemoveState = (selectedList) => {
    dispatch(manageMultiselect({ key: "state", value: selectedList }));

    dispatch(addDriverDetail({ name: "driverState", value: "" }));
    dispatch(emptyCounty([]));
  };

  const handleCounty = (selectedList, selectedItem) => {
    dispatch(manageMultiselect({ key: "county", value: selectedList }));

    dispatch(
      addDriverDetail({ name: "driverCounty", value: selectedItem._id })
    );

    dispatch(getCountyFromId(selectedItem._id));
  };

  const handleRemoveCounty = (selectedList) => {
    dispatch(manageMultiselect({ key: "county", value: selectedList }));

    dispatch(addDriverDetail({ name: "driverCounty", value: "" }));
  };

  const handleProfileImage = (e) => {
    const { name } = e.target;
    if (e.target.files.length > 0) {
      formData.set("img", e.target.files[0]);
    }

    dispatch(uploadImages(formData))
      .unwrap()
      .then((url) => {
        dispatch(addDriverDetail({ name, value: url.result[0] }));
      })
      .catch((error) => {
        console.log(error, "upload img err...");
        if (error.message === "Rejected")
          toast.error("Issue in uploading Image. Please try Again");
      });
  };

  const handlePhone = (e) => {
    dispatch(managePhoneCode(e.target.value));
  };

  // const handlePhoneChange = (e) => {
  //   let inputValue = e.target.value;
    
  //   if (inputValue.startsWith(code)) {
  //     inputValue = inputValue.slice(code.length); 
  //   }
    
  //   setDriverPhone((prev) => ({
  //     ...prev,
  //     phone: code + inputValue, 
  //   }));

  //   dispatch(
  //     addDriverDetail({
  //       name: "phone",
  //       value: code +  inputValue,
  //     })
  //   );
  // };
  
  
  // const handleCodeChange = (e) => {
  //   const newCode = e.target.value;
  
  //   setDriverPhone((prev) => ({
  //     ...prev,
  //     phone: prev.phone.startsWith(code) 
  //       ? newCode + "" 
  //       : newCode + "", 
  //   }));
  

  //   setCode(newCode);
  // };

  const handlePhoneChange = (e, field) => {
    let inputValue = e.target.value;
    
    if (inputValue.startsWith(code)) {
      inputValue = inputValue.slice(code.length); 
    }
    
    setDriverPhone((prev) => ({
      ...prev,
      [field]: code + inputValue, 
    }));

    dispatch(
      addDriverDetail({
        name: field,
        value: code + inputValue,
      })
    );
  };
  
const handleCodeChange = (e, field) => {
    const newCode = e.target.value;
  
    setDriverPhone((prev) => ({
      ...prev,
      [field]: prev[field].startsWith(code) 
        ? newCode + "" 
        : newCode + "", 
    }));

    setCode(newCode);
  };

  

  const handleCompany = (selectedList , selectedItem) => {
    console.log(selectedItem._id)
    dispatch(manageMultiselect({ key: "companyId", value: selectedList }));

    dispatch(
      addDriverDetail({ name: "companyId", value: selectedItem._id })
    );
  
    // dispatch(getCompanyFromId(selectedItem._id))
    // .unwrap()
    // .catch((error) =>
    //   console.log("Something went wrong in getting counties!")
    // );;
  };

  const handleRemoveCompany = (selectedList) => {
    dispatch(manageMultiselect({ key: "companyId", value: selectedList }));

    dispatch(
      addDriverDetail({
        name: "companyId",
        value: "",
      })
    );
  };

let autoComplete;
const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState == "loaded" || script.readyState == "completed") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

// export default function App() {
  const [query, setQuery] = React.useState("");
  const autoCompleteRef = React.useRef(null);

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    const chicagoBounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(41.644335, -87.940267), 
      new window.google.maps.LatLng(42.023131, -87.523661)  
    );

    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        componentRestrictions : {country : ["IN" , "US"]}
    //   bounds: chicagoBounds,
    //  componentRestrictions: { country: "us" },
    //  fields: ["address_components", "geometry", "icon", "name"],
    // strictBounds: false,
      }
    );
    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();
    const query = addressObject.name;
    updateQuery(query);
    console.log( addressObject );


    let state = "";
    let county = "";
    
    addressObject.address_components?.forEach(component => {
        if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
        }
        if (component.types.includes("administrative_area_level_2")) {
            county = component.long_name;
        }
    });
    
    // console.log("State:", state);  
    // console.log("County:", county);
    // console.log(addressObject.place_id)  
    // console.log(addressObject.formatted_address, "address")
    dispatch(addDriverDetail({ name: "driverState", value: state }));
    dispatch(addDriverDetail({ name: "driverCounty", value: county }));
    dispatch(addDriverDetail({name : "placeId" , value : addressObject?.place_id}))
    dispatch(addDriverDetail({name : "address" , value : addressObject?.name}))

 // console.log(addressObject, "address");
    const latLng = {
      lat: addressObject?.geometry?.location?.lat(),
      lng: addressObject?.geometry?.location?.lng(),
    };

    console.log(latLng);

    if(county === "") {
      // const script = document.createElement("script");
      if (window.google && window.google.maps) {
        const geocoder = new window.google.maps.Geocoder();

        const geocodeLatLng = (latLng) => {
          geocoder.geocode({ location: latLng })
            .then((response) => {
              if (response.results[0]) {
                response?.results[0]?.address_components?.forEach((component) => {
                  if (component.types.includes("administrative_area_level_2")) {
                    county = component.long_name;
                  }
                });
                console.log(county);
                dispatch(addDriverDetail({ name: "driverCounty", value: county }));
              } else {
                console.log("No results found");
              }
            })
            .catch((e) => console.log("Geocoder failed due to: " + e));
        };

        geocodeLatLng(latLng);
      } else {
        console.log("Google Maps API not loaded");
      }
    }
  };

 
  React.useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?&key=AIzaSyBkOfRs10Kr8r83VYCwkQld9XzM--mJG7U&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  React.useEffect(() => {
    if (driverData.phone) {
      setDriverPhone((prev) => ({
        ...prev,
        phone: "",
      }));

      dispatch(
        addDriverDetail({
          name: "phone",
          value: "",
        })
      );
    }

    if(driverData.secondaryPhone) {
      setDriverPhone((prev) =>({
        ...prev,
        secondaryPhone : "",
      }));

      dispatch(
        addDriverDetail({
          name : "secondaryPhone",
          value : "",
        })
      )
    }

  }, [code]);

  React.useEffect(() =>{
  console.log(driverPhone)
},[driverPhone])
 
  React.useEffect(() => {
    dispatch(getAllCountry())
      .unwrap()
      .then((countries) =>{
        setCode(countries?.[0]?.phoneCode  || "" )
      })
      .catch(() => toast.error("Something unwanted occurred!"));

    dispatch(getAllLanguage({ isActive: true }))
      .unwrap()
      .catch((error) => toast.error("Something unwanted occurred!"));

      dispatch(getAllCompany({isActive : true}))
      .unwrap()
      .catch((error) => toast.error("Something unwanted occured!"));

    // dispatch(getAllState({ isActive: true }))
    //   .unwrap()
    //   .catch((error) => toast.error("Something unwanted occurred!"));
  }, []);




  

  return (
    <div className="container"> 
    <div className="row">
      <div className = "col-md-3">
        <Text >Driver Name</Text>
      {/* </Col> */}
      {/* <Col xl={8}> */}
        <LabelField
          placeholder = "Type driver's name"
          type="text"
          name="name"
          fieldSize={`w-xl h-md`}
          value={driverData?.name}
          onChange={handleChange}
        />
      </div>

      <div className = "col-md-3">
        <Text>Phone</Text>
     
      <div className ="d-flex">
        <select
          className="phoneCode"
          // value={phoneCode}
          // onChange={handlePhone}
          value = {code}
          onChange = {(e) => handleCodeChange(e,"phone")}
        >
          {countryList?.map((country, index) => (
            <option key={index} value={country?.phoneCode}>
              {country?.phoneCode}
            </option>
          ))}
        </select>
      

        <input
          type="tel"
          name="phone"
          maxLength="10"
          pattern="[1-9]{1}[0-9]{9}"
          className="phoneInput"
          placeholder = "Type driver's mobile no."
          value={driverData?.phone.slice(code.length)}
          onChange={(e) => handlePhoneChange(e , "phone")}
          onBlur={(e) => {
            if (e.target.validationMessage)
              toast.warn("Please enter a valid number!");
            else return;
          }}
        />
        </div>
      </div>

      {/* <Col xl={4}> */}
      <div className = "col-md-3">
        <Text>Secondary Phone</Text>
     
      {/* </Col> */}

      <div className ="d-flex">
        <select
          className="phoneCode"
          value={code}
          onChange={(e) => handleCodeChange(e,"secondaryPhone")}
        >
          {countryList?.map((country, index) => (
            <option key={index} value={country?.phoneCode}>
              {country?.phoneCode}
            </option>
          ))}
        </select>

        <input
          type="tel"
          name="secondaryPhone"
          maxLength="10"
          pattern="[1-9]{1}[0-9]{9}"
          className="phoneInput"
          placeholder="Type driver's mobile no."
          value={driverData?.secondaryPhone.slice(code.length)}
          onChange={(e) => handlePhoneChange(e,"secondaryPhone")}
          onBlur={(e) => {
            if (e.target.validationMessage)
              toast.warn("Please enter a valid number!");
            else return;
          }}
        />
        </div>
     </div>

     <div className = "col-md-3">
        <Text>Email</Text>
      
      
        <LabelField
          type="text"
          name="email"
          placeholder = "Type driver's email address"
          fieldSize={`w-xl h-md`}
          value={driverData?.email}
          onChange={handleChange}
        />
       </div>

      </div>


  
  <div className="row mt-3" >  
        <div className = "col-md-3">
           Gender
        
         <Multipleselect
           options={[
             { gender: "Male", id: 1 },
             { gender: "Female", id: 2 },
             { gender: "Others", id: 3 },
           ]}
             displayValue="gender"
           placeholder="Choose Gender"
           onSelect={handleGender}
           onRemove={handleRemoveGender}
           selectionLimit="1"
           width="auto"
           selectedValues={multiselect["gender"]}
         />
      </div>

       <div className="col-md-3">Language Known
      
         <Multipleselect
          options={language}
          displayValue="label"
          placeholder="Choose Language"
          onSelect={handleLanguageKnown}
          onRemove={handleRemoveLanguage}
          width="auto"
          selectedValues={multiselect["language"]}
        />
      </div>

      <div className="col-md-3">Company Name
      
      <Multipleselect
              options={company}
              displayValue="label"
              placeholder="Choose Company"
              onSelect={handleCompany}
              onRemove={handleRemoveCompany}
              selectionLimit="1"
              width="auto"
              selectedValues={multiselect["companyId"]}
            />
   </div>

      <div className="col-md-3">Address
      
      <LabelField
          type="text"
          name="address"
          fieldSize={`w-xl h-md`}
          placeholder = "Type driver's address"
          ref={autoCompleteRef}
          onChange={(e) => setQuery(e.target.value)}
          value={query ? query : driverData?.address}
        />
      </div>

  {driverData.address && (
    <>
      <div className = "col-md-3 mt-10">Selected State
     
      <LabelField
          type="text"
          name="state"
          fieldSize={`w-xl h-md`}
          // ref={autoCompleteRef}
          // onChange={(e) => setQuery(e.target.value)}
          value={driverData?.driverState}
        />
      </div>
      </>
   )}
    
      {driverData?.driverState && (
        <div className="col-md-3 mt-10">
          <label>County you drive in</label>
          <LabelField
            type="text"
            name="county"
            fieldSize={`w-xl h-md`}
            value={driverData?.driverCounty || "empty"}
          />
        </div>
      )}  
 </div>

     <div className="row mt-3">
       <div className="col-md-6">
        <Text>Profile Picture</Text>
        {/* <Fileupload /> */}
       
      
        <LabelField
          type="file"
          name="profileUrl"
          fieldSize={`w-xl h-md`}
          onChange={handleProfileImage}
        />

         {driverData?.profileUrl && (
          <img
            src={driverData?.profileUrl}
            alt=""
            height="100px"
            width="100px"
          />
        )}
     </div>
     </div>
       {/* <ToastContainer /> */}
   
    </div>
  );
}
