import { configureStore } from "@reduxjs/toolkit";
import { ReduxApi } from "./utils/ReduxApi";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  driverManagementSlice,
  countryManagementSlice,
  rideManagementSlice,
  riderManagementSlice,
  teamManagementSlice,
  announcementSlice,
  manageSettingsSlice,
  authSlice,
  sidebarSlice,
  paymentSlice
} from "./slices";

export const store = configureStore({
  reducer: {
    [ReduxApi.reducerPath]: ReduxApi.reducer,
    driverManagement: driverManagementSlice,
    countryManagement: countryManagementSlice,
    rideManagement: rideManagementSlice,
    riderManagement: riderManagementSlice,
    teamManagement: teamManagementSlice,
    announcement: announcementSlice,
    manageSettings: manageSettingsSlice,
    auth: authSlice,
    sidebar: sidebarSlice,
    payment : paymentSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ReduxApi.middleware),
});

setupListeners(store.dispatch);
