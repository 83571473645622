import React from "react";
import { Row, Col } from "react-bootstrap";
import { Anchor, Item } from "../../components/elements";
import { CardLayout, CardHeader } from "../../components/cards";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/documentsDetail.json";
import { DocumentsTable } from "../../components/tables";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDriverProfileById } from "../../slices/driverManagementSlice";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";


export default function DocumentsDetail() {
  const location = useLocation();
  const id = location.state;

  const dispatch = useDispatch();
  const  driverProfile  = useSelector((state) => state.driverManagement);
  const [driverDocData , setDriverDocData] = React.useState({})

  React.useEffect(() => {
    dispatch(getDriverProfileById(id))
      .unwrap().then(response => setDriverDocData(response))
      .catch((error) => console.log("Something went wrong!"));
  }, []);

  console.log(driverDocData, "doc profile....");
  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={data?.pageTitle}>
              {data?.breadcrumb.map((item, index) => (
                <Item key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </Item>
              ))}
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <CardHeader title="Verify Documents" />

            <DocumentsTable tbody={driverDocData} toast = {toast} />
          </CardLayout>

        </Col>
      </Row>
      <ToastContainer />
    </PageLayout>
  );
}
