import React , {useEffect} from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import {
  List,
  Item,
  Icon,
  Text,
  Box,
  Anchor,
  Button,
  Heading,
} from "../../components/elements";
import {
  Breadcrumb,
  RoundAvatar,
  DivideTitle,
  DuelText,
} from "../../components";
import {
  CardLayout,
  CardHeader,
  FloatCard,
  AccordianCard,
} from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/document.json";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { EarningIcon, VerifiedIcon  , CarIcon} from "../../components/icons/icons";
import { RatingBar } from "../../components/elements/RatingBar";
import { riderProfile } from "../../slices/riderManagementSlice";
import { changeLabel } from "../../slices/sidebarSlice";

export default function DriverProfile() {
  const location = useLocation();
  const id = location.state;

  const [editModal, setEditModal] = React.useState(false);
  const [reason, setReason] = React.useState({});
  const dispatch = useDispatch();

 

  const driverProfile = useSelector((state) => state.riderManagement.profile)


  const profileData = (id) =>{
    dispatch(riderProfile(id))
    .unwrap()
    .catch((error) => toast.error(error))
  }

  useEffect(() => {
    profileData(id?._id)
    dispatch(changeLabel(""))
  },[]) 

  


  return (

    <>
      <div className="d-flex justify-content-between gap-3">
        <Col xl={4}>
          <CardLayout>
            <Box className="mc-user-group">
              <Box className="mc-user-profile d-flex justify-content-center">
                <RoundAvatar
                  src={
                    driverProfile?.profile?.profileUrl
                      ? driverProfile?.profile?.profileUrl
                      : data?.profile.src
                  }
                  alt={data?.profile.alt}
                  size={data?.profile.size}
                />
              </Box>
              <Box className="d-flex justify-content-center gap-1">
                <DuelText title={driverProfile?.profile?.name} size={data?.profile.size} />
                <Box>
                  {driverProfile?.profile?.isVerifiedDriver && <VerifiedIcon />}
                </Box>
              </Box>
              <Box className="d-flex justify-content-center">
                <RatingBar stars={driverProfile?.profile?.averageRating} />
                {/* <RatingBar stars = {3.5} /> */}
              </Box>

              <Box className="pt-4">
                <List className="mc-user-metalist">
                  <Item className="d-flex justify-content-between">
                    <Text>Mobile No.</Text>
                    <Text as="span">{driverProfile?.profile?.phone}</Text>
                  </Item>
                  <Item className="d-flex justify-content-between">
                    <Text>Email</Text>
                    <Text className = "text-capitalize" as="span">{driverProfile?.profile?.email}</Text>
                  </Item>
                  {driverProfile?.profile?.gender &&
                  <Item className="d-flex justify-content-between">
                    <Text>Gender</Text>
                    <Text className = "text-capitalize"  as="span">{driverProfile?.profile?.gender}</Text>
                  </Item>
                   }
                  <Item className="d-flex justify-content-between">
                    <Text>Company</Text>
                    <Text  className = "text-capitalize"  as="span">{driverProfile?.profile?.company || "-"}</Text>
                  </Item>
                  <Item className="d-flex justify-content-between">
                    <Text>County</Text>
                    <Text  className = "text-capitalize"  as="span">{driverProfile?.profile?.driverCounty}</Text>
                  </Item>
                  <Item className="d-flex justify-content-between">
                    <Text>State</Text>
                    <Text  className = "text-capitalize"  as="span">{driverProfile?.profile?.driverState}</Text>
                  </Item>
                </List>
              </Box>
            </Box>
          </CardLayout>

          <CardLayout className="mt-2">
            <CardHeader title="Overall Statistics" />
            <Box className="mc-card topCards d-flex align-items-center justify-content-between rounded p-4 mb-2">
              <Box>
                <Heading>{driverProfile?.countData}</Heading>
                <Text>Rides</Text>
              </Box>
              <Box className="border bg-white rounded p-2">
                <CarIcon />
              </Box>
            </Box>
            <Box className="mc-card topCards d-flex align-items-center justify-content-between rounded p-4">
              <Box>
                <Heading className="text-2xl font-[600]">${(driverProfile?.totalEarnings)}</Heading>
                <Text>Earnings</Text>
              </Box>
              <Box className="border bg-white rounded p-2">
                <EarningIcon />
              </Box>
            </Box>
          </CardLayout>
        </Col>

        <Col xl={8}>
          <CardLayout>
            <CardHeader title="Documents" />
            {driverProfile?.profile?.documentId?.length > 0 ? (
              <Box className="mb-4">
                <AccordianCard data={data.documents} profile={driverProfile} />
              </Box>
            ) : (
              <h4 className="text-danger text-center">No Verified Documents yet!!</h4>
            )}
          </CardLayout>
        </Col>
      </div>
    </>
  );
}


