import React, { useState, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import { defaultStaticRanges } from "./DeafultRange";
import { format } from "date-fns";


import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

// import "./Datepicker.css";

import PropTypes from "prop-types";
// import Button from "@/components/atoms/button";

const DateRangeSelector = ({
  ranges,
  handleSelected,
  onChange,
  onSubmit,
  applyFilter,
  ...rest
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [show, setShow] = useState(false);

  // Formating the date range  for start date and end date
  function formatDateDisplay(date, defaultText) {
    if (!date) return defaultText;
    return format(date, "dd MMM yyyy");
  }

  const handleSelect = (ranges) => {
    setSelectedDateRange(ranges.selection);
    console.log(ranges.selection);
  };

  const onClickDone = () => {
    onSubmit(selectedDateRange);
    applyFilter();
    //  setShow(true);
  };

  // const onClickClear = () => {
  //   setSelectedDateRange({
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: "selection",
  //   });
  //   // setShow(false);
  //   // applyFilter()
  // };

const onClickClear = () => {
  const today = new Date();
  setSelectedDateRange({
    startDate: today,
    endDate: today,
    key: "selection",
  });
  onSubmit({
    startDate: today,
    endDate: today,
  });
  setShow(false);
};
  return (
    <React.Fragment>
      <div className="  d-inline-block ">
        <DateRangePicker
         
          onChange={handleSelect}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={[selectedDateRange]}
          maxDate={new Date()}
          direction="horizontal"
          rangeColors={["#FBBF24"]}
          />

        <div className=" buttonDirection">
          <button
            className="blackButton"
            onClick={onClickDone}
          >
            Apply
          </button>
          <button
             className="whiteButton"
            onClick={onClickClear}
          >
            Clear
          </button>
        </div>
      </div>

      {show && (
        <div className=" h-100 mt-3 alert alert-transparent">
          <p className="my-auto d-inline">
            Start Date : {formatDateDisplay(selectedDateRange.startDate)}
            {" | "}
            End Date : {formatDateDisplay(selectedDateRange.endDate)}
          </p>
          <button
            className="mb-1 btn btn-transparent text-danger"
            onClick={() => setShow(false)}
            variant="outline-success"
          >
            {" "}
            Close
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

DateRangeSelector.defaultProps = {
  ranges: defaultStaticRanges,
};

DateRangeSelector.propTypes = {
  /**
   * On Submit
   */
  onSubmit: PropTypes.func,
};

export default DateRangeSelector;

