import React from "react";
import MenuItem from "./MenuItem";
import { List, Menu, Heading } from "../elements";
import { DashBoardIcon } from "../icons/icons";

export default function MultipleMenu({ data }) {
  const role = localStorage.getItem("role");
  const userType = localStorage.getItem("permissions");
  


  return (
    <>
      {data?.map((item, index) => (
        <Menu key={index} className="mc-sidebar-menu">
          <Heading as="h5" className="mc-sidebar-menu-title">
            {item.title}
          </Heading>
          <List className="mc-sidebar-menu-list">
            <MenuItem
              item={{
                icon: "dashboard",
                text: "dashboard",
                // href: "/dashboard",
                href: "/home",
                label: "dashboard",
                // url: ["/dashboard"],
                url: ["/home"],
              }}
            />
            {item.menu.map((item, index) => {
              if (role === "super_admin") {
                return <MenuItem key={index} item={item} />;
              } else if (userType?.includes(item.label) && role === "admin") {
                return <MenuItem key={index} item={item} />;
              }
              return null;
            })}
          </List>
        </Menu>
      ))}
    </>
  );
}
