import React from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Text, Item, Anchor, Button } from "../../components/elements";
import { LabelField } from "../../components/fields";
import { Breadcrumb, DivideTitle } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/languages.json";
import { LanguageTable } from "../../components/tables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CardHeader } from "../../components/cards";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewLanguage,
  toggleChangeLanguage,
} from "../../slices/manageSettingsSlice";
import { getAllLanguage } from "../../slices/driverManagementSlice";
import { changeLabel } from "../../slices/sidebarSlice";
import { AddIcon } from "../../components/icons/icons";

export default function AddLanguage() {
  const [language, setLanguage] = React.useState("");

  const dispatch = useDispatch();

  const lang = useSelector((state) => state.driverManagement.language);

  const handleChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleClick = async () => {
    console.log(language, "lang...");
    if (language) {
      dispatch(addNewLanguage({ label: language }))
        .unwrap()
        .then(() => toast.success("Language added successfully"))
        .catch((error) => toast.error("Something went wrong!"));
    } else {
      toast.warning("Add a language");
    }
    setLanguage("");
  };

  React.useEffect(() => {
    dispatch(getAllLanguage())
      .unwrap()
      .catch((error) => toast.error("Something went wrong!"));

    dispatch(changeLabel("managesettings"));
  }, []);

  const toggleChange = (id) => {
    dispatch(toggleChangeLanguage(id));
  };

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>

      <CardLayout className="p-sm-5">
        <Box className="mb-5">
          <DivideTitle title="Add Languages" className="mb-5" />
             <div className="d-flex gap-2">
                  <LabelField
                    type="text"
                    fieldSize={`w-xl h-md`}
                    placeholder = "Type language"
                    value={language}
                    onChange={(e) => handleChange(e)}
                  />
               

               <div className="blackButton" onClick= {() => handleClick() } >
            <span className="px-1"> <AddIcon /></span>
          Add Language
              </div>
                </div>
        </Box>
      </CardLayout>

      <CardLayout className={`mt-4`}>
        <CardHeader title="added languages" />
        <LanguageTable
          languages={lang}
          toggleChange={toggleChange}
          toast={toast}
        />
      </CardLayout>
      <ToastContainer />
    </PageLayout>
  );
}
