import React from "react";
import { Row, Col } from "react-bootstrap";
import LabelField from "../../../components/fields/LabelField";
import { Text, Box } from "../../../components/elements";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { uploadImages } from "../../../slices/driverManagementSlice";

export default function BankDetail({ bankDocs, setDocData, img }) {
  let formData = new FormData();

  const dispatch = useDispatch();

  const handleDocumentChange = (e, type) => {
    console.log(e.target.value , "vakue added")
    if (type === "text") {
      const { name, value } = e.target;
     
      setDocData((prev) => ({
        ...prev,
        bank: { ...prev["bank"], [name]: value, docType: "bank" },
      }));
    } else if (type === "file") {

      if (e.target.files.length > 0) {
        for (let i = 0; i < e.target.files.length; i++) {
          formData.append("img", e.target.files[i]);
        }
      }

      dispatch(uploadImages(formData))
        .unwrap()
        .then((url) => {
          if (url.result !== undefined) {
            img.current.push(url.result[0]);
          } else {
            img.current.pop();
          }

          setDocData((prev) => ({
            ...prev,
            bank: {
              ...prev["bank"],
              docImg: url.result,
              docType: "bank",
            },
          }));
        })
        .catch((error) => {
          if (error.message === "Rejected")
            toast.error("Something unwanted occured");
        });
    }
  };

  return (
    <div className="container">
    <div className="row">
      <div className = "col-md-3">
        <Text>First Name</Text>
      
      
        <LabelField
          type="text"
          name="firstName"
          fieldSize={`w-xl h-md`}
          placeholder = "Type driver's first name"
          // value={bankDocs?.firstname}
          value={bankDocs?.bank?.firstName}
          onChange={(e) => handleDocumentChange(e, "text")}
        />
      </div>

      <div className = "col-md-3">
        <Text>Last Name</Text>
      
      
        <LabelField
          type="text"
          name="lastName"
          fieldSize={`w-xl h-md`}
          placeholder = "Type driver's last name"
          // value={bankDocs?.lastname}
          value={bankDocs?.bank?.lastName}
          onChange={(e) => handleDocumentChange(e, "text")}
        />
      </div>
      </div>

      <div className="row mt-3">

      <div className = "col-md-3">
        <Text>Account Number</Text>
      
        <LabelField
          type="text"
          name="documentNumber"
          fieldSize={`w-xl h-md`}
          placeholder = "Type driver's account no."
          // value={bankDocs?.documentNumber}
          value={bankDocs?.bank?.documentNumber}
          onChange={(e) => handleDocumentChange(e, "text")}
        />
      </div>

      <div className = "col-md-3">
        <Text>Routing Number</Text>
    
        <LabelField
          type="text"
          name="routingNumber"
          fieldSize={`w-xl h-md`}
          placeholder = "Type driver's routing no."
          // value={bankDocs?.swiftCode}
          value={bankDocs?.bank?.routingNumber}
          onChange={(e) => handleDocumentChange(e, "text")}
        />
      </div>
      </div>

      <div className = "row mt-3 ">

      <div className = "col-md-6">
        <Text>Cancelled Cheque</Text>
     
        <LabelField
          type="file"
          // name="cheque"
          name="front"
          multiple
          fieldSize={`w-xl h-md`}
          onChange={(e) => handleDocumentChange(e, "file")}
        />

        <Box className="d-flex gap-2">
          {bankDocs?.bank?.docImg?.map((url, i) => (
            <img key={i} src={url} alt="" height="100px" width="100px" />
          ))}
        </Box>
      </div>

      </div>
      {/* <ToastContainer /> */}
    </div>
    
  );
}



