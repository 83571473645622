import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRiders,
  toggleChangeRiders,
} from "../services/riderManagementService";
import { getRiderProfile } from "../services/riderManagementService";
export const getAllRiders = createAsyncThunk(
  "manage_riders/getAllRiders",
  async (params, { rejectWithValue }) => {
    try {
      const res = await getRiders(params);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const toggleChange = createAsyncThunk(
  "manage_riders/toggleChange",
  async ({ id, params }, { dispatch, rejectWithValue }) => {
    try {
      const res = await toggleChangeRiders(id);
      if (res.status === 200) {
        await dispatch(getAllRiders(params));
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const riderProfile = createAsyncThunk(
  "manage_riders/riderProfile" ,
  async(profileId, {rejectWithValue}) =>{
    try{
      const res = await getRiderProfile(profileId);
      if (res.status == 200) {
        return res.data;
      }else throw res?.error
    }catch(error){
      return rejectWithValue(error);
    }
  }
)



const riderManagement = createSlice({
  name: "manage_riders",
  initialState: {
    // ridersList: {},
    ridersList: [],
    profile : {}
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRiders.fulfilled, (state, action) => {
      state.ridersList = action.payload;
    });
    builder.addCase(riderProfile.fulfilled , (state , action) =>{
        state.profile = action.payload
    })
  },
});

export default riderManagement.reducer;
