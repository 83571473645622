import React from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Text, Item, Anchor, Button } from "../../components/elements";
import { LabelField } from "../../components/fields";
import { Breadcrumb, DivideTitle } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/settings.json";
import { useDispatch } from "react-redux";
import { changeLabel } from "../../slices/sidebarSlice";

export default function ApiCredentials() {
  const [apiCredentials, setApiCredentials] = React.useState({});
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setApiCredentials((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleClick = () => {
    console.log(apiCredentials, "api");
  };

  React.useEffect(() => {
    dispatch(changeLabel("managesettings"));
  }, []);
  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>

      <CardLayout className="p-sm-5">
        <Box className="mb-5">
          <DivideTitle title="twilio credentials" className="mb-4" />
          <Row className="d-flex flex-column">
            {/* <Col xl={12} className="ps-xl-5"> */}
              <Row>
                <div className="col-md-4">
                  <Text>Account SID</Text>
               
                  <LabelField
                    type="text"
                    fieldSize={`w-xl h-md`}
                    name="ACCOUNT_SID"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4">
                  <Text>Auth Token</Text>
              
                  <LabelField
                    type="text"
                    fieldSize={`w-xl h-md`}
                    name="AUTH_TOKEN"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4">
                  <Text>Twilio Number</Text>
               
                  <LabelField
                    type="text"
                    fieldSize={`w-xl h-md`}
                    name="TWILIO_NUMBER"
                    onChange={handleChange}
                  />
                </div>
              </Row>
            {/* </Col> */}
          </Row>
        </Box>

        <Box className="mb-5">
          <DivideTitle title="sendgrid credentials" className="mb-4" />
          <Row className="d-flex flex-column">
            {/* <Col xl={12} className="ps-xl-5"> */}
              <Row>
              <div className="col-md-4">
                  <Text>SendGrid ID</Text>
              
               
                  <LabelField
                    type="text"
                    fieldSize={`w-xl h-md`}
                    name="SENDGRID_ID"
                    onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                  <Text>SendGrid Auth User</Text>
                
                  <LabelField
                    type="text"
                    fieldSize={`w-xl h-md`}
                    name="SENDGRID_AUTH_USER"
                    onChange={handleChange}
                  />
                </div>
              </Row>
            {/* </Col> */}
          </Row>
        </Box>

        <Box className="mb-5">
          <DivideTitle title="s3 bucket credentials" className="mb-4" />
          <Row className="d-flex flex-column">
            {/* <Col xl={12} className="ps-xl-5"> */}
              <Row>
              <div className="col-md-4">
                  <Text>S3 Mount DIR</Text>
                
                  <LabelField
                    type="text"
                    fieldSize={`w-xl h-md`}
                    name="S3_MOUNT_DIR"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4">
                  <Text>Bucket Name</Text>
              
                  <LabelField
                    type="text"
                    fieldSize={`w-xl h-md`}
                    name="Bucket_Name"
                    onChange={handleChange}
                  />
               </div>
               <div className="col-md-4">
                  <Text>Access Key ID</Text>
                
                  <LabelField
                    type="text"
                    fieldSize={`w-xl h-md`}
                    name="AccessKeyID"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4">
                  <Text>Secret Access Key</Text>
               
                  <LabelField
                    type="text"
                    fieldSize={`w-xl h-md`}
                    name="SecretAccessKey"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4">
                  <Text>Region</Text>
                
                  <LabelField
                    type="text"
                    fieldSize={`w-xl h-md`}
                    name="region"
                    onChange={handleChange}
                  />
                </div>
              </Row>
            {/* </Col> */}
          </Row>
        </Box>

        <Box className="mb-5">
          <DivideTitle title="OTP time" className="mb-4" />
          <Row className="d-flex flex-column">
            {/* <Col xl={12} className="ps-xl-5"> */}
              <Row>
                <div className="col-md-4">
                  <Text>OTP Expiry Time</Text>
               
                  <LabelField
                    type="password"
                    fieldSize={`w-xl h-md`}
                    name="otpExpiryTime"
                    onChange={handleChange}
                  />
                </div>
              </Row>
            {/* </Col> */}
          </Row>
        </Box>

        <Box className="mb-5">
          <DivideTitle title="JWT credentials" className="mb-4" />
          <Row className="d-flex flex-column">
            {/* <Col xl={12} className="ps-xl-5"> */}
              <Row>
              <div className="col-md-4">
                  <Text>JWT Secret Key</Text>
               
                  <LabelField
                    type="password"
                    fieldSize={`w-xl h-md`}
                    name="JWT_SECRET_KEY"
                    onChange={handleChange}
                  />
                </div>
              </Row>
            {/* </Col> */}
          </Row>
        </Box>
        <div
          
          className = "blackButton d-inline"
          onClick={handleClick}
        >Submit</div>
      </CardLayout>
    </PageLayout>
  );
}
