import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Anchor, Item } from "../../components/elements";
import { CardLayout, CardHeader, FloatCard } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import LabelField from "../../components/fields/LabelField";
import { RiderTable } from "../../components/tables";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/riderManagement.json";
import { useDispatch, useSelector } from "react-redux";
import { getAllRiders, toggleChange } from "../../slices/riderManagementSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { changeLabel } from "../../slices/sidebarSlice";
import {Box  , Heading , Text} from "../../components/elements";
import { RiderIcon , SearchIcon , DownloadIcon } from "../../components/icons/icons";
import { Search } from "../../components/fields/search";
import { getDateInYYYYMMDD } from "../../components/elements/DatePicker";
import DatePicker from "../../components/elements/DatePicker";
import { searchProfiles } from "../../slices/driverManagementSlice";
import { downloadProfiles } from "../../slices/driverManagementSlice";
import { downloadCsv } from "../../components/elements/DownloadCsv";
import { current } from "@reduxjs/toolkit";

export default function RiderManagement() {
  const [filterData, setFilterData] = React.useState([]);
  const [selectedRange , setSelectedRange] = React.useState({
    startDate : new Date(),
    endDate : new Date()
  })


  const [searchValue , setSearchValue ] = React.useState("");
  const [searchActive, setSearchActive] = React.useState(false);

  const searchData = useSelector((state) => state.driverManagement.searchData)

  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(10);

  const dispatch = useDispatch();

  const ridersList = useSelector((state) => state.riderManagement.ridersList);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // getRiders({
    //   role : "user" , 
    //   page : newPage , 
    //   limit : itemsPerPage
    // })
    applyFilters();
  };

  const getStartIndex = () =>{
    return (currentPage - 1) *  itemsPerPage
  }

  const handleChange = (e) => {
    const roleVal = e.target.value;

    const d = data?.table.tbody.filter(
      (item) =>
        item.role.text === roleVal ||
        item.name === roleVal ||
        item.email === roleVal ||
        item.phone === roleVal ||
        item.status === roleVal
    );
    setFilterData(d);
  };
  // console.log(filterData, "data");

  // const params = {
  //   role: "user",
  //   // page: currentPage,
  //   // limit: 1,
  // };

  // React.useEffect(() => {
  //   dispatch(getAllRiders(params))
  //     .unwrap()yy
  //     .catch((error) => console.log("Something went wrong!"));
  // }, [currentPage]);

  const getRiders = (params) =>{
    dispatch(getAllRiders(params))
    .unwrap()
    .catch((error) => console.log("Something went wrong!"));
  }

  React.useEffect(() => {
   getRiders({role : "user" ,  page : currentPage , 
    limit : itemsPerPage})

    dispatch(changeLabel(""));
  }, []);

  const handleDateRange = (range) =>{
    let {startDate , endDate } = range;
    setSelectedRange(range);
    let formattedStart =  getDateInYYYYMMDD(startDate);
    let formattedEnd = getDateInYYYYMMDD(endDate);
    getRiders({role  :"user" ,fromDate : formattedStart  , toDate  : formattedEnd , page : currentPage , limit : itemsPerPage})
   }
  
  

  const downloadRiderDetail = async (params) => {
    try {
     const csvContent =  await  dispatch(downloadProfiles(params)).unwrap();
      downloadCsv(csvContent, "RidersList.csv")

    } catch (error) {
        toast.error("No Data found");
    }
  };

   const DownloadData = () =>{
     let startDate = getDateInYYYYMMDD(selectedRange?.startDate)
     let endDate = getDateInYYYYMMDD(selectedRange?.endDate)
  
     console.log(startDate , "klkk" , endDate)
     downloadRiderDetail({role : "user" , fromDate : startDate , toDate : endDate})
     
   }

  const toggleChangeStatus = (id) => {
    dispatch(toggleChange({ id, role : "user"} ))
      .unwrap()
      .catch((error) => {
        if (error.message === "Rejected") {
          toast.error("Something went wrong!");
        }
      });
  };

  const searchProfile = async(data) =>{
    setSearchActive(true)
    const res = await dispatch(searchProfiles(data))
     .unwrap()
     .catch((error) => console.log(error));
  }
  
  const handleSearchChange = (e)=>{
    if(e.target.value === ""){
    setSearchActive(false)
    }
    setSearchValue(e.target.value)
  
  }

  // useEffect(()=>{
  //   if(searchValue.length >= 0){
  //     let params = {
  //       // isVerifiedDriver : false,
  //       role : "user",
  //       isActive : true,
  //       searchTerms : searchValue
  //     }
  //     searchProfile(params);
  //   }
  //   // else{
  //   //   getRiders({role : "user"})
  //   // }
  // },[searchValue])

  const applyFilters = () => {
    const params = { role: "user"  , page : currentPage , limit : itemsPerPage};

    if (selectedRange) {
        params.fromDate = getDateInYYYYMMDD(selectedRange.startDate);
        params.toDate = getDateInYYYYMMDD(selectedRange.endDate);
        getRiders(params);
    }

     if(searchValue) {
        params.searchTerms = searchValue;
        searchProfile(params);
    } else {
        getRiders({role : "user" , page : currentPage , limit : itemsPerPage  });
    }
};

useEffect(() => {
    applyFilters();
}, [currentPage , searchValue, selectedRange]);

  
  // useEffect(() =>{
  //   console.log(ridersList , "l;l;")
  // },[ridersList])

  const tbody = searchActive && searchData.length === 0 ? [] : (searchActive ? searchData : ridersList)

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={data?.pageTitle}>
              {data?.breadcrumb.map((item, index) => (
                <Item key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </Item>
              ))}
            </Breadcrumb>
          </CardLayout>
        </Col>

        {/* {data?.float.map((item, index) => (
          <Col xl={4} key={index}>
            <FloatCard
              variant={item.variant}
              digit={item.digit}
              title={item.title}
              // icon={item.icon}
            />
          </Col>
        ))} */}

       

        <Col xl={12}>
          <CardLayout>
            <div className="d-flex justify-content-between mb-2">
            <CardHeader
              title="Data of all the Riders"
              //  dotsMenu={data?.dotsMenu}
            />
            </div>
{/* 
          <div className=" flex border rounded-xl"   >
           <span className="p-2 flex gap-2"><SearchIcon /><Search placeholder="search by name/email"/> </span>
           </div>
           </div> */}

           <div className="d-flex justify-content-between flex-nowrap pb-4">
              <div className="d-flex gap-2">
            {/* <span className="border rounded-xl  p-2 m-auto ">date and time</span>  */}
           <DatePicker onDateRangeSubmit  = {handleDateRange} />

             <div className="d-flex cursor-pointer wrap-content" onClick = {DownloadData}>
              
         <span className="px-1"><DownloadIcon /> </span>
         <span className="text-uppercase">Export</span>
      
            </div>
            </div>

           <Col xl = {5}>
           <div className="d-flex searchClass"   >
            <span className="px-1"><SearchIcon /> </span>
           <span className="search-input"><Search placeholder="Search by name/email/phone"  value= {searchValue} onChange = {handleSearchChange}/> </span></div>
           
           </Col>
           </div>
          
         
            {/* <Row xs={1} sm={4} className="mb-4">
              {data?.filter.map((item, index) => (
                <Col key={index}>
                  <LabelField
                    type={item.type}
                    label={item.label}
                    option={item.option}
                    placeholder={item.placeholder}
                    labelDir="label-col"
                    fieldSize="w-100 h-sm"
                    // onChange={(e) => handleChange(e)}
                  />
                </Col>
              ))}
            </Row> */}
            <RiderTable
              thead={data?.table.thead}
              // tbody={data?.table.tbody}
              // filterData={filterData}
              startIndex = {getStartIndex}
              tbody={searchData.length > 0 ? tbody : tbody?.docs}
              toggleChange={toggleChangeStatus}
            />
            {tbody.totalDocs > 0 &&
            <Pagination
              totalItems={tbody?.totalDocs}
              itemsPerPage={tbody?.pagingCounter}
              currentPage={currentPage}
              limit = {tbody?.limit}
              onPageChange={handlePageChange}
            />
             }

            {/* <RiderTable
              thead={data?.table.thead}
              // tbody={searchData.length > 0  ? searchData : ridersList}
              tbody = {tbody}
              toggleChange={toggleChangeStatus}
            />
            <Pagination /> */}
          </CardLayout>
        </Col>
      </Row>
      <ToastContainer />
    </PageLayout>
  );
}
