import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  changePassword,
  sendOtpOnEmail,
  login,
  updateProfile,
  viewProfile,
  resetPassword,
} from "../services/authService";

export const adminLogin = createAsyncThunk(
  "auth/adminLogin",
  async (data, { rejectWithValue }) => {
    try {
      const res = await login(data);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeCurrentPassword = createAsyncThunk(
  "auth/changeCurrentPassword",
  async (newPasswordData, { rejectWithValue }) => {
    try {
      const res = await changePassword(newPasswordData);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAdminProfile = createAsyncThunk(
  "auth/updateAdminProfile",
  async (formData, { rejectWithValue }) => {
    try {
      const res = await updateProfile(formData);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const viewAdminProfile = createAsyncThunk(
  "auth/viewAdminProfile",
  async (_, { rejectWithValue }) => {
    try {
      const res = await viewProfile();
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendOtpOnAdminEmail = createAsyncThunk(
  "auth/sendOtpOnAdminEmail",
  async (email, { rejectWithValue }) => {
    try {
      const res = await sendOtpOnEmail(email);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resetAdminPassword = createAsyncThunk(
  "auth/resetAdminPassword",
  async (data, { rejectWithValue }) => {
    try {
      const res = await resetPassword(data);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const auth = createSlice({
  name: "auth",
  initialState: {
    name: "",
    email: "",
    profileUrl: "",
  },
  extraReducers: (builder) => {
    builder.addCase(adminLogin.fulfilled, (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.profileUrl = action.payload.profileUrl;
    });

    builder.addCase(changeCurrentPassword.fulfilled);

    builder.addCase(updateAdminProfile.fulfilled, (state, action) => {
      state.name = action.payload.name;
      state.profileUrl = action.payload.profileUrl;
    });

    builder.addCase(viewAdminProfile.fulfilled, (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.profileUrl = action.payload.profileUrl;
    });

    builder.addCase(sendOtpOnAdminEmail.fulfilled);

    builder.addCase(resetAdminPassword.fulfilled);
  },
});

export default auth.reducer;
