import React from "react";
import { Box, Item, Anchor } from "../../components/elements";
import { Breadcrumb, DivideTitle } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/vehicleColor.json";
import { CardHeader } from "../../components/cards";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LabelField } from "../../components/fields";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewVehicleColor,
  getAllVehicleColorList,
  toggleChangeVehicleColor,
} from "../../slices/manageSettingsSlice";
import { changeLabel } from "../../slices/sidebarSlice";
import { VehicleColorTable } from "../../components/tables";
import { AddIcon } from "../../components/icons/icons";

export default function VehicleColor() {
  const [color, setColor] = React.useState("");

  const dispatch = useDispatch();

  const { colorList } = useSelector((state) => state.manageSettings);

  const handleVehicleColor = (e) => {
    setColor(e.target.value);
  };

  const addVehicleColor = () => {
    if (!color) {
      toast.warn("Add a color!");
    } else {
      dispatch(addNewVehicleColor({ label: color }))
        .unwrap()
        .then((res) => toast.success(res.result))
        .catch((error) => toast.error("Something went wrong!"));
    }
    setColor("");
  };

  const toggleChange = (id) => {
    dispatch(toggleChangeVehicleColor(id));
  };

  React.useEffect(() => {
    dispatch(getAllVehicleColorList())
      .unwrap()
      .catch(() => toast.error("Something unwanted occurred!"));

    dispatch(changeLabel("vehiclesettings"));
  }, []);

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>

     
        <CardLayout className="p-sm-5">
        <Box className="mb-5">
          <DivideTitle title="add Color" className="mb-5" />

         <div className="d-flex gap-2">
         <LabelField
          type="text"
          placeholder = "Type color"
          fieldSize={`w-xl h-md`}
          value={color}
          onChange={(e) => handleVehicleColor(e)}
        />
       
                <div className="blackButton" onClick= {() => addVehicleColor() } >
            <span className="px-1"> <AddIcon /></span>
          Add Color
           </div>
                </div>
        </Box>
      </CardLayout>

      <CardLayout className={`mt-4`}>
        <CardHeader title="Color Data of the Registered Cars " />
        <VehicleColorTable
          thead={["vehicle color", "status", "action"]}
          list={colorList}
          toggleChange={toggleChange}
          toast={toast}
        />
      </CardLayout>
      <ToastContainer />
    </PageLayout>
  );
}
