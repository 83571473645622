import React from "react";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Text, Box } from "../elements";
import userInfo from "../../data/master/driverManagement.json";
import moment from "moment";

export default function AnnouncementTable({ tbody }) {
  const thead = ["title", "message", "created at"];

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    setData(tbody);
  }, [tbody]);

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                <Text>s no.</Text>
              </Box>
            </Th>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
      </Thead>
        {/* <Tbody className="mc-table-body even announce"> */}
        <Tbody className="mc-table-body even">
          {data?.map((item, index) => (
            <Tr key={index}>
              <Td title="id">
                <Box className="mc-table-check">
                  <Text>#{index + 1}</Text>
                </Box>
              </Td>
              <Td title={item.title}>
                <Box className="mc-table-profile">
                  <Text className="text-capitalize">{item.title}</Text>
                </Box>
              </Td>
              <Td title={item.message}>
              <Text className="text-capitalize">{item.message}</Text></Td>
              <Td title={item.createdAt}>
                {new Date(item.createdAt).toLocaleString()}
                {/* {moment
                  .utc(item.createdAt)
                  .local()
                  .format("DD-MM-YYYY, HH:mm:ss")} */}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
