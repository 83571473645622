import React, { useContext } from "react";
import { ThemeContext } from "../../context/Themes";
import { AreaChart, Area, XAxis,Label , YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export default function SalesChart({ chart }) {
    const { theme } = useContext(ThemeContext);
    const dark = { border: "#39496b", frame: "#2f3f61", title: "#f0f0f0", text: "#d1d1d1", bg: "#1b2b4d" };
    const light = { border: "#d1d1d1", frame: "#f0f0f0", title: "#403e57", text: "#5e5d72", bg: "#ffffff" };

    const salesData = Array.isArray(chart) ? chart.map(data => parseFloat(data.sale) || 0) : [];
    const dataMax = salesData.length > 0 ? Math.max(...salesData) : 0; 

        // Format function for Y-axis ticks
        const formatYAxisTick = (value) => {
            return value.toFixed(2); // Format to 2 decimal places
        };
    return (
        <div style={{ width: '100%', height: '400px' }}> {/* Adjust height as needed */}
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={chart} margin={{ top: 20, right: 30, left: 50, bottom: 30 }}>
                    <defs>
                        <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="75%" stopColor="#2351af" stopOpacity={0.3} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid stroke="#4094f1" strokeDasharray="1 5" />
                    <XAxis 
                        dataKey="week"
                        tick={{ fill: theme !== "light_mode" ? dark.text : light.text }} 
                        padding={{ left: 10, right: 10 }} 
                        >
                             <Label value="Days of week" position="bottom" offset={3} fill={theme !== "light_mode" ? dark.text : light.text} />
                             </XAxis>
                    <YAxis 
                        type="number"
                        // domain={[0, 'dataMax + 250']} // Adds padding to ensure the highest value is fully visible
                        domain={[0,  dataMax * 1.1]} 
                        tickFormatter={formatYAxisTick}
                        tick={{ fill: theme !== "light_mode" ? dark.text : light.text }}
                    >
                          <Label 
                            value="Sales ($)" 
                            angle={-90} 
                            position="left" 
                            offset = {41}
                            style={{ textAnchor: 'middle' }} 
                            fill={theme !== "light_mode" ? dark.text : light.text}
                        />
                        </YAxis>

                    <Tooltip
                        cursor={false}
                        contentStyle={{
                            background: `${theme !== "light_mode" ? dark.bg : light.bg}`,
                            borderRadius: "8px",
                            padding: "8px 15px 9px",
                            border: `1px solid ${theme !== "light_mode" ? dark.frame : light.frame}`,
                            boxShadow: "0px 12px 25px 0px rgb(0 0 0 / 12%)",
                        }}
                        itemStyle={{
                            color: `${theme !== "light_mode" ? dark.text : light.text}`,
                            fontSize: "14px",
                            fontWeight: "500",
                            padding: "2px 0px",
                            textTransform: "capitalize",
                        }}
                        labelStyle={{
                            color: `${theme !== "light_mode" ? dark.title : light.title}`,
                            fontSize: "12px",
                            fontWeight: "600",
                            textTransform: "uppercase",
                        }}
                    />
                    <Area 
                        type="monotone" 
                        dataKey="sale" 
                        stroke="#2b77e5"
                        strokeWidth="2" 
                        fill="url(#color)" 
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}
