import React from "react";
import { Item, Anchor } from "../../components/elements";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/settings.json";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {  getAllPolicy } from "../../slices/manageSettingsSlice";
import { changeLabel } from "../../slices/sidebarSlice";
import { PolicyTable } from "../../components/tables";
import { AddIcon } from "../../components/icons/icons";

export default function PolicyManagement() {
  const dispatch = useDispatch();

  const { policies } = useSelector((state) => state.manageSettings);

  const getPolicy = () => {
    dispatch(getAllPolicy())
      .unwrap()
      .catch((error) => console.log("Something went wrong!"));
  };

  React.useEffect(() => {
    getPolicy();
    dispatch(changeLabel("managesettings"));
  }, []);

  const navigate = useNavigate();

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      <CardLayout>
        <div className = "d-flex justify-content-between mb-5">
            <CardHeader
              title="Policy Management"
            /> 

      <div className="d-flex gap-2 ">
       <div 
       className="blackButton"
       onClick={()=>navigate('/add-policy')}>
            <span className="px-1"> <AddIcon /></span>
           Add Policy
           </div>
        </div>

          
         </div>

        <PolicyTable tbody={policies} />
      </CardLayout>
    </PageLayout>
  );
}
