export const Search = ({
    id,
    children,
    name,
    placeholder,
    classname,
    onChange = () =>{},
    maxLength,value=''
  }) => {
    return (
      <div className="flex flex-1 items-center  justify-start ">
        <input
          type="search"
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={(e) => onChange(e)}
         
          // className={` w-auto text-sm font-normal rounded-md ${classname}`}
          maxLength={maxLength}
          value={value}
        />
        {children}
      </div>
    );
  };
  