import React, { forwardRef } from "react";

// export default function Input({ type, placeholder, className, ...rest }) {
//     return <input type={ type || "text" } placeholder={ placeholder } className={ className } { ...rest } />
// }

const Input = forwardRef(({ type, placeholder, className, ...rest }, ref) => {
  return (
    <input
      type={type || "text"}
      placeholder={placeholder}
      className={className}
      ref={ref}
      {...rest}
    />
  );
});

export default Input;
