// import React from 'react';
// import { useState } from 'react';

// export const RatingBar = (stars) =>{
//     const [starCount , setStarCount] = useState(Math.floor(stars.stars));

//     console.log(starCount)
//     return(
//         <div className='ratingBar '>
//             {[...Array(5)].map((_,index) =>{
//                 return(
//                 <div key = {index} >
//                  <span style={{ color: index < starCount ? '#FBBF24' : '' }}>
//                             &#9733;
//                     </span>
//                 </div>
//                 )
//             })}
//             {/* <span className='rating'>{(stars.stars)}</span> */}
//         </div>
//     )
// }




// import React from 'react';

// const fullStar = '/images/fullStar.png';
// const halfStar = '/images/halfStar.png';
// const emptyStar = '/images/emptyStar.png';

// export const RatingBar = ( {stars} ) => {
//     console.log(stars , "jlkjk")
//     const fullStars = Math.floor(stars);
//     const hasHalfStar = stars - fullStars >= 0.5;
//     const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

//     // Create an array of star elements
//     const starArr = [
//         ...Array(fullStars).fill(fullStar), // Full stars
//         ...(hasHalfStar ? [halfStar] : []), // Half star
//         ...Array(emptyStars).fill(emptyStar) // Empty stars
//     ];
//  console.log(starArr  , "staratt")
//     return (
//         <div  className = "ratingBar"
//    >
//             {starArr?.map((star, index) => (
        
//                 <img
//                     key={index}
//                     src={star}
//                     alt="star"
//                     style={{ width: '20px', height: '20px' }} // Adjust size as needed
//                 />
//         ))}
//         </div>
//     );
// };



import React from 'react';

const fullStar = '/images/fullStar.png';
const halfStar = '/images/halfStar.png';
const emptyStar = '/images/emptyStar.png';

export const RatingBar = ({ stars }) => {
    console.log(stars, "stars value");

    // Ensure stars is a number between 0 and 5
    const validStars = Math.min(Math.max(stars || 0, 0), 5);

    const fullStars = Math.floor(validStars);
    const hasHalfStar = validStars - fullStars >= 0.5;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    // Create an array of star elements
    const starArr = [
        ...Array(fullStars).fill(fullStar), // Full stars
        ...(hasHalfStar ? [halfStar] : []), // Half star
        ...Array(emptyStars).fill(emptyStar) // Empty stars
    ];

    console.log(starArr, "star array");

    return (
        <div className="ratingBar">
            {starArr.map((star, index) => (
                <img
                    key={index}
                    src={star}
                    alt="star"
                    style={{ width: '20px', height: '20px' }} // Adjust size as needed
                />
            ))}
        </div>
    );
};



