import React from "react";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { LegendField, IconField } from "../../components/fields";
import { Item, Anchor, Box, Button, Image } from "../../components/elements";
import { CardLayout, TabCard } from "../../components/cards";
import { Breadcrumb, FileUpload } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/myAccount.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  changeCurrentPassword,
  updateAdminProfile,
  viewAdminProfile,
} from "../../slices/authSlice";

export default function MyAccount() {
  const [newPasswordData, setNewPasswordData] = React.useState({});
  const [profileData, setProfileData] = React.useState({});
  const [file, setFile] = React.useState("");
  const [img, setImg] = React.useState({});

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleChangePassword = (event) => {
    setNewPasswordData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSaveNewPassword = async () => {
    console.log(newPasswordData, "pwd");

    if (!newPasswordData.oldPassword) {
      toast.warn("Enter your current password!");
    } else if (!newPasswordData.password) {
      toast.warn("Enter your new password!");
    } else if (!newPasswordData.confirmPassword) {
      toast.warn("Confirm your new password!");
    } else if (newPasswordData.password !== newPasswordData.confirmPassword) {
      toast.warn("Password doesn't match");
    } else {
      dispatch(changeCurrentPassword(newPasswordData))
        .unwrap()
        .then((res) => {
          toast.success(res.result);
          setTimeout(() => {
            setNewPasswordData({
              oldPassword: "",
              password: "",
              confirmPassword: "",
            });
            navigate("/");
          }, 1000);
        })
        .catch((error) => toast.error("Something went wrong!"));
    }
  };

  let formData = new FormData();

  const handleAvatarChange = (e) => {
    if (e.target.files.length > 0) {
      setFile(URL.createObjectURL(e.target.files[0]));
      setImg(e.target.files[0]);
      // formData.set("img", e.target.files[0]);
    }
  };

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // formData.set(name, value);
  };

  const handleClick = async () => {
    formData.set("img", img);
    formData.set("name", profileData.name);

    if (!profileData.name) {
      toast.warn("Name is required!");
    } else {
      dispatch(updateAdminProfile(formData))
        .unwrap()
        .then((res) => {
          toast.success("Profile Updated Successfully");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        })
        .catch((error) => toast.error("Something went wrong!"));
    }
  };

  React.useEffect(() => {
    dispatch(viewAdminProfile())
      .unwrap()
      .then((res) => setProfileData(res))
      .catch((error) => console.log(error, "Something went wrong!"));
  }, []);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <Breadcrumb title={data?.pageTitle}>
            {data?.breadcrumb.map((item, index) => (
              <Item key={index} className="mc-breadcrumb-item">
                {item.path ? (
                  <Anchor className="mc-breadcrumb-link" href={item.path}>
                    {item.text}
                  </Anchor>
                ) : (
                  item.text
                )}
              </Item>
            ))}
          </Breadcrumb>
        </Col>
        <Col xl={12}>
          <CardLayout>
            <Tabs defaultActiveKey="profile" id="mc" className="mc-tabs">
              <Tab
                eventKey="profile"
                title="edit Profile"
                className="mc-tabpane profile"
              >
                <TabCard title="profile information">
                  <Row>
                    <Col xl={4}>
                      <Box className="mc-user-avatar-upload">
                        <Box className="mc-user-avatar">
                          <Image
                            src={file ? file : profileData.profileUrl}
                            alt={data?.avatar.alt}
                          />
                        </Box>
                        <FileUpload
                          // icon="cloud_upload"
                          text="UPLOAD"
                          onChange={handleAvatarChange}
                          accept="image/jpg,image/jpeg,image/png"
                        />
                      </Box>
                    </Col>
                    <Col xl={8}>
                      <Row>
                        <Col xl={6}>
                          <LegendField
                            title={data?.name.title}
                            name="name"
                            // value={data?.name.value}
                            value={profileData.name}
                            onChange={handleProfileChange}
                          />
                        </Col>
                        <Col xl={6}>
                          <LegendField
                            title={data?.email.title}
                            name="email"
                            placeholder={profileData.email}
                            // value={data?.email.value}
                            // value={profileData.email}
                            // onChange={handleProfileChange}
                            readOnly
                          />
                        </Col>
                        <Col xl={6}>
                          <LegendField
                            title={data?.phone.title}
                            name="phone"
                            placeholder={profileData.phone}
                            // value={data?.phone.value}
                            // value={profileData.phone}
                            // onChange={handleProfileChange}
                            readOnly
                          />
                        </Col>
                        <Col xl={6}>
                          <LegendField
                            // title={data?.username.title}
                            // value={data?.username.value}
                            placeholder={profileData.gender}
                            title="gender"
                            name="gender"
                            // value={profileData.gender}
                            readOnly
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabCard>
                
                <div className = "d-flex justify-content-end">
                <div
                className="blackButton"
                 
                  onClick={handleClick}
                >Save Changes</div>
                </div>
              </Tab>
              <Tab
                eventKey="password"
                title="Change Password"
                className="mc-tabpane password"
              >
                <TabCard title="generate password">
                  <Row>
                    <Col xs={12} md={12}>
                      <IconField
                        icon="lock"
                        type="password"
                        placeholder="current password"
                        classes="w-100 h-lg"
                        passwordVisible
                        name="oldPassword"
                        value={newPasswordData.oldPassword}
                        onChange={handleChangePassword}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <IconField
                        icon="add_moderator"
                        type="password"
                        placeholder="new password"
                        classes="w-100 h-lg"
                        passwordVisible
                        name="password"
                        value={newPasswordData.password}
                        onChange={handleChangePassword}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <IconField
                        icon="verified_user"
                        type="password"
                        placeholder="confirm password"
                        classes="w-100 h-lg"
                        passwordVisible
                        name="confirmPassword"
                        value={newPasswordData.confirmPassword}
                        onChange={handleChangePassword}
                      />
                    </Col>
                  </Row>
                </TabCard>
                <div className = "d-flex mt-4 justify-content-end">
                <div
                className="blackButton"
                  // className = "bg-black font-[500] text-white uppercase px-3 py-2 rounded-xl"
                  onClick={handleSaveNewPassword}
                >save changes</div>
                </div>
              </Tab>
             
            </Tabs>
          </CardLayout>
        </Col>
      </Row>
      <ToastContainer />
    </PageLayout>
  );
}
