import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const token = localStorage.getItem("token");
export const ReduxApi = createApi({
  reducerPath: "ReduxApi",

  baseQuery: fetchBaseQuery({
    // baseUrl: "http://localhost:3000",
    //  baseUrl : "http://51.20.115.46/api",
    baseUrl : "http://hail-a-taxi.com/api"
  }),

  // refetchOnMountOrArgChange: true,

  tagTypes: ["Data"],

  endpoints: (builder) => ({
    getData: builder.query({
      query: ({ url, params }) => ({
        url,
        params,
        headers: {
          Authorization: token,
        },
      }),
      providesTags: ["Data"],
    }),

    changeDataById: builder.mutation({
      query: ({ url }) => ({
        url,
        method: "GET",
        headers: {
          Authorization: token,
        },
      }),
      invalidatesTags: ["Data"],
    }),

    addData: builder.mutation({
      query: ({ url, body }) => ({
        url,
        method: "POST",
        body,
        headers: {
          Authorization: token,
        },
      }),
      invalidatesTags: ["Data"],
    }),

    updateData: builder.mutation({
      query: ({ url, body }) => ({
        url,
        method: "PUT",
        body,
        headers: {
          Authorization: token,
        },
      }),
      invalidatesTags: ["Data"],
    }),

    deleteData: builder.mutation({
      query: ({ id, url }) => ({
        url: `${url}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetDataQuery,
  useChangeDataByIdMutation,
  useAddDataMutation,
  useUpdateDataMutation,
  useDeleteDataMutation,
} = ReduxApi;

