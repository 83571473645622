import React from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Text, Item, Anchor, Button } from "../../components/elements";
import { LabelField } from "../../components/fields";
import { Breadcrumb, DivideTitle } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/addVehiclePrice.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewRidePrice,
  getRidePriceToEdit,
  updateNewRidePrice,
} from "../../slices/manageSettingsSlice";
import { changeLabel } from "../../slices/sidebarSlice";
import Multipleselect from "../../components/Multipleselect";
import { getAllVehicleType } from "../../slices/driverManagementSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { editExistingVehicleType } from "../../slices/manageSettingsSlice";
import { getVehicleTypeFromId } from "../../slices/driverManagementSlice";

export default function AddVehiclePrice() {
  const [feeData, setFeeData] = React.useState({
  });
  const [initialFeeData, setInitialFeeData] = React.useState({});

  const location = useLocation();
  console.log(location.state, "id..");
  const navigate = useNavigate()

  const dispatch = useDispatch();


  const handleFeeChange = (e) => {
    console.log(e.target.value , "E")
    const { name, value } = e.target;
    setFeeData((prev) => ({
      ...prev,
      [name]: value.replace("%", ""),
    }));
  };

  const handleVehicleType = (_, selectedItem) => {
    setFeeData((prev) => ({
      ...prev,
      vehicleType: selectedItem._id,
    }));
  };

  const handleRemoveVehicleType = () => {
    setFeeData((prev) => ({
      ...prev,
      vehicleType: "",
    }));
  };

  const handleFeeDataSubmit = () => {
    console.log(feeData, "data fee");
     if(!feeData.label){
       toast.warn("Vehicle Type is required!")
     }else if (!feeData.price) {
      toast.warn("Base price is required!");
    } else if (!feeData.waitingPrice) {
      toast.warn("Waiting price is required!");
    } else {
      dispatch(addNewRidePrice(feeData))
        .unwrap()
        .then((res) => {
          toast.success(res.result)
         setTimeout(() => {
          navigate("/fee-management");
        }, 1000)
      }).catch((error) => toast.error("Something went wrong!"));
    }
  };

  const handleUpdateFeeData = () => {
    const updatedFields = {};
    for (const key in feeData) {
      if (feeData[key] !== initialFeeData[key]) {
        updatedFields[key] = feeData[key];
      }
    }
      const updatePayload = {
        Id: location.state,
        ...updatedFields
      };

      dispatch(editExistingVehicleType(updatePayload))
        .unwrap()
        .then((res) => {
          toast.success(res.result);
          setTimeout(() => {
            navigate("/fee-management");
          }, 1000);
        })
        .catch((error) => toast.error("Something went wrong!"));
    // }
  };
 

  const getRidePrice = (id) => {
    dispatch(getVehicleTypeFromId(id))
      .unwrap()
      .then((res) => {
        setFeeData(res)
        setInitialFeeData(res)
  })
      .catch((error) => console.log(error, "error in getting ride price"));
  };

 

  React.useEffect(()=>{
     if(location.state){
      getRidePrice(location.state)
     }
  },[])

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>

      <CardLayout className="p-sm-5">
        
              <div className="container"> 
              <div className="row">
          
             <div className = "col-md-3">
                <Text>Vehicle Type</Text>
              <LabelField
              placeholder = "Type vehicleType"
              type="text"
              name="label"
              fieldSize={`w-xl h-md`}
              value={feeData?.label}
              onChange={handleFeeChange}
            />
          </div>
                

          <div className = "col-md-3">
                <Text>Short Description</Text>
              <LabelField
              type="text"
              placeholder = "Type short description"
              name="sortDescription"
              fieldSize={`w-xl h-md`}
              value={feeData?.sortDescription}
              onChange={handleFeeChange}
            />
          </div>

          <div className = "col-md-3">
                <Text>Long Description</Text>
              <LabelField
              placeholder = "Type long description"
              type="text"
              name="longDescription"
              fieldSize={`w-xl h-md`}
              value={feeData?.longDescription}
              onChange={handleFeeChange}
            />
          </div>

          <div className = "col-md-3">
                <Text>Number of Seats</Text>
              <LabelField
              type="text"
              placeholder = "Type no. of seats"
              name="numberOfSeats"
              fieldSize={`w-xl h-md`}
              value={feeData?.numberOfSeats}
              onChange={handleFeeChange}
            />
          </div>
          </div>


        <div className="row mt-3 " >
          <div className = "col-md-3">
                <Text>Base Fare</Text>
              <LabelField
              type="text"
              name="price"
              placeholder = "Type base fare"
              fieldSize={`w-xl h-md`}
              value={feeData?.price}
              onChange={handleFeeChange}
            />
          </div>

          <div className = "col-md-3">
                <Text>Driver Waiting Charges</Text>
              <LabelField
              type="text"
              name="waitingPrice"
              placeholder = "Type waiting charges"
              fieldSize={`w-xl h-md`}
              value={feeData?.waitingPrice}
              onChange={handleFeeChange}
            />
          </div>


          <div className = "col-md-3">
                <Text>Night Charges</Text>
              <LabelField
              type="text"
              name="nightPrice"
              placeholder = "Type night charges"
              fieldSize={`w-xl h-md`}
              value={feeData?.nightPrice}
              onChange={handleFeeChange}
            />
          </div>
          </div>
            </div> 
       
       
            <div className="d-flex justify-content-end mt-4">
        {location.state ? (
          <div
           className = "blackButton"
        
            onClick={handleUpdateFeeData}
          >Update</div>
        ) : (
          <div
            className="blackButton"
            onClick={handleFeeDataSubmit}
          >Submit</div>
        )}
        </div>
      </CardLayout>
      <ToastContainer />
    </PageLayout>
  );
}
