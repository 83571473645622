// import React, { useState } from "react";
// import { Box, Icon, Item, List, Text } from "./elements";


// export default function Pagination({
//   totalItems,
//   itemsPerPage,
//   currentPage,
//   limit,
//   onPageChange,
// }) {
//   const totalPages  = Math.ceil(totalItems / limit)
//   const [start , setStart ] = useState(0);
//   const [end , setEnd ] = useState(limit)
//   const pageNumbers = Array.from(
//     { length: totalPages },
//     (_, index) => index + 1
//   );

//   const newPageNumbers = pageNumbers.slice(start , end)

//   const handlePageChangeLeft = (newPage) => {
//     if (newPage >= 1 ) {
//       onPageChange(newPage);
    

//     if (start > 0) {
//      setStart(start - 1);
//       setEnd(end - 1);
//     }
//   }
//   };

//   const handlePageChange = (newPage) => {
//       onPageChange(newPage);
//   };

//   const handlePageChangeRight = (newPage) => {
//     if(newPage <= totalPages){
//       onPageChange(newPage)
      
//       if(currentPage === newPageNumbers[newPageNumbers.length - 1] && end < totalPages){
//        setStart(start+1);
//       setEnd(end+1)
//       }
//   }
// }
  

//   return (
//     <Box className="mc-paginate">
//       <Text className="mc-paginate-title">
//         Showing <b>{itemsPerPage}</b> of <b>{totalItems}</b> Results
//       </Text>
//       <List className="mc-paginate-list">
//         <Item
//           className={`mc-paginate-item  ${currentPage === 1 ? "disabled" : ""}`}
//           onClick={() => currentPage > 1 && handlePageChangeLeft(currentPage - 1)}
//         >
//           <Icon type="chevron_left" />
//         </Item>
        
//         {newPageNumbers.map((page) => (
//           <Item
//             key={page}
//             className={`mc-paginate-item ${
//               page === currentPage ? "active" : ""
//             }`}
//             onClick={() => handlePageChange(page)}
//           >
//             {page}
//           </Item>
//         ))}
//         <Item
//           className={`mc-paginate-item ${currentPage === totalPages ? "disabled" : ""}`}
//           onClick={() => currentPage < totalPages && handlePageChangeRight(currentPage + 1)}
//         >
//           <Icon type="chevron_right" />
//         </Item>
//       </List>
//     </Box>
//   );
// }




import React, { useMemo } from "react";
import { Box, Icon, Item, List, Text } from "./elements";

export default function Pagination({
  totalItems,
  itemsPerPage,
  currentPage,
  limit,
  onPageChange,
}) {
  console.log(totalItems , "items " , itemsPerPage , "items page " , currentPage , "page" , limit)
  const totalPages = Math.ceil(totalItems / limit);

  // Determine the range of page numbers to display
  const pageNumbers = useMemo(() => {
    let pages = [];

    if (totalPages <= 10) {
      // If total pages are 10 or less, display all pages
      pages = Array.from({ length: totalPages }, (_, index) => index + 1);
    } else {
      // If more than 10 pages, handle page number display
      const maxVisiblePages = 10;
      const halfVisible = Math.floor(maxVisiblePages / 2);

      if (currentPage <= halfVisible) {
        // Current page is in the beginning range
        pages = Array.from({ length: maxVisiblePages }, (_, index) => index + 1);
        pages.push('...');
        pages.push(totalPages);
      } else if (currentPage >= totalPages - halfVisible) {
        // Current page is in the ending range
        pages.push(1);
        pages.push('...');
        pages = pages.concat(Array.from({ length: maxVisiblePages }, (_, index) => totalPages - maxVisiblePages + 1 + index));
      } else {
        // Current page is in the middle range
        pages.push(1);
        pages.push('...');
        pages = pages.concat(Array.from({ length: maxVisiblePages }, (_, index) => currentPage - halfVisible + index));
        pages.push('...');
        pages.push(totalPages);
      }
    }

    return pages;
  }, [currentPage, totalPages]);

  const handlePageChange = (page) => {
    if (page !== '...') {
      onPageChange(page);
    }
  };

  const handlePageChangeLeft = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handlePageChangeRight = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <Box className="mc-paginate">
      <Text className="mc-paginate-title">
        Showing <b>{itemsPerPage}</b> of <b>{totalItems}</b> Results
      </Text>
      <List className="mc-paginate-list">
        {/* Previous Button */}
        <Item
          className={`mc-paginate-item ${currentPage === 1 ? "disabled" : ""}`}
          onClick={currentPage > 1 ? handlePageChangeLeft : undefined}
        >
          <Icon type="chevron_left" />
        </Item>

        {/* Page Numbers */}
        {pageNumbers.map((page, index) => (
          <Item
            key={index}
            className={`mc-paginate-item ${page === currentPage ? "active" : ""} ${page === '...' ? "ellipsis" : ""}`}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Item>
        ))}

        {/* Next Button */}
        <Item
          className={`mc-paginate-item ${currentPage === totalPages ? "disabled" : ""}`}
          onClick={currentPage < totalPages ? handlePageChangeRight : undefined}
        >
          <Icon type="chevron_right" />
        </Item>
      </List>
    </Box>
  );
}
