import React from "react";
import DotsMenu from "../DotsMenu";
import { Box, Heading, Anchor } from "../elements";
import { LabelField } from "../fields";

export default function CardHeader({ title, dotsMenu, button, label, ...rest }) {
    return (
        <Box className="mc-card-header">
            { title && <Heading as="h4" className="mc-card-title" {...rest}>{ title }</Heading> }

            {label &&
                <LabelField
                type={label.type}
                label={label.label}
                option={label.option}
                placeholder={label.placeholder}
                name={label.name}
                labelDir="label-row"
                fieldSize="w-100 h-sm"
              />
            }

            { dotsMenu && <DotsMenu dots={ dotsMenu.dots } dropdown={ dotsMenu.dropdown } /> }
            { button && <Anchor className="mc-btn primary" href={ button.path } icon={ button.icon } text={ button.text } /> }
        </Box>
    )
}