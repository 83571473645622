import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addCompany,
  addCounty,
  addLanguage,
  addPolicy,
  addReason,
  addRidePrice,
  addState,
  addVehicleColor,
  addVehicleType,
  editCompany,
  editCounty,
  editLanguage,
  editReason,
  editState,
  editVehicleColor,
  editVehicleType,
  getAllCompany,
  getAllCounty,
  getAllReasons,
  getAllVehicleColor,
  getPolicy,
  getPolicyById,
  getRidePrice,
  getRidePriceById,
  toggleCompany,
  toggleCounty,
  toggleLanguage,
  toggleReason,
  toggleState,
  toggleVehicleColor,
  toggleVehicleType,
  updatePolicy,
  updateRidePrice,
} from "../services/manageSettingsService";
import {
  getAllLanguage,
  getAllState,
  getAllVehicleType,
} from "./driverManagementSlice";

export const addNewRidePrice = createAsyncThunk(
  "manage_settings/addNewRidePrice",
  async (feeData, { rejectWithValue }) => {
    try {
      const res = await addRidePrice(feeData);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getNewRidePrice = createAsyncThunk(
  "manage_settings/getNewRidePrice",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getRidePrice();
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRidePriceToEdit = createAsyncThunk(
  "manage_settings/getRidePriceToEdit",
  async (id, { rejectWithValue }) => {
    try {
      const res = await getRidePriceById(id);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateNewRidePrice = createAsyncThunk(
  "manage_settings/updateNewRidePrice",
  async (data, { rejectWithValue }) => {
    try {
      const res = await updateRidePrice(data);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewPolicy = createAsyncThunk(
  "manage_settings/addNewPolicy",
  async (policy, { rejectWithValue }) => {
    try {
      const res = await addPolicy(policy);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllPolicy = createAsyncThunk(
  "manage_settings/getAllPolicy",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getPolicy();
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPolicyToEdit = createAsyncThunk(
  "manage_settings/getPolicyToEdit",
  async (id, { rejectWithValue }) => {
    try {
      const res = await getPolicyById(id);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateExistingPolicy = createAsyncThunk(
  "manage_settings/updateExistingPolicy",
  async (data, { rejectWithValue }) => {
    try {
      const res = await updatePolicy(data);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewLanguage = createAsyncThunk(
  "manage_settings/addNewLanguage",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await addLanguage(data);
      if (res.status === 200) {
        await dispatch(getAllLanguage());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const toggleChangeLanguage = createAsyncThunk(
  "manage_settings/toggleChangeLanguage",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const res = await toggleLanguage(id);
      if (res.status === 200) {
        await dispatch(getAllLanguage());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editExistingLanguage = createAsyncThunk(
  "manage_settings/editExistingLanguage",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await editLanguage(data);
      if (res.status === 200) {
        await dispatch(getAllLanguage());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewVehicleType = createAsyncThunk(
  "manage_settings/addNewVehicleType",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await addVehicleType(data);
      if (res.status === 200) {
        await dispatch(getAllVehicleType());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const toggleChangeVehicleType = createAsyncThunk(
  "manage_settings/toggleChangeVehicleType",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const res = await toggleVehicleType(id);
      if (res.status === 200) {
        await dispatch(getAllVehicleType());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editExistingVehicleType = createAsyncThunk(
  "manage_settings/editExistingVehicleType",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await editVehicleType(data);
      if (res.status === 200) {
        await dispatch(getAllVehicleType());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewCompany = createAsyncThunk(
  "manage_settings/addNewCompany",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await addCompany(data);
      if (res.status === 200) {
        await dispatch(getAllCompanyList());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllCompanyList = createAsyncThunk(
  "manage_settings/getAllCompanyList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getAllCompany();
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const toggleChangeCompany = createAsyncThunk(
  "manage_settings/toggleChangeCompany",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const res = await toggleCompany(id);
      if (res.status === 200) {
        await dispatch(getAllCompanyList());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editExistingCompany = createAsyncThunk(
  "manage_settings/editExistingCompany",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await editCompany(data);
      if (res.status === 200) {
        await dispatch(getAllCompanyList());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewState = createAsyncThunk(
  "manage_settings/addNewState",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await addState(data);
      if (res.status === 200) {
        await dispatch(getAllState());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const toggleChangeState = createAsyncThunk(
  "manage_settings/toggleChangeState",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const res = await toggleState(id);
      if (res.status === 200) {
        await dispatch(getAllState());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editExistingState = createAsyncThunk(
  "manage_settings/editExistingState",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await editState(data);
      if (res.status === 200) {
        await dispatch(getAllState());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewCounty = createAsyncThunk(
  "manage_settings/addNewCounty",
  async (data, { rejectWithValue }) => {
    try {
      const res = await addCounty(data);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllCountyList = createAsyncThunk(
  "manage_settings/getAllCounty",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getAllCounty();
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const toggleChangeCounty = createAsyncThunk(
  "manage_settings/toggleChangeCounty",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const res = await toggleCounty(id);
      if (res.status === 200) {
        await dispatch(getAllCountyList());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editExistingCounty = createAsyncThunk(
  "manage_settings/editExistingCounty",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await editCounty(data);
      if (res.status === 200) {
        await dispatch(getAllCountyList());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewVehicleColor = createAsyncThunk(
  "manage_settings/addNewVehicleColor",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await addVehicleColor(data);
      if (res.status === 200) {
        await dispatch(getAllVehicleColorList());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllVehicleColorList = createAsyncThunk(
  "manage_settings/getAllVehicleColorList",
  async (params, { rejectWithValue }) => {
    try {
      const res = await getAllVehicleColor(params);
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const toggleChangeVehicleColor = createAsyncThunk(
  "manage_settings/toggleChangeVehicleColor",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const res = await toggleVehicleColor(id);
      if (res.status === 200) {
        await dispatch(getAllVehicleColorList());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editExistingVehicleColor = createAsyncThunk(
  "manage_settings/editExistingVehicleColor",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await editVehicleColor(data);
      if (res.status === 200) {
        await dispatch(getAllVehicleColorList());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewReason = createAsyncThunk(
  "manage_settings/addNewReason",
  async (data, { dispatch ,rejectWithValue }) => {
    try {
      const res = await addReason(data);
      if (res.status === 200) {
        await dispatch(getAllReasonsList())
        return res.data
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllReasonsList = createAsyncThunk(
  "manage_settings/getAllReasonsList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getAllReasons();
      if (res.status === 200) {
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const toggleChangeReason = createAsyncThunk(
  "manage_settings/toggleChangeReason",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const res = await toggleReason(id);
      if (res.status === 200) {
        await dispatch(getAllReasonsList());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editExistingReason = createAsyncThunk(
  "manage_settings/editExistingReason",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await editReason(data);
      if (res.status === 200) {
        await dispatch(getAllReasonsList());
        return res.data;
      } else throw res.error;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const manageSettings = createSlice({
  name: "manage_settings",
  initialState: {
    policies: [],
    priceList: [],
    companyList: [],
    countyList: [],
    colorList: [],
    reasonsList: [],
  },
  extraReducers: (builder) => {
    builder.addCase(addNewRidePrice.fulfilled);

    builder.addCase(getNewRidePrice.fulfilled, (state, action) => {
      state.priceList = action.payload;
    });

    builder.addCase(getRidePriceToEdit.fulfilled);

    builder.addCase(updateNewRidePrice.fulfilled);

    builder.addCase(addNewPolicy.fulfilled);

    builder.addCase(getAllPolicy.fulfilled, (state, action) => {
      state.policies = action.payload;
    });

    builder.addCase(getPolicyToEdit.fulfilled);

    builder.addCase(updateExistingPolicy.fulfilled);

    builder.addCase(addNewLanguage.fulfilled);

    builder.addCase(toggleChangeLanguage.fulfilled);

    builder.addCase(addNewVehicleType.fulfilled);

    builder.addCase(toggleChangeVehicleType.fulfilled);

    builder.addCase(editExistingVehicleType.fulfilled);

    builder.addCase(addNewCompany.fulfilled);

    builder.addCase(getAllCompanyList.fulfilled, (state, action) => {
      state.companyList = action.payload;
    });

    builder.addCase(toggleChangeCompany.fulfilled);

    builder.addCase(addNewState.fulfilled);

    builder.addCase(toggleChangeState.fulfilled);

    builder.addCase(addNewCounty.fulfilled);

    builder.addCase(getAllCountyList.fulfilled, (state, action) => {
      state.countyList = action.payload;
    });

    builder.addCase(toggleChangeCounty.fulfilled);

    builder.addCase(addNewVehicleColor.fulfilled);

    builder.addCase(getAllVehicleColorList.fulfilled, (state, action) => {
      state.colorList = action.payload;
    });

    builder.addCase(toggleChangeVehicleColor.fulfilled);

    builder.addCase(editExistingVehicleColor.fulfilled);

    builder.addCase(getAllReasonsList.fulfilled, (state, action) => {
      state.reasonsList = action.payload;
    });
  },
});

export default manageSettings.reducer;
