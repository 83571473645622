import React from "react";
import { Row, Col } from "react-bootstrap";
import LabelField from "../../../components/fields/LabelField";
import { Text, Box } from "../../../components/elements";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadImages,
  getVehicleTypeList,
  addVehicleDetail,
  manageMultiselect,
  getVehicleTypeFromId,
} from "../../../slices/driverManagementSlice";
import Multipleselect from "../../../components/Multipleselect";
import { getAllVehicleColorList } from "../../../slices/manageSettingsSlice";
import { compose } from "@reduxjs/toolkit";

export default function VehicleDetail() {
  let formData = new FormData();

  const dispatch = useDispatch();

  const { vehicleType, driverData, multiselect } = useSelector(
    (state) => state.driverManagement
  );

  const { colorList } = useSelector((state) => state.manageSettings);

  const handleVehicleDataChange = (e, type) => {
    const { name, value } = e.target;
    if (type === "text") {
      dispatch(addVehicleDetail({ name, value }));
    } else if (type === "file") {
      if (e.target.files.length > 0) {
        for (let i = 0; i < e.target.files.length; i++) {
          formData.append("img", e.target.files[i]);
        }
      }

      dispatch(uploadImages(formData))
        .unwrap()
        .then((url) => {
          dispatch(addVehicleDetail({ name, value: url.result }));
        })
        .catch((error) => {
          if (error.message === "Rejected")
            toast.error("Something unwanted occured");
        });
    }
  };

  const handleVehicleType = (selectedList, selectedItem) => {
    dispatch(manageMultiselect({ key: "vehicleType", value: selectedList }));

    dispatch(addVehicleDetail({ name: "type", value: selectedItem._id }));

    dispatch(getVehicleTypeFromId(selectedItem._id));
  };

  const handleRemoveVehicleType = (selectedList) => {
    dispatch(manageMultiselect({ key: "vehicleType", value: selectedList }));

    dispatch(addVehicleDetail({ name: "type", value: "" }));
  };

  const handleVehicleColor = (selectedList, selectedItem) => {
    dispatch(manageMultiselect({ key: "vehicleColor", value: selectedList }));

    dispatch(addVehicleDetail({ name: "color", value: selectedItem._id }));
  };

  const handleRemoveVehicleColor = (selectedList) => {
    dispatch(manageMultiselect({ key: "vehicleColor", value: selectedList }));

    dispatch(addVehicleDetail({ name: "color", value: "" }));
  };



  React.useEffect(() => {
   dispatch(getVehicleTypeList())
      .unwrap()
      .catch((error) => toast.error("Something unwanted occurred!"));

    dispatch(getAllVehicleColorList({ isActive: true }))
      .unwrap()
      .catch((error) => toast.error("Something unwanted occurred!"));
  }, []);

  console.log(vehicleType , "l;l")

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3">
        <Text>Vehicle Name</Text>
     
        <LabelField
          type="text"
          name="vehicleName"
          placeholder = "Type vehicle's name"
          fieldSize={`w-xl h-md`}
          value={driverData?.vehicleData?.vehicleName}
          onChange={(e) => handleVehicleDataChange(e, "text")}
        />
      
      </div>

      <div className="col-md-3">
        <Text>Vehicle Number</Text>
      
        <LabelField
          type="text"
          name="vehicleNumber"
          placeholder = "Type vehicle's no."
          fieldSize={`w-xl h-md`}
          value={driverData?.vehicleData?.vehicleNumber}
          onChange={(e) => handleVehicleDataChange(e, "text")}
        />
      </div>

      <div className  = "row mt-3 ">

      <div className="col-md-3">
        <Text>Vehicle Type</Text>
      
    
        <Multipleselect
          options={vehicleType}
          displayValue="label"
          placeholder="Choose Vehicle Type"
          onSelect={handleVehicleType}
          onRemove={handleRemoveVehicleType}
          selectionLimit="1"
          width="auto"
          selectedValues={multiselect["vehicleType"]}
        />
      </div>

      <div className="col-md-3">
        <Text>Vehicle Color</Text>
     
        <Multipleselect
          options={colorList}
          displayValue="label"
          placeholder="Choose Vehicle Color"
          onSelect={handleVehicleColor}
          onRemove={handleRemoveVehicleColor}
          selectionLimit="1"
          width="auto"
          selectedValues={multiselect["vehicleColor"]}
        />
      </div>

      </div>

      {/* <Col xl={4}>
        <Text>No. of Seats</Text>
      </Col>
      <Col xl={8}>
        <LabelField
          type="text"
          name="numberOfSeats"
          fieldSize={`w-xl h-md`}
          value={driverData?.vehicleData?.numberOfSeats}
          onChange={(e) => handleVehicleDataChange(e, "text")}
        />
      </Col> */}

      <div className = "row mt-3 ">
        <div className="col-md-6">
        <Text>Upload Vehicle Image <span className="text-[10px]">(Upload 4 images: Front/Side/Inside back/Trunk)</span></Text>
    
        <LabelField
          type="file"
          name="vehicleGallery"
          fieldSize={`w-xl h-md`}
          multiple
          onChange={(e) => handleVehicleDataChange(e, "file")}
        />
        {/* {driverData?.vehicleData?.vehicleGallery?.length === 4 ? null : (
          <p className="text-xs text-wrap">
            (Upload 4 images: Front / side / inside back / trunk)
          </p>
        )} */}

        <Box className="d-flex gap-2">
          {driverData?.vehicleData?.vehicleGallery?.map((url, i) => (
            <img key={i} src={url} alt="" height="100px" width="100px" />
          ))}
        </Box>
        </div>
      </div>
      </div>

      {/* <ToastContainer /> */}
      </div>
  );
}
