import React, { useDebugValue, useEffect } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { List, Item, Icon, Text, Box, Anchor, Button } from "../../components/elements";
import {
  Breadcrumb,
  RoundAvatar,
  DivideTitle,
  DuelText,
  Pagination,
} from "../../components";
import {
  CardLayout,
  CardHeader,
  FloatCard,
  ActivityCard,
} from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/riderProfile.json";
import { useLocation, useSearchParams } from "react-router-dom";
import { UserRideHistory } from "../../components";
import { RatingBar } from "../../components/elements/RatingBar";
import {Heading} from "../../components/elements";
import { CarIcon } from "../../components/icons/icons";
import { EarningIcon } from "../../components/icons/icons";
import { riderProfile } from "../../slices/riderManagementSlice";
import { useDispatch } from "react-redux";
import { changeLabel } from "../../slices/sidebarSlice";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getAllRides } from "../../slices/rideManagementSlice";
import "react-toastify/dist/ReactToastify.css";
import DetailedPayout from "../../components/tables/DetailedPayoutTable";

export default function RequestedPayout() {
  const location = useLocation();
  const riderId = location.state;
  const dispatch = useDispatch();

  const allRides = useSelector((state) => state.rideManagement.allRides);

  const [currentPage , setCurrentPage ] = React.useState(1)
  const [itemsPerPage] = React.useState(10)
  const profileData = (id) =>{
     dispatch(riderProfile(id))
     .unwrap()
     .catch((error) => toast.error(error))
  }

  const fetchRides = (params) =>{
    // console.log(params , "params")
    dispatch(getAllRides(params))
    .unwrap()
    .catch((error) => console.log(error));

  }

const handlePageChange = (newPage) =>{
  setCurrentPage(newPage)
  fetchRides({
    passenger : riderId,
    page : newPage,
    limit : itemsPerPage
    })
}

  useEffect(()=>{
    profileData(riderId)
    fetchRides({passenger : riderId  , page : currentPage , limit : itemsPerPage })
    dispatch(changeLabel(""));
  },[])

  const getStartIndex = () =>{
    return (currentPage - 1) * itemsPerPage
  }

  const rider  = useSelector((state) => state.riderManagement.profile)

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title="Payout Request Id">
              {data?.breadcrumb.map((item, index) => (
                <Item key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </Item>
              ))}
            </Breadcrumb>
          </CardLayout>
        </Col>

        {/* {data?.float.map((item, index) => (
          <Col md={4} lg={4} key={index}>
            <FloatCard
              variant={item.variant}
              digit={item.digit}
              title={item.title}
              // icon={item.icon}
            />
          </Col>
        ))} */}


      <Col xl={4} >
          <CardLayout>
            {/* <CardHeader
              title="driver information"
              // dotsMenu={data?.dotsMenu}
            /> */}
            <Box className="mc-user-group">
            <Box className="mc-user-profile d-flex justify-content-center">
                  <RoundAvatar
                    src={rider?.profile?.profileUrl || data?.profile.src2}
                    alt={data?.profile.alt}
                    size={data?.profile.size}
                  />
              </Box>
                  <Box className="d-flex justify-content-center gap-1">
                    <DuelText
                      title={rider?.profile?.name}
                      // descrip={rider?._id}
                      size={data?.profile.size}
                    />                
                </Box>
               
                <Box className = "d-flex justify-content-center">  
                  {/* <Text className="text-[12px] text-bold bg-yellow-100 p-1" > */}
                    <RatingBar stars = {rider?.profile?.averageRating} />
                    {/* </Text> */}
                    </Box>

              <Box className="pt-4">
               
                <List className="mc-user-metalist ">
                  <Item className="d-flex justify-content-between"> 
                    <Text>Mobile Number</Text>
                    <Text as="span">{rider?.profile?.phone}</Text>
                  </Item>
                  <Item className="d-flex justify-content-between">
                  <Text>Email</Text>
                    <Text as="span">{rider?.profile?.email}</Text>
                  </Item>
                  <Item className="d-flex justify-content-between">
                  <Text>Gender</Text>
                    <Text as="span">{rider?.profile?.gender}</Text>
                  </Item>
                  {/* <Item className="d-flex justify-content-between">
                  <Text>County</Text>
                    <Text as="span">{driverProfile?.driverCounty}</Text>
                  </Item> */}
                </List>
              </Box>
              </Box>
              </CardLayout>


              <CardLayout  className = "mt-2">
              <CardHeader
              title="Overall Statistics"
              // dotsMenu={data?.dotsMenu}
            />
            
              
                <Box className="d-flex topCards mc-card align-items-center justify-content-between  rounded p-4 mb-2">
                  <Box>
                    <Heading className="">{rider?.countData}</Heading>
                    <Text>Rides</Text>
                  </Box>
                  <Box className="border rounded p-2">
                    {/* <TimeToLeaveIcon fontSize="large" /> */}
                    {/* <Icon fontSize="large">commute</Icon> */}
                    <CarIcon />
                  </Box>
                </Box>
              
             
                <Box className="mc-card topCards d-flex align-items-center justify-content-between  rounded p-4">
                  <Box>
                    <Heading className="text-2xl font-[600]">${rider?.totalEarnings?.toFixed(2)}</Heading>
                    <Text>Spending</Text>
                  </Box>

                  <Box className="border rounded p-2">
                    {/* <Icon fontSize="large">commute</Icon> */}
                    {/* <TimeToLeaveIcon fontSize="large" /> */}
                    <EarningIcon />
                  </Box>
                </Box>
              </CardLayout>
             </Col>
             
        
            <Col xl= {8}>
               <CardLayout>
             <DivideTitle title="Ride History" className="mb-4" />
             <DetailedPayout
              startIndex = {getStartIndex}
            //   data = {allRides?.docs} 
            data = ""
             
             />
             {/* <Pagination  
             totalItems = {allRides?.totalDocs}
             itemsPerPage = {allRides?.pagingCounter}
             currentPage = {currentPage}
             limit = {allRides?.limit}
             onPageChange={handlePageChange}
             /> */}
             </CardLayout>
           

            
             {/* <CardLayout className = "mt-2"> */}
             <Box className="pt-4 justify-content-center">
               
               <List className="mc-user-metalist ">
                 <Item className="d-flex justify-content-center"> 
                   <Text>Total amount earned : </Text>
                   {/* <Text as="span">{rider?.profile?.phone}</Text> */}
                 </Item>
                 <Item className="d-flex justify-content-center">
                 <Text>Admin Commision : </Text>
                   {/* <Text as="span">{rider?.profile?.email}</Text> */}
                 </Item>
                 <Item className="d-flex justify-content-center">
                 <Text>Amount paid to driver Id :</Text>
                   {/* <Text as="span">{rider?.profile?.gender}</Text> */}
                 </Item>
                 <Item className="d-flex justify-content-center">
                 <Text>Cash collected by driver : </Text>
                   {/* <Text as="span">{driverProfile?.driverCounty}</Text> */}
                 </Item>
               </List>
                <Box className="d-flex justify-content-end">
                  <div
                    className="blackButton"
                    // onClick={()=>handleVerify(data?._id, "vehicle")}
                  >Approve</div>

                  <div
                   className="whiteButton"
                    // onClick={() => {setRejectModal(true); setRejectReason((prev)=>({...prev , profileId : data._id , docType : "vehicle"}))}}
                  >Reject</div>
                </Box>
              
             </Box>
             {/* </CardLayout> */}
             </Col>
           


        
                   
      </Row> 
    </PageLayout>
  );
}
