import React from "react";
import { Box, Item, Anchor } from "../../components/elements";
import { LabelField } from "../../components/fields";
import { Breadcrumb, DivideTitle } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/settings.json";
import { CompanyTable } from "../../components/tables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CardHeader } from "../../components/cards";
import { useDispatch, useSelector } from "react-redux";
import { changeLabel } from "../../slices/sidebarSlice";
import {
  addNewCompany,
  getAllCompanyList,
  toggleChangeCompany,
} from "../../slices/manageSettingsSlice";
import { AddIcon } from "../../components/icons/icons";

export default function Company() {
  const [company, setCompany] = React.useState("");

  const dispatch = useDispatch();

  const { companyList } = useSelector((state) => state.manageSettings);

  const handleChange = (e) => {
    setCompany(e.target.value);
  };

  const handleClick = () => {
    // console.log(company, "comp...");

    if (company) {
      dispatch(addNewCompany({ label: company }))
        .unwrap()
        .then((res) => toast.success(res.result))
        .catch((error) => toast.error("Something went wrong!"));
    } else {
      toast.warn("Add a company!");
    }

    setCompany("");
  };

  const toggleChange = (id) => {
    dispatch(toggleChangeCompany(id));
  };

  React.useEffect(() => {
    dispatch(changeLabel("managesettings"));

    dispatch(getAllCompanyList())
      .unwrap()
      .catch((error) => toast.error("Something went wrong!"));
  }, []);

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>

     
<CardLayout className="p-sm-5">
        <Box className="mb-5">
          <DivideTitle title="Company" className="mb-5" />
       

         <div className="d-flex gap-2">
         <LabelField
          type="text"
          placeholder = "Type company name"
          // name="email"
          fieldSize={`w-xl h-md`}
          value={company}
          onChange={(e) => handleChange(e)}
        />
       
                <div className="blackButton" onClick= {() => handleClick() } >
            <span className="px-1"> <AddIcon /></span>
           Add Company
           </div>
                </div>
        </Box>
      </CardLayout>

      <CardLayout className={`mt-4`}>
        <CardHeader title="added companies" />
        <CompanyTable
          companyList={companyList}
          toggleChange={toggleChange}
          toast={toast}
        />
      </CardLayout>
      <ToastContainer />
    </PageLayout>
  );
}
