import React from "react";
import { Dropdown } from "react-bootstrap";
import { DuelText, RoundAvatar } from "..";
import { Anchor } from "../elements";
import services from "../../utils/Common";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { viewAdminProfile } from "../../slices/authSlice";

export default function ProfileDropdown({ image, dropdown }) {
  const role = localStorage.getItem("role");

  const { name, email, profileUrl } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(viewAdminProfile())
      .unwrap()
      .catch((error) => console.log("Something went wrong!"));
  }, []);

  // const navigate = useNavigate();

  // const removeCookie = (name) => {
  //   document.cookie =
  //     name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  // };

  // const deleteCookie = async () => {
  //   try {
  //     const res = await authAxios({
  //       url: "/auth/logout",
  //       method: "get",
  //     });

  //     if (res.status === 200) {
  //       console.log(res.data, "data....");
  //       navigate("/login");
  //     }
  //   } catch (error) {
  //     console.log(error, "err...");
  //   }
  // };

  return (
    <Dropdown className="mc-header-user">
      <Dropdown.Toggle className="mc-dropdown-toggle">
        {/* <RoundAvatar src={image} alt="avatar" size="xs" /> */}
        <RoundAvatar
          // src={profileUrl && profileUrl !== undefined ? profileUrl : image}
          src={profileUrl ? profileUrl : image}
          alt="avatar"
          size="xs"
        />
        <DuelText title={name} descrip={email} size="xs" />
      </Dropdown.Toggle>
      <Dropdown.Menu align="end" className="mc-dropdown-paper">
        {dropdown?.map((item, index) => {
          if (role === "super_admin" && item.text === "logout") {
            return (
              <Anchor
                key={index}
                href={item.path}
                icon={item.icon}
                text={item.text}
                className="mc-dropdown-menu"
                onClick={() => {
                  if (item.text === "logout") {
                    services.clearToken();
                    // localStorage.removeItem("token");
                    // removeCookie("Authorization");
                    // await deleteCookie();
                  }
                }}
              />
            );
          } else if (role === "admin") {
            return (
              <Anchor
                key={index}
                href={item.path}
                icon={item.icon}
                text={item.text}
                className="mc-dropdown-menu"
                onClick={() => {
                  if (item.text === "logout") {
                    services.clearToken();
                    // localStorage.removeItem("token");
                    // removeCookie("Authorization");
                    // await deleteCookie();
                  }
                }}
              />
            );
          }
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
